import React, { useState, useRef, useEffect } from "react";
import { Play, FileDown, Clock, X, Copy, Check } from "lucide-react";
import SMLCard from "../common/styled-components/SMLCard";
import styles from "./ResourceSection.module.css";
import { getResourcesApi } from "../../api/resources/getResourcesApi";

const ResourceSection = () => {
  const [activeVideo, setActiveVideo] = useState(null);
  const videoRef = useRef(null);
  const [resources, setResources] = useState({ videos: [], templates: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const observerRef = useRef(null);

  useEffect(() => {
    // Cleanup function for ResizeObserver
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    fetchResources();
  }, []);

  const fetchResources = async () => {
    try {
      setIsLoading(true);
      setError(null);

      // Fetch videos
      const videoResponse = await getResourcesApi('video');
      
      // Fetch templates and guides
      const templateResponse = await getResourcesApi('template,guide');

      setResources({
        videos: videoResponse?.resources || [],
        templates: templateResponse?.resources || []
      });
    } catch (error) {
      setError(error.message);
      console.error('Error fetching resources:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleVideoClick = (video) => {
    setActiveVideo(video);
    // Setup ResizeObserver for video player
    if (videoRef.current) {
      observerRef.current = new ResizeObserver(() => {
        // Handle resize if needed
      });
      observerRef.current.observe(videoRef.current);
    }
  };

  const closeVideo = () => {
    // Cleanup ResizeObserver before closing
    if (observerRef.current) {
      observerRef.current.disconnect();
    }
    setActiveVideo(null);
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  if (isLoading) {
    return <div>Loading resources...</div>;
  }

  if (error) {
    return <div>Error loading resources: {error}</div>;
  }

  return (
    <>
      <SMLCard className={styles.resourceCard}>
        <h2 className={styles.sectionTitle}>Sync Insider Essentials</h2>
        <div className={styles.videoGrid}>
          {resources.videos.map((video, index) => (
            <div
              key={video.id}
              className={styles.videoCard}
              onClick={() => handleVideoClick(video)}
            >
              <div className={styles.thumbnailContainer}>
                <img
                  className={styles.thumbnail}
                  src={`${video.baseUrl}/${video.thumbnailKey}`}
                  alt={video.title}
                />
                <div className={styles.videoOverlay}>
                  <div className={styles.videoInfo}>
                    <h4 className={styles.videoTitle}>{video.title}</h4>
                    <div className={styles.videoMeta}>
                      <span className={styles.duration}>
                        <Clock size={12} className={styles.durationIcon} />
                        {video.duration || "0:00"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </SMLCard>

      {/* Video Player Overlay */}
      {activeVideo && (
        <div className={styles.videoPlayerOverlay} onClick={closeVideo}>
          <div
            className={styles.videoPlayerContent}
            onClick={(e) => e.stopPropagation()}
          >
            <button className={styles.closeButton} onClick={closeVideo}>
              <X size={24} />
            </button>
            <video
              ref={videoRef}
              className={styles.videoPlayer}
              controls
              autoPlay
              preload="auto"
            >
              <source src={`${activeVideo.baseUrl}/${activeVideo.resourceFileKey}`} type="video/mp4" />
            </video>
          </div>
        </div>
      )}

      <SMLCard className={styles.resourceCard}>
        <h2 className={styles.sectionTitle}>Templates & Resources</h2>
        <div className={styles.templateList}>
          {resources.templates.map((template, index) => (
            <div key={template.id} className={styles.templateRow}>
              <div className={styles.templateIcon}>
                <FileDown size={24} />
              </div>
              <div className={styles.templateInfo}>
                <h3 className={styles.templateTitle}>{template.title}</h3>
                <p className={styles.templateDescription}>
                  {template.description}
                </p>
                <span className={styles.templateMeta}>{template.category}</span>
              </div>
              <div className={styles.templateActions}>
                <button
                  className={styles.actionButton}
                  onClick={() => window.open(`${template.baseUrl}/${template.resourceFileKey}`, "_blank")}
                  title="Download template"
                >
                  <FileDown size={20} />
                </button>
              </div>
            </div>
          ))}
        </div>
      </SMLCard>
    </>
  );
};

export default ResourceSection;
