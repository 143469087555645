import React, { useEffect, useState } from "react";
import "./SupHeader.css";
import { Row, Col } from "react-bootstrap";
import SMlLogo from "../../../assets/Images/SMLLogo.svg";
import SMLLabel from "../../../components/Shared/SMLLabel/SMLLabel";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllArtistFetch } from "../../../redux/actions/smlActions";
import ProfileDropdown from "../../common/header/ProfileDropdown";
import NotifPanel from "../../common/notif-panel/NotifPanel";
import { getNotificationRequest } from "../../../redux/actions";
import { RESET_ASSIGNED_SUPERVISOR } from "../../../redux/actions/actionTypes";
const SUPHeader = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isMaster = localStorage.getItem("isMaster");
  useEffect(() => {
    dispatch(getAllArtistFetch());
    // dispatch(getNotificationRequest({ offset: 0, limit: 10 }));
  }, [dispatch]);

  const user = useSelector((state) => state.sml.allArtistFetched?.artists);

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleDashboard = () => {
    dispatch({ type: RESET_ASSIGNED_SUPERVISOR });
    navigate("/supervisorDashboard");
  };

  const handleResources = () => {
    navigate("/supervisor/resources");
  };

  const handleArtists = () => {
    navigate("/supervisor/artists");
  };

  return (
    <div className="sup-header-div">
      <Row className="py-4">
        <Col>
          <img className="headerLogo" src={SMlLogo} alt="Image" />
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <div className="label-container d-flex" style={{ gap: "20px" }}>
            <div
              className="col d-flex align-items-center justify-content-end cursor-pointer dashboard-link"
              onClick={handleDashboard}
            >
              <SMLLabel style="Button6" value="Dashboard" />
            </div>
            {isMaster === "true" && (
              <>
                <div
                  className="col d-flex align-items-center justify-content-end cursor-pointer dashboard-link"
                  onClick={handleResources}
                >
                  <SMLLabel style="Button6" value="Resources" />
                </div>
                <div
                  className="col d-flex align-items-center justify-content-end cursor-pointer dashboard-link"
                  onClick={handleArtists}
                >
                  <SMLLabel style="Button6" value="Artists" />
                </div>
              </>
            )}
            {/* {isMaster === "true" && (
              <>
                <div
                  className={
                    "d-flex align-items-center justify-content-center cursor-pointer"
                  }
                >
                  <SMLLabel style="Button2" value="Music Supervisors" />
                </div>
                <div
                  className={
                    "d-flex align-items-center justify-content-center cursor-pointer"
                  }
                >
                  <SMLLabel style="Button2" value="Feedbacks Sent" />
                </div>
              </>
            )} */}

            <div className="profile-btn">
              <NotifPanel panelType={"sup"} />
              <ProfileDropdown name={user?.name} />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SUPHeader;
