import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SMLButton from "../../components/Shared/SMLButton/SMLButton";
import SMLButtonWithIcon from "../../components/Shared/SMLButtonWithIcon/SMLButtonWithIcon";
import SMLCheckbox from "../../components/Shared/SMLCheckbox/SMLCheckbox";
import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import SMLTextBox from "../../components/Shared/SMLTextBox/SMLTextBox";

import "../../styles/Fonts.scss";
import "./Register.css";
import Backbutton from "../../components/Shared/BackButton/BackButton";
import TermsAndConditions from "../../components/UI/TermsAndConditions/TermsAndCondition";
import { MdOutlineClose, MdVisibility, MdVisibilityOff } from "react-icons/md";
import { GoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { socialLoginRequest } from "../../redux/actions";
import FacebookLoginButton from '../../components/common/FacebookLoginButton/FacebookLoginButton';
import { APPCONFIG } from "../../config/APPConfig";
import closeIcon from "../../assets/Images/closeIcon.svg";
import SupervisionLogin from "../SupervisorLogin/SupervisorLogin";

const Register = (props) => {
  const [fullName, setFullName] = useState(props.formData.fullName);
  const [email, setEmail] = useState(props.formData.email);
  const [createPassword, setCreatePassword] = useState(
    props.formData.verifyPassword
  );
  const [verifyPassword, setVerifyPassword] = useState(
    props.formData.verifyPassword
  );
  const [agreeConditions, setAgreeConditions] = useState(
    props.formData.agreeConditions
  );
  const [fullNameError, setFullNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [createPasswordError, setCreatePasswordError] = useState("");
  const [verifyPasswordError, setVerifyPasswordError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showCreatePassword, setShowCreatePassword] = useState(false); // For password visibility toggle
  const [showVerifyPassword, setShowVerifyPassword] = useState(false); // For password visibility toggle
  const [showLogin, setShowLogin] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const validatePassword = (password) => {
    const isValid =
      password.length >= 8 &&
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password) &&
      /\d/.test(password) &&
      /[^\w\s]/.test(password) && // Special character validation
      !/\s/.test(password); // No spaces

    if (!isValid) {
      return "Password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, one number, one special character, and must not contain spaces.";
    }

    return null; // No validation errors
  };
  const handleFacebookSuccess = ({ accessToken, userInfo }) => {
    const token = {
      credential: accessToken,
      provider: "facebook",
      profileType: "supervisor",
      userData: {
        name: userInfo.name,
        email: userInfo.email
      }
    };

    if (token.credential) {
      props.onRegisterClick(token);
    }
  };
  const handleFacebookError = (error) => {
    console.error('Facebook signup error:', error);
    // Add error handling UI if needed
  };
  const handleGoogleSignup = (credentialResponse) => {
    const token = {
      credential: credentialResponse.credential,
      provider: "google",
      profileType: "supervisor",
    };
    if (token) {
      props.onRegisterClick(token);
    }
  };
  const handleRegister = (e) => {
    e.preventDefault();

    // Validate Full Name
    if (!fullName.trim()) {
      setFullNameError("Full Name is required");
      return;
    }

    // Validate email
    if (!email.trim()) {
      setEmailError("Email is required");
      return;
    } else if (!validateEmail(email)) {
      setEmailError("Invalid email format");
      return;
    } else {
      setEmailError("");
    }

    // Validate passwords
    if (!createPassword.trim()) {
      setCreatePasswordError("Create Password is required");
      return;
    } else {
      const passwordError = validatePassword(createPassword);
      if (passwordError) {
        setCreatePasswordError(passwordError);
        return;
      } else {
        setCreatePasswordError("");
      }
    }
    if (createPassword !== verifyPassword) {
      setVerifyPasswordError("Password does not match");
      return;
    } else {
      setVerifyPassword("");
    }

    // Prepare the data with camelCase
    const registrationData = {
      email,
      password: createPassword,
      verifyPassword: verifyPassword,
      fullName,
      profileType: "supervisor",
      agreeConditions,
    };

    // Call the parent function with the formatted data
    props.onRegisterClick(registrationData);
  };

  const handleLoginClick = () => {
    // setShowLogin(true);
    props.goToLogin();
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  // const handleFullNameChange = (e) => {
  //   const value = e.target.value;
  //   // Allow only letters and specified special characters
  //   const regex = /^[A-Za-z!@#$%^&*()_+|~=`{}\[\]:;\"'<>,.?/ -]*$/;

  //   // Validate the input
  //   if (regex.test(value)) {
  //     setFullName(value); // Update state if valid
  //   }
  // };

  return (
    <div className="register-container">
      <div className="">
        <div className="contact-label-div">
          <span
            style={{
              color: "#000000",
              fontFamily: "DM Sans",
              fontWeight: "600",
              fontSize: "26px",
            }}
          >
            Supervisor Sign Up
          </span>
        </div>
        <div className="register-signUp-groupby">
          <div className="register-signUp-main">
            {/* <div className="register-close-div" onClick={props.onClose}>
              <img src={closeIcon} style={{ cursor: "pointer" }} alt="Close" />
            </div> */}
            {emailError && (
              <div className="error-box-sup">
                <SMLLabel style="error" value={emailError} />
              </div>
            )}
            {fullNameError && (
              <div className="error-box-sup">
                <SMLLabel value={fullNameError} />
              </div>
            )}
            {createPasswordError && (
              <div className="error-box-sup">
                <SMLLabel style="error" value={createPasswordError} />
              </div>
            )}
            {verifyPasswordError && (
              <div className="error-box-sup">
                <SMLLabel style="error" value={verifyPasswordError} />
              </div>
            )}

            <form className="register-signUp-grid" onSubmit={handleRegister}>
              <div className="register-signUp-content-left">
                <div className="register-signUp-controls">
                  <div className="register-signUp-fields">
                    <SMLLabel value="Full Name" style="title2" />
                    <SMLTextBox
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                  </div>
                  <div className="register-signUp-fields">
                    <SMLLabel value="Email" style="title2" />
                    <SMLTextBox
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="register-signUp-fields">
                    <SMLLabel value="Create Password" style="title2" />
                    <div className="password-container">
                      <SMLTextBox
                        type={showCreatePassword ? "text" : "password"}
                        value={createPassword}
                        onChange={(e) => setCreatePassword(e.target.value)}
                      />
                      <span
                        className="toggle-password-visibility"
                        onClick={() =>
                          setShowCreatePassword(!showCreatePassword)
                        }
                      >
                        {showCreatePassword ? (
                          <MdVisibility />
                        ) : (
                          <MdVisibilityOff />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="register-signUp-fields">
                    <SMLLabel value="Verify Password" style="title2" />
                    <div className="password-container">
                      <SMLTextBox
                        type={showVerifyPassword ? "text" : "password"}
                        value={verifyPassword}
                        onChange={(e) => setVerifyPassword(e.target.value)}
                      />
                      <span
                        className="toggle-password-visibility"
                        onClick={() =>
                          setShowVerifyPassword(!showVerifyPassword)
                        }
                      >
                        {showVerifyPassword ? (
                          <MdVisibility />
                        ) : (
                          <MdVisibilityOff />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="register-signUp-fields">
                    <div className="signUp-groupby">
                      <SMLCheckbox
                        checked={agreeConditions}
                        onChange={(e) => setAgreeConditions(e.target.checked)}
                      />
                      <div className="title2" style={{ margin: "0px" }}>
                        I accept all{" "}
                        <a className="link" onClick={openModal}>
                          terms and conditions
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="register-signUp-content">
                <div className="register-signUp-controls1">
                  <div className="divider"></div>
                  <span>or</span>
                  <div className="divider"></div>
                </div>
              </div>
              <div className="register-signUp-right-content">
                <div className="register-signUp-controls1">
                  <div className="register-signUp-fields-right">
                    {/* <SMLButtonWithIcon
                      type="button"
                      value="Sign up with Google"
                      icon="Google"
                    /> */}
                    <div className="provider-btn-sup">
                      <GoogleLogin
                        onSuccess={(codeResponse) => {
                          handleGoogleSignup(codeResponse);
                        }}
                        onError={() => console.log("Login Failed")}
                        setOneTap={false}
                        text="signup_with"
                        size="large"
                        width="232"
                      />
                    </div>
                  </div>
                  <div className="register-signUp-fields-right">
                    {/* <SMLButtonWithIcon
                      type="button"
                      value="Sign up with Facebook"
                      icon="Facebook"
                    /> */}
                    <div className="provider-btn-sup">
                      <FacebookLoginButton 
                        onSuccess={handleFacebookSuccess}
                        onError={handleFacebookError}
                      />
                    </div>
                  </div>
                </div>{" "}
                <div className="register-signUp-fields-right">
                  <div className="register-signUp-groupby">
                    <SMLLabel value="Already a member?" style="title2" />
                    <button
                      type="button"
                      className="btn-no-margin"
                      onClick={handleLoginClick}
                    >
                      Log in
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div className="register-signUp-footer">
              {/* Modal content */}
              {/* </TermsAndConditions> */}
              <SMLButton
                style={`SMLButton2 SMLButton-Orange ${
                  agreeConditions ? "" : "disabled"
                }`}
                value="Continue"
                handleClickEvent={handleRegister}
                handleDisable={!agreeConditions} // Disable button based on checkbox
              />
            </div>
          </div>
        </div>
      </div>
      <TermsAndConditions
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </div>
  );
};

export default Register;
