import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RateSlider from "../../components/UI/RateBar/RateBar";
import SMLOptionQuestion from "../../components/Shared/SMLOptionQuestion/SMLOptionQuestion";
import { RESET_GET_FEEDBACK } from "../../redux/actions/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import "./TrackEvaluation.css";
import { getFeedbackRequest } from "../../redux/actions";
import Union from "../../assets/Images/Union.png";

const showingFields = [
  "Production",
  "Vocals",
  "Lyrics",
  "Dynamics",
  "Build",
  "Editor Hit Points",
  "Stop-downs",
  "Back End",
];
const fields = [
  "production",
  "vocals",
  "lyrics",
  "dynamics",
  "build",
  "editor_hit_points",
  "stop_downs",
  "back_end",
];

const TrackEvaluationArtist = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { trackId } = useParams();
  useEffect(() => {
    dispatch({ type: RESET_GET_FEEDBACK });
    dispatch(getFeedbackRequest(trackId));
  }, [trackId]);
  const feedback = useSelector((state) => state.getFeedback.feedback);
  const [rating, setRatings] = useState({});
  const [syncPotential, setSyncPotential] = useState(true);
  const [suggestedTargets, setSuggestedTargets] = useState([]);
  const [notes, setNotes] = useState("");
  const [downloaded, setDownloaded] = useState(false);
  const [masterFeedback, setMasterFeedback] = useState({});
  useEffect(() => {
    if (feedback && feedback.length > 0) {
      setMasterFeedback(feedback[0]?.master_feedback);
    }
  }, [feedback]);

  useEffect(() => {
    if (feedback && feedback.length > 0) {
      const masterFeedback = feedback[0]?.master_feedback || {};
      setDownloaded(masterFeedback?.download_track);
      setRatings(masterFeedback?.ratings || {});
      setSyncPotential(masterFeedback?.sync_potential || true);
      setSuggestedTargets(
        feedback[0]?.master_feedback?.suggested_targets || []
      );
      setNotes(masterFeedback?.final_feedback);
    }
  }, [feedback]);

  const handleChangePages = () => {
    navigate(-1);
  };

  return (
    <>
      <div
        className="vh-50 align-items-center pb-5"
        style={{ overflow: "auto", color: "#333", minWidth: "55%" }}
      >
        <div style={{ backgroundColor: "#f8f9fa" }}>
          <div
            className="track-bg-main-div-artist track-rate-container mt-3 mb-3"
            style={{ backgroundColor: "#ffffff" }}
          >
            <Col>
              <Row>
                <div className="trackEvalHeaderBox">
                  <SMLLabel style="title1" value="Track Evaluation" />
                </div>
              </Row>
              <Row className="my-3">
                <span className="Subtitle1" style={{ whiteSpace: "pre-line" }}>
                  {notes}
                </span>
              </Row>
            </Col>
            <Row className="mb-3">
              <Col className="trackEvalBtnBox">
                <SMLLabel
                  value="Does this track have potential for sync?"
                  style="title2"
                />
                <SMLLabel
                  style="sub-subtitle2"
                  value={syncPotential ? "Yes" : "No"}
                />
              </Col>
            </Row>
            {suggestedTargets && suggestedTargets.length > 0 && (
              <div className="mb-4">
                <Row>
                  <Col className="my-3">
                    <SMLLabel
                      value="Suggested targets for track placement opportunities"
                      style="title2"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {suggestedTargets.map((target, index) => (
                      <SMLOptionQuestion
                        key={index}
                        type="Button"
                        style="SMLSecondaryButtonSelected"
                        value={target}
                        disabled={true}
                      />
                    ))}
                  </Col>
                </Row>
              </div>
            )}

            {downloaded && (
              <div>
                <Row className="mb-3">
                  <Col className="trackEvalBtnBox">
                    <img src={Union} className="" />
                    <SMLLabel value="Track Certified" style="title2" />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <SMLLabel
                      value="Your track has been highly rated! Music supervisors have downloaded it for potential placement. It is now eligible for distribution by Sync Music Lab."
                      style="notes"
                    />
                  </Col>
                </Row>
              </div>
            )}
          </div>
          <div
            className="track-bg-main-div-artist track-rate-container mt-3 mb-3"
            style={{ backgroundColor: "#ffffff" }}
          >
            <Row className="mb-3">
              <Col>
                <SMLLabel style="title1" value="Track Ratings" />
              </Col>
            </Row>
            {fields.map((field, index) => (
              <div key={index} className="my-4">
                <Row>
                  <Col>
                    <SMLLabel
                      value={showingFields[index]}
                      style="rating-title2"
                    />
                  </Col>
                </Row>
                <div className="ratebar">
                  <Col className="track-rate-slider-div">
                    <Row className="d-flex align-items-center">
                      <Col className="ratesliderBar col-11 my-2">
                        {rating[field] !== undefined && (
                          <RateSlider
                            value={rating[field]}
                            disabled={true}
                            fromUserPortal={true}
                          />
                        )}
                      </Col>
                      <Col className="col-1">
                        <SMLLabel
                          value={rating[field] === 0 ? "N/A" : rating[field]}
                          style={rating[field] === 0 ? "black" : "orange"}
                        />
                      </Col>
                    </Row>
                  </Col>
                  {rating?.[`${field}_notes`] && (
                    <Row>
                      <Col>
                        <div className="notes-line">
                          <span style={{ fontWeight: "bold" }}>
                            Note:&nbsp;
                          </span>
                          <SMLLabel
                            newLine={true}
                            value={`${
                              masterFeedback.ratings[`${field}_notes`]
                            }`}
                            style="notes"
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackEvaluationArtist;
