import { eventChannel } from "redux-saga";

let ws;
let connectionStartTime;

function WebsocketApi(endpoint) {
  return eventChannel((emit) => {
    if (ws) {
      const connectionDuration = connectionStartTime ? 
        `Connection duration: ${Math.floor((Date.now() - connectionStartTime) / 1000)} seconds` : 
        'Connection duration unknown';
      console.log('Closing existing WebSocket connection', {
        disconnectionTime: new Date().toISOString(),
        connectionDuration
      });
      ws.close();
    }

    ws = new WebSocket(endpoint);

    ws.onopen = () => {
      connectionStartTime = Date.now();
      console.log("WebSocket connected", {
        url: ws.url,
        readyState: ws.readyState,
        protocol: ws.protocol,
        connectionTime: new Date(connectionStartTime).toISOString()
      });
    };

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        console.log("WebSocket message received:", data);
        emit(data);
      } catch (error) {
        console.error("WebSocket message parsing error:", error);
        emit(new Error(`Failed to parse message: ${error.message}`));
      }
    };

    ws.onerror = (error) => {
      const connectionDuration = connectionStartTime ? 
        `Connection duration: ${Math.floor((Date.now() - connectionStartTime) / 1000)} seconds` : 
        'Connection duration unknown';
      console.error("WebSocket error:", {
        error,
        readyState: ws.readyState,
        url: ws.url,
        errorTime: new Date().toISOString(),
        connectionDuration
      });
      emit(new Error(error.message || 'WebSocket error occurred'));
    };

    ws.onclose = (event) => {
      const connectionDuration = connectionStartTime ? 
        `Connection duration: ${Math.floor((Date.now() - connectionStartTime) / 1000)} seconds` : 
        'Connection duration unknown';
      console.log("WebSocket closed", {
        code: event.code,
        reason: event.reason,
        wasClean: event.wasClean,
        readyState: ws.readyState,
        disconnectionTime: new Date().toISOString(),
        connectionDuration
      });
      emit(new Error('WebSocket connection closed'));
    };

    return () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        const connectionDuration = connectionStartTime ? 
          `Connection duration: ${Math.floor((Date.now() - connectionStartTime) / 1000)} seconds` : 
          'Connection duration unknown';
        console.log("Cleaning up WebSocket connection", {
          cleanupTime: new Date().toISOString(),
          connectionDuration
        });
        ws.close(1000, "Channel closed");
      }
    };
  });
}

export const logoutFromWebSocket = () => {
  if (ws && ws.readyState === WebSocket.OPEN) {
    const connectionDuration = connectionStartTime ? 
      `Connection duration: ${Math.floor((Date.now() - connectionStartTime) / 1000)} seconds` : 
      'Connection duration unknown';
    ws.close(1000, "User logged out");
    ws.onclose = () => {
      console.log("WebSocket closed after logout.", {
        logoutTime: new Date().toISOString(),
        connectionDuration
      });
      ws = null;
    };
    console.log("Logged out from WebSocket.");
  } else {
    console.log("WebSocket is not connected or already closed.", {
      exists: !!ws,
      readyState: ws?.readyState,
      timestamp: new Date().toISOString()
    });
  }
};

export default WebsocketApi;
