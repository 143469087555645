import { call, put, takeLatest } from "redux-saga/effects";
import {
  CONFIRM_MAIL_FAILURE,
  CONFIRM_MAIL_LOADING,
  CONFIRM_MAIL_REQUEST,
  CONFIRM_MAIL_SUCCESS,
} from "../actions/actionTypes";
import { confirmMailApi } from "../../api/confirmMail/confirmMail";

function* confirmMail(action) {
  try {
    yield put({ type: CONFIRM_MAIL_LOADING });
    
    // Log the request data for debugging
    console.log('Confirmation Request Data:', action.payload);
    
    const response = yield call(confirmMailApi, action.payload);
    yield put({ type: CONFIRM_MAIL_SUCCESS });

  } catch (error) {
    console.error('Confirmation Error:', error);
    const errorMessage = error.response?.data?.message || "An unknown error occurred";
    yield put({ type: CONFIRM_MAIL_FAILURE, error: errorMessage });
  }
}

function* confirmMailSaga() {
  yield takeLatest(CONFIRM_MAIL_REQUEST, confirmMail);
}

export default confirmMailSaga;
