import React, { useEffect, useRef, useState } from "react";
import "./MusicPartner.css";
import checkbox from "../../../assets/Images/checkboxIcon.svg";
import apps from "../../../assets/Images/apps.svg";
import SignUpPage from "../../../pages/SignUpPage/SignUpPage";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";

function MusicPartner({ setIsPopupOpen }) {
  const [showSignUpPage, setShowSignUpPage] = useState(false);
  const sectionRef = useRef(null);
  // useEffect(() => {
  //   const featureItems = document.querySelectorAll(".feature-item");

  //   const observer = new IntersectionObserver((entries) => {
  //     entries.forEach((entry, index) => {
  //       if (entry.isIntersecting) {
  //         entry.target.style.transitionDelay = `${1 + index * 0.6}s`;
  //         entry.target.classList.add("visible");
  //         observer.unobserve(entry.target);
  //       }
  //     });
  //   });

  //   featureItems.forEach((item) => {
  //     observer.observe(item);
  //   });
  // }, []);

  useGSAP(() => {
    const heading = document.querySelectorAll('.word');
    gsap.set(heading, { opacity: 0, filter: "blur(10px)", rotateX: -30 });

    gsap.to(heading, {
      opacity: 1,
      filter: "blur(0px)",
      rotateX: 0,
      duration: 1,
      stagger: 0.2,
      delay: 0.5,
      ease: "power2.out"
    });

    gsap.fromTo([".main-section_description", ".trial-button-container", ".main-section_heading", ".scroll-container"], {
      opacity: 0,
      y: 40
    }, {
      opacity: 1,
      y: 0,
      duration: 1,
      stagger: 0.2,
      delay: 0.5,
      ease: "power2.out"
    });

    gsap.fromTo(".feature-item", {
      y: 40,
      opacity: 0,
    }, {
      y: 0,
      opacity: 1,
      duration: 1.5,
      delay: 1,
      stagger: 0.2,
      ease: "power2.out"
    });
  }, { scope: sectionRef });

  const openSignUpPage = () => {
    setIsPopupOpen(true);
    setShowSignUpPage(true);
    sessionStorage.setItem("pageClosing", "true");
  };
  const closeSignUpModal = () => {
    setIsPopupOpen(false);
    setShowSignUpPage(false);
  };
  return (
    <div className="section" ref={sectionRef}>
      <div className="main-section">
        <h1 className="main-section_heading" style={{
          transformStyle: "preserve-3d",
          perspective: "1000px"
        }}>
          <div className="heading-line" style={{ display: "block" }}>
            {"WE ARE YOUR".split(" ").map((word, index) => (
              <span key={index} className="word" style={{ marginRight: '0.2em', display: "inline-block" }}>
                {word}
              </span>
            ))}
          </div>
          <div className="heading-line" style={{ display: "block" }}>
            {"SYNC PARTNERS".split(" ").map((word, index) => (
              <span key={`line2-${index}`} className="word" style={{ marginRight: '0.2em', display: "inline-block" }}>
                {word}
              </span>
            ))}
          </div>
        </h1>
        <p className="main-section_description">
        Elevating your creative spark to light the way forward, from writing session to sync success, we got you.
        </p>
        <div className="trial-button-container">
          <button className="trial-button" onClick={() => {
            openSignUpPage()
          }}>Get Started</button>
        </div>
      </div>
      <div className="scroll-container">
        <div className="logos">
          <img src={apps} alt="frame" />
          <img src={apps} alt="frame" />
          <img src={apps} alt="frame" />
          <img src={apps} alt="frame" />
          <img src={apps} alt="frame" />
          <img src={apps} alt="frame" />
          <img src={apps} alt="frame" />
          <img src={apps} alt="frame" />
          <img src={apps} alt="frame" />
          <img src={apps} alt="frame" />
          <img src={apps} alt="frame" />
          <img src={apps} alt="frame" />
        </div>
      </div>
      <div className="features">
        <div className="feature-item feature-item1">
          <img
            style={{ marginRight: 8 }}
            width={28}
            height={28}
            src={checkbox}
            alt="Checkbox"
          />
          Track feedback
        </div>
        <div className="feature-item feature-item2">
          <img
            style={{ marginRight: 8 }}
            width={28}
            height={28}
            src={checkbox}
            alt="Checkbox"
          />
          1 on 1 Consultation
        </div>
        <div className="feature-item feature-item3">
          <img
            style={{ marginRight: 8 }}
            width={28}
            height={28}
            src={checkbox}
            alt="Checkbox"
          />
          Distribution to networks
        </div>
        <div className="feature-item feature-item4">
          <img
            style={{ marginRight: 8 }}
            width={28}
            height={28}
            src={checkbox}
            alt="Checkbox"
          />
          Career strategy collaboration
        </div>
      </div>
      {showSignUpPage && <SignUpPage onClose={closeSignUpModal} fromHome={false} />}
    </div>
  );
}

export default MusicPartner;
