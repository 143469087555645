import { React, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import "./parallaxForm.css";
import "../../../styles/Fonts.scss";
import SMLLabel from "../../Shared/SMLLabel/SMLLabel";

const ParallaxForm = () => {
  const formRef = useRef(null);
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    message: ""
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.fromTo(
      [".Parallax-Form"],
      {
        opacity: 0,
        y: -40,
      },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        stagger: 0.2,
        scrollTrigger: {
          trigger: formRef.current,
          start: "top center+=100",
          toggleActions: "play none none reverse"
        }
      }
    );
  }, { scope: formRef });

  // Form validation and submission logic remains the same
  const validateForm = () => {
    const newErrors = {};
    
    if (!formState.name.trim()) {
      newErrors.name = "Name is required";
    } else if (formState.name.length < 2) {
      newErrors.name = "Name must be at least 2 characters";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formState.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!emailRegex.test(formState.email)) {
      newErrors.email = "Please enter a valid email address";
    }

    if (!formState.message.trim()) {
      newErrors.message = "Message is required";
    } else if (formState.message.length < 10) {
      newErrors.message = "Message must be at least 10 characters";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      if (process.env.NODE_ENV === 'development') {
        await new Promise(resolve => setTimeout(resolve, 1000));
        console.log('Form submitted locally:', formState);
        setSubmitStatus("success");
        setFormState({ name: "", email: "", message: "" });
        setErrors({});
      } else {
        const response = await fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams({
            "form-name": "contact",
            ...formState
          }).toString()
        });

        if (response.ok) {
          setSubmitStatus("success");
          setFormState({ name: "", email: "", message: "" });
          setErrors({});
        } else {
          setSubmitStatus("error");
        }
      }
    } catch (error) {
      console.error('Form submission error:', error);
      setSubmitStatus("error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState(prev => ({
      ...prev,
      [name]: value
    }));
    
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ""
      }));
    }
  };

  return (
    <div className="Parallax" id="form-section">
      <div className="Parallax-Form-container" ref={formRef}>
        {/* Header section - always visible */}
        <div className="Parallax-Header Parallax-Form">
          <div className="Parallax-Question-content">GOT A QUESTION?</div>
          <div className="Body Parallax-Question-description">
            Send us a message and we will respond shortly.
          </div>
        </div>

        {/* Form section */}
        <div className="Parallax-Content-Area Parallax-Form">
          {submitStatus === "success" ? (
            <div className="success-message">
              Thank you for your message! We'll get back to you soon.
            </div>
          ) : (
            <form
              className="Parallax-Form"
              onSubmit={handleSubmit}
              name="contact"
              method="POST"
              data-netlify="true"
              netlify-honeypot="bot-field"
            >
              <input type="hidden" name="form-name" value="contact" />
              <input type="hidden" name="bot-field" />
              
              <div className="Parallax-Form-Contents">
                <SMLLabel value="Your name" htmlFor="name" />
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formState.name}
                  onChange={handleInputChange}
                  className="SMLTextBox"
                  required
                />
                {errors.name && <div className="error-text">{errors.name}</div>}
              </div>
              
              <div className="Parallax-Form-Content">
                <SMLLabel value="Your Email" htmlFor="email" />
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formState.email}
                  onChange={handleInputChange}
                  className="SMLTextBox"
                  required
                />
                {errors.email && <div className="error-text">{errors.email}</div>}
              </div>
              
              <div className="Parallax-Form-Content">
                <SMLLabel value="Your Message" htmlFor="message" />
                <textarea
                  id="message"
                  name="message"
                  value={formState.message}
                  onChange={handleInputChange}
                  className="SMLTextArea"
                  required
                />
                {errors.message && <div className="error-text">{errors.message}</div>}
              </div>

              <button 
                type="submit" 
                disabled={isSubmitting}
                className="submit-button"
              >
                {isSubmitting ? "Sending..." : "Send Message"}
              </button>

              {submitStatus === "error" && (
                <div className="error-message">There was an error sending your message. Please try again.</div>
              )}
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ParallaxForm;