import React, { useEffect, useMemo, useRef, useState } from "react";
import "../../styles/Fonts.scss";
import "../Supervisor-Dashboard/TrackFeedback.css";
import "../../styles/Fonts.scss";
import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import { Row, Col } from "react-bootstrap";
import trackImg from "../../assets/Images/TrackRectangle.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SMLOptionQuestion from "../../components/Shared/SMLOptionQuestion/SMLOptionQuestion";
import ArtistProfile from "../../components/UI/artistProfile/artistProfile";
import AboutTrack from "../../components/UI/aboutTrack/aboutTrack";
import { useDispatch, useSelector } from "react-redux";
import { getSingleTrackRequest } from "../../redux/actions/uploadTrackActions";
import { getSingleAritstRequest } from "../../redux/actions";

import { useWavesurfer } from "@wavesurfer/react";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions.esm.js";
import { Box, Stack, IconButton } from "@mui/material";
import { Close, ArrowBack } from "@mui/icons-material";

import TrackPlayer from "../../components/common/track-details/TrackPlayer";
import CommentIcon from "../../assets/Icons/comment.svg";
import CommentGrayIcon from "../../assets/Icons/comment-grey.svg";

import { getAllCommentsApi } from "../../api/supervisor/comment";
import { downloadTrackApi } from "../../api/track/downloadTrackApi";
import { getTrackUploadUrlApi, uploadToS3 } from "../../api/track/getTrackUploadUrlApi";

import "../Reviews/AudioGraph.css";
import { Bars } from "react-loader-spinner";
import { formatTime } from "../Reviews/TrackUnderReview";
import HomeLayout from "../../components/Layout/HomeLayout";
import { Tooltip } from "react-tooltip";
import UploadTrack from "../../components/Shared/UploadTrack/UploadTrack";

const TrackDetail = (props) => {
  const location = useLocation();
  const status = location.state?.status;
  const fromDashboard = location.state?.fromDashboard;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { trackId } = useParams();
  const track = useSelector((state) => state.uploadTrack?.singleTrack);
  const artistId = useSelector(
    (state) => state.uploadTrack?.singleTrack?.artist_id
  );
  const artist = useSelector((state) => state.singleArtist?.details);
  const [showArtistProfile, setShowArtistProfile] = useState(false);
  const [showAboutTrack, setShowAboutTrack] = useState(false);
  const [done, setDone] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  
  const handleTrackEvaluation = () => {
    navigate(`/TrackEvaluated/${trackId}`);
  };

  const handleAboutTrack = () => {
    setShowAboutTrack(true);
  };

  const handleArtistProfile = () => {
    setShowArtistProfile(true);
  };

  const handleCloseArtistProfile = () => {
    setShowArtistProfile(false);
  };

  const handleCloseAboutTrack = () => {
    setShowAboutTrack(false);
  };

  useEffect(() => {
    dispatch(getSingleTrackRequest(trackId));
  }, [dispatch, trackId]);

  useEffect(() => {
    if (artistId) {
      dispatch(getSingleAritstRequest(artistId));
    }
  }, [artistId, dispatch]);

  const [peaks, setPeaks] = useState(null);
  const [trackUrl, setTrackUrl] = useState();
  const [peaksError, setPeaksError] = useState(false);
  const wavesurfRef = useRef(null);
  const regions = useMemo(() => RegionsPlugin.create(), []);

  const { wavesurfer, isPlaying } = useWavesurfer({
    container: wavesurfRef,
    waveColor: "#CBCBCB",
    progressColor: "#FF4405",
    cursorColor: "#FF4405",
    selectionColor: "#FF4405",
    barWidth: 3,
    url: !isPolling && trackUrl ? trackUrl : null,
    peaks: !isPolling && peaks ? peaks : null,
    height: 150,
    plugins: useMemo(() => [regions], [regions]),
  });

  async function fetchTrackUrl(trackId) {
    try {
      const { audio_signed_url, peaks_signed_url } = await downloadTrackApi({
        trackId,
      });
      
      // Store the audio URL
      setTrackUrl(audio_signed_url);

      // If peaks_signed_url exists, don't show re-upload even during initial load
      if (!peaks_signed_url) {
        console.log("No peaks URL available");
        setPeaksError(true);
        return false;
      }

      try {
        console.log("Fetching peaks data from:", peaks_signed_url);
        const peaksResponse = await fetch(peaks_signed_url);
        const peaksData = await peaksResponse.json();
        console.log("Peaks data fetched successfully");
        
        // Set peaks data and clear any errors
        setPeaks(peaksData.peaks);
        setPeaksError(false);
        return true;
      } catch (peaksError) {
        console.error("Error fetching peaks data:", peaksError);
        // Only set error if not polling
        if (!isPolling) {
          setPeaksError(true);
        }
        return false;
      }
    } catch (error) {
      console.error("Error fetching track URL:", error);
      // Only set error if not polling
      if (!isPolling) {
        setPeaksError(true);
      }
      return false;
    }
  }
  
  async function pollForPeaksData(trackId, maxAttempts = 10, delayMs = 2000) {
    setIsPolling(true);
    setPeaksError(false);
    let attempts = 0;
    
    while (attempts < maxAttempts) {
      console.log(`Polling for peaks data: attempt ${attempts + 1} of ${maxAttempts}`);
      const success = await fetchTrackUrl(trackId);
      
      // If we got peaks successfully, stop polling
      if (success) {
        console.log("Peaks data retrieved successfully, stopping polling");
        
        // Force wavesurfer to reinitialize after we stop polling
        // The delay helps ensure proper state updates sequence
        setIsPolling(false);
        
        // Allow the component to update, then trigger wavesurfer to reinitialize
        if (wavesurfer) {
          wavesurfer.destroy();
        }

        return true;
      }
      
      attempts++;
      if (attempts < maxAttempts) {
        await new Promise(resolve => setTimeout(resolve, delayMs));
      }
    }
    
    // Only set peaksError after all polling attempts have failed
    console.log("All polling attempts failed");
    setIsPolling(false);
    setPeaksError(true);
    return false;
  }

  // Initial load effect
  useEffect(() => {
    // Clear any existing errors on initial load or re-load
    setPeaksError(false);
    fetchTrackUrl(trackId).then(success => {
      // If initial fetch fails, don't set error yet
      console.log("Initial fetch result:", success);
    });
    
    if (status === "final_feedback_submitted") {
      getAllCommentsApi(trackId).then((uploadedComments) => {
        setComments(
          uploadedComments.map(
            ({ _id, start_time, end_time, comment }, index) => ({
              id: _id,
              time:
                start_time === end_time ? start_time : [start_time, end_time],
              transcript: comment,
              idSuffix: `${index}-${start_time}`,
            })
          )
        );
      });
    }
  }, [trackId]);

  // This effect watches for changes to peaks/trackUrl and resets wavesurfer
  useEffect(() => {
    // When peaks or trackUrl changes, reset the done state
    // to ensure wavesurfer properly reinitializes
    if (peaks && trackUrl && !isPolling) {
      console.log("Peaks and audio URL both available, preparing wavesurfer");
      setDone(false);
    }
  }, [peaks, trackUrl, isPolling]);

  // Update the wavesurfer effect
  useEffect(() => {
    if (wavesurfer) {
      console.log("Wavesurfer instance created");
      
      wavesurfer.on("ready", () => {
        console.log("Wavesurfer is ready");
        setDone(true);
      });

      wavesurfer.on("error", (error) => {
        if (error?.name === "AbortError") {
          console.warn("Audio loading aborted");
          // Don't set error during polling
          if (isPolling) return;
        } else {
          console.error("Error loading the audio", error);
        }
        // Only set error if not polling
        if (!isPolling) {
          setPeaksError(true);
        }
      });

      return () => {
        console.log("Cleaning up wavesurfer instance");
        if (wavesurfer && wavesurfer.isReady && wavesurfer.destroy) {
          wavesurfer.destroy();
        }
      };
    }
  }, [wavesurfer, isPolling]);

  const [comments, setComments] = useState([]);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [showAddCommentButton, setShowAddCommentButton] = useState(false);
  const [currentComment, setCurrentComment] = useState("");
  const inputRef = useRef(null);
  const [commentPosition, setCommentPosition] = useState(0);
  const commentBoxPosition = useMemo(() => {
    if (!wavesurfRef.current || !wavesurfer || !wavesurfer.getDuration) return 0;
    const rect = wavesurfRef.current.getBoundingClientRect();
    const x = (commentPosition / wavesurfer.getDuration()) * rect.width;
    return Math.min(x, rect.width - 300);
  }, [commentPosition, wavesurfer, wavesurfRef]);

  const [focusedComment, setFocusedComment] = useState(null);
  const focusedCommentContainer = useRef(null);

  useEffect(() => {
    if (!!wavesurfer && focusedComment !== null) {
      if (typeof comments[focusedComment].time === "number") {
        wavesurfer.setTime(comments[focusedComment].time);
      } else {
        regions.clearRegions();
        regions.addRegion({
          start: comments[focusedComment].time[0],
          end: comments[focusedComment].time[1],
          color: "#ff440540",
          resize: false,
        });
      }
    }
  }, [focusedComment, wavesurfer, regions, comments]);

  const handleClickOutside = (event) => {
    if (
      focusedCommentContainer.current &&
      !focusedCommentContainer.current.contains(event.target)
    ) {
      event.preventDefault();
      event.stopPropagation();
      regions.clearRegions();
      setFocusedComment(null);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.preventDefault();
      event.stopPropagation();
      regions.clearRegions();
      setFocusedComment(null);
      setActivePosition(null);
      setActiveRegion(null);
      setShowCommentBox(false);
      setShowAddCommentButton(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const [selcetedPart, setSelectedPart] = useState();
  const [activePosition, setActivePosition] = useState();
  const [activeRegion, setActiveRegion] = useState();

  useEffect(() => {
    if (!!wavesurfer && wavesurfRef.current) {
      const handleWaveformClick = (event) => {
        if (selcetedPart === "region") regions.clearRegions();
        setSelectedPart("point");
        setActivePosition(wavesurfer.getCurrentTime());
        setCommentPosition(wavesurfer.getCurrentTime());
        setShowAddCommentButton(true);
        setShowCommentBox(false);
      };

      wavesurfRef.current.addEventListener("click", handleWaveformClick);

      return () => {
        if (wavesurfRef.current) {
          wavesurfRef.current.removeEventListener("click", handleWaveformClick);
        }
      };
    }
  }, [wavesurfRef, wavesurfer, regions, setSelectedPart, setActivePosition, selcetedPart]);

  useEffect(() => {
    if (!showCommentBox) {
      setActivePosition(undefined);
      setActiveRegion(undefined);
      setSelectedPart(undefined);
      setCurrentComment("");
    } else if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [showCommentBox]);

  const user = useSelector((state) => state.sml.allArtistFetched?.artists);
  const reDirectToComment = (timeInSeconds) => {
    if (wavesurfer) {
      wavesurfer.seekTo(timeInSeconds / wavesurfer.getDuration());
      // wavesurfer.play();
    }
  };

  const handleUploadClick = async (file) => {
    try {
      // Reset states first and set loading indicator
      setDone(false);
      setPeaks(null);
      setTrackUrl(null);
      setPeaksError(false);
      setIsPolling(true);
      
      // Get signed URL for upload using existing API convention
      const { uploadUrl } = await getTrackUploadUrlApi({
        trackId,
        fileName: file.name
      });

      // Upload directly to S3
      await uploadToS3(uploadUrl, file);

      // Refetch track data
      await dispatch(getSingleTrackRequest(trackId));
      
      // Start polling for peaks data - this will stop once peaks are available
      await pollForPeaksData(trackId, 10);
    } catch (error) {
      console.error("Error during upload:", error);
      setIsPolling(false);
      setPeaksError(true);
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleUploadClick(file);
    }
  };

  // State to control upload popup visibility
  const [showUploadPopup, setShowUploadPopup] = useState(false);

  // This will be called when a file is selected from the UploadTrack popup
  const handleFileFromPopup = (file) => {
    if (file) {
      // Close the popup immediately after getting the file
      setShowUploadPopup(false);
      // When we get a file from the popup, use our existing upload process
      handleUploadClick(file);
    }
  };

  return (
    <HomeLayout>
      <div
        className="full-screen-artist"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {/* <div className="track-detail-header mx-auto my-3"></div> */}
        <div
          style={{
            paddingLeft: "2.3rem",
            marginTop: "-15px",
            marginBottom: "10px",
          }}
        >
          <IconButton
            sx={{
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            onClick={() => {
              if (fromDashboard) {
                navigate("/dashboard");
              } else {
                if (status === "submitted") navigate("/uploadedTrack");
                else if (status === "in_review") navigate("/Review");
                else if (status === "final_feedback_submitted")
                  navigate("/FeedbackReceived");
                else navigate("/dashboard");
              }
            }}
          >
            <div>
              <ArrowBack style={{ cursor: "pointer" }} />
            </div>
            <div>
              <SMLLabel
                style="title2"
                value={
                  fromDashboard
                    ? "Back to Dashboard"
                    : status === "submitted"
                    ? "Back to Uploaded Tracks"
                    : "Back to Under Review Tracks"
                }
              />
            </div>
          </IconButton>
        </div>
        <div
          className="padding-0"
          style={{ display: "flex", flexDirection: "column", flex: "1 1" }}
        >
          <div
            className="track-main-div"
            style={{
              position: "relative",
              // marginLeft: "40px",
            }}
          >
            <Row>
              <Col className="d-flex align-items-end" style={{ maxWidth: "111px" }}>
                <img
                  src={trackImg}
                  alt="Track"
                  width="111"
                  height="111"
                  className="img-fluid"
                />
              </Col>
              <Col className="d-flex flex-column justify-content-center">
                <div>
                  <SMLLabel
                    style="title1 mb-1"
                    value={
                      <span
                        ref={(el) => {
                          if (el && el.scrollWidth > el.clientWidth) {
                            el.setAttribute("data-tooltip-id", "trackTitles");
                            el.setAttribute("data-tooltip-content", track?.title);
                            el.setAttribute("data-tooltip-place", "bottom");
                          }
                        }}
                        className="title-text"
                      >
                        {track?.title.length > 43
                          ? `${track?.title.substring(0, 43)}...`
                          : track?.title}
                      </span>
                    }
                  />
                  <Tooltip id="trackTitles" />
                </div>
                {track?.original_track_id && (
                  <div className="revision-info">
                    <span className="revision-number">Revision {track.revision_number || "1"}</span>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/TrackDetails/${track.original_track_id}`, {
                          state: { status: 'submitted' },
                        });
                      }}
                      className="revision-link"
                    >
                      View original ↗
                    </button>
                  </div>
                )}
              </Col>
              <Col className="d-flex align-items-center justify-content-end">
                <SMLOptionQuestion
                  style="SMLSecondaryButton1"
                  type="button"
                  value="About Track"
                  handleClickEvent={handleAboutTrack}
                />
              </Col>
            </Row>
          </div>
          <div
            className="track-main-div"
            style={{ flex: "1 1", paddingTop: "20px" }}
          >
            {track && (
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  flexDirection: "column",
                }}
              >
                <Stack
                  sx={{
                    flexGrow: 1,
                    height: "100%",
                    maxHeight: "100%",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      height:
                        status === "final_feedback_status" ? "100%" : "auto",
                      maxHeight:
                        status === "final_feedback_status" ? "100%" : "none",
                      overflow: "hidden",
                      paddingTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                    }}
                  >
                    <div style={{ position: "relative", height: "30px" }}>
                      {wavesurfer && wavesurfer.getDuration &&
                        comments.map((comment, index) => (
                          <div
                            onClick={() => {
                              document
                                .getElementById(
                                  `transcript-${comment.idSuffix}`
                                )
                                .scrollIntoView({ behavior: "smooth" });
                              setFocusedComment(index);
                              regions.clearRegions();
                            }}
                            key={`comment-${comment.idSuffix}`}
                            style={{
                              position: "absolute",
                              left: `calc(${
                                ((typeof comment.time === "object"
                                  ? comment.time[0]
                                  : comment.time) /
                                  wavesurfer.getDuration()) *
                                87
                              }% + 9rem)`,
                              top: "3px",
                              height: "24px",
                              width: " 24px",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={
                                focusedComment === index
                                  ? CommentIcon
                                  : CommentGrayIcon
                              }
                              alt="Comment"
                            />
                          </div>
                        ))}
                    </div>
                    <div style={{ position: "relative", minHeight: "150px" }}>
                      {peaksError && !isPolling ? (
                        <div style={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          justifyContent: 'center',
                          height: '100%',
                          minHeight: '150px',
                          backgroundColor: 'rgba(20, 20, 20, 0.05)',
                          borderRadius: '8px'
                        }}>
                          <div style={{ textAlign: 'center' }}>
                            <div style={{ marginBottom: '1rem' }}>
                              <p style={{ 
                                color: "#FF4405", 
                                margin: "0 0 0.5rem 0",
                                fontSize: '16px',
                                fontWeight: '500',
                                lineHeight: '1.4'
                              }}>
                                We're having trouble processing your track. Please make sure it's in a standard audio format and try re-uploading.
                              </p>
                              <p style={{ 
                                color: "#666", 
                                margin: "0",
                                fontSize: '14px',
                                fontWeight: '400',
                                lineHeight: '1.4'
                              }}>
                                If you continue having trouble, message us in chat located bottom right corner and we'll help you as soon as possible.
                              </p>
                            </div>
                            <button 
                              onClick={() => setShowUploadPopup(true)}
                              style={{
                                backgroundColor: "#FF4405",
                                color: "white",
                                border: "none",
                                padding: "10px 20px",
                                borderRadius: "4px",
                                cursor: "pointer",
                                display: 'inline-block',
                                fontSize: '15px',
                                fontWeight: '500',
                                transition: 'opacity 0.2s ease'
                              }}
                              onMouseOver={(e) => e.target.style.opacity = '0.9'}
                              onMouseOut={(e) => e.target.style.opacity = '1'}
                            >
                              Re-upload Track
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div id="waveform" ref={wavesurfRef}>
                          {(!wavesurfer || !done || isPolling) && (
                            <div className="spinner-overlay">
                              <Bars
                                height="80"
                                width="80"
                                color="#23f0c7"
                                ariaLabel="bars-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div style={{ position: "relative", height: "20px" }}>
                      {showCommentBox && focusedComment === null && (
                        <div
                          className="comment-box"
                          style={{ left: commentBoxPosition }}
                        >
                          <div className="comment-header">
                            <span className="timestamp">
                              <div style={{ height: "24px", width: " 24px" }}>
                                <img src={CommentIcon} alt="Comment" />
                              </div>
                              {selcetedPart === "point"
                                ? `${formatTime(activePosition)}`
                                : selcetedPart === "region"
                                ? `${formatTime(
                                    activeRegion[0]
                                  )} - ${formatTime(activeRegion[1])}`
                                : ""}
                            </span>
                            <IconButton
                              sx={{ padding: 0.7 }}
                              onClick={() => {
                                if (selcetedPart === "region")
                                  regions.clearRegions();
                                setShowCommentBox(false);
                                setShowAddCommentButton(false);
                              }}
                            >
                              <Close sx={{ height: 20, width: 20 }} />
                            </IconButton>
                          </div>
                          <label>{user?.name}</label>
                        </div>
                      )}
                    </div>

                    <div id="descriptions" style={{ paddingTop: "30px" }}>
                      {comments.map((comment, index) =>
                        focusedComment !== index ? (
                          <div
                            id={`transcript-${comment.idSuffix}`}
                            key={`transcript-${comment.idSuffix}`}
                            className="input-track-notes"
                            style={{
                              opacity: focusedComment !== null ? 0.25 : 1,
                            }}
                            onClick={() =>
                              reDirectToComment(
                                typeof comment.time === "number"
                                  ? comment.time
                                  : comment.time[0]
                              )
                            }
                          >
                            <div className="comment-header">
                              <span className="timestamp">
                                <div style={{ height: "24px", width: " 24px" }}>
                                  <img src={CommentIcon} alt="Comment" />
                                </div>
                                {typeof comment.time === "object"
                                  ? `${formatTime(
                                      comment.time[0]
                                    )} - ${formatTime(comment.time[1])}`
                                  : `${formatTime(comment.time)}`}
                              </span>
                            </div>
                            <div className="content">
                              <div className="author">{user?.name}</div>
                              <div className="comment-content">
                                {comment.transcript}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            ref={focusedCommentContainer}
                            id={`transcript-${comment.idSuffix}`}
                            key={`transcript-${comment.idSuffix}`}
                            className="input-track-notes"
                          >
                            <div className="comment-header">
                              <span className="timestamp">
                                <div style={{ height: "24px", width: " 24px" }}>
                                  <img src={CommentIcon} alt="Comment" />
                                </div>
                                {typeof comment.time === "object"
                                  ? `${formatTime(
                                      comment.time[0]
                                    )} - ${formatTime(comment.time[1])}`
                                  : `${formatTime(comment.time)}`}
                              </span>
                            </div>
                            <label>{user?.name}</label>
                            <div ref={inputRef} type="text" className="content">
                              {comment.transcript}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </Stack>
                <Stack
                  sx={{
                    right: "0",
                    position: "fixed",
                    bottom: "0",
                    width: "calc(100% - 245px)",
                    "@media (max-width: 1200px)": {
                      width: "90%",
                    },
                  }}
                >
                  <TrackPlayer
                    track={track}
                    isPlaying={isPlaying}
                    wavesurfer={wavesurfer}
                    done={done}
                    commentEnabled={false}
                  />
                </Stack>
              </Box>
            )}
          </div>
        </div>
        {showArtistProfile && (
          <div className="popup-background-blur">
            <div className="ArtistProfile-popup">
              <ArtistProfile
                onArtistProfileClose={handleCloseArtistProfile}
                artist={artist}
              />
            </div>
          </div>
        )}
        {showAboutTrack && (
          <div className="popup-background-blur">
            <div className="ArtistProfile-popup">
              <AboutTrack
                onAboutTrackClose={handleCloseAboutTrack}
                track={track}
                fromArtistPortal={true}
              />
            </div>
          </div>
        )}
        {showUploadPopup && (
          <UploadTrack 
            onPopupClose={() => setShowUploadPopup(false)} 
            onDrop={handleFileFromPopup}
          />
        )}
      </div>
    </HomeLayout>
  );
};

export default TrackDetail;
