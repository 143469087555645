import React from "react";
import "../../../styles/Fonts.scss";
import closeIcon from "../../../assets/Images/closeIcon.svg";
import "./artistProfile.css";
import artistProfile from "../../../assets/Images/artistProfileLogo.svg";
import { Modal } from "react-bootstrap";

const ArtistProfile = ({ onArtistProfileClose, artist, show }) => {
  return (
    <Modal size="lg" onHide={onArtistProfileClose} className="custom-modal" show={show} centered>
      <div
        style={{ textAlign: "center" }}
        className="artistProfile-header Mainheader"
      >
        Artist Profile
        <div className="artistProfile-close-div" onClick={onArtistProfileClose}>
          <img src={closeIcon} alt="close" />
        </div>
      </div>
      <div className="artistProfile">
        <div className="" style={{ width: "70%" }}>
          <div className="artistProfile-rectangle">
            <div className="artistProfile-content">
              <div className="artistProfile-frame">
                <div className="artistProfile-logo">
                  <img src={artistProfile} alt="artistProfile" />
                  <div style={{ marginTop: "16px", width: "50%" }}>
                    <div className="lblArtistName">{artist?.name}</div>
                    <div className="lblSubTitle">
                      {artist?.role?.map((item, index) => (
                        <span key={index}>
                          {item}
                          {index < artist.role.length - 1 && ","}
                          &nbsp;
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="artistProfile-link ">
                  {artist?.personal_links?.map((link, index) => (
                    <div key={index}>{link}</div>
                  ))}
                </div>
              </div>

              <div className="artistProfile-description-container">
                <div className="artistProfile-description">
                  <div className="title2 profileTitle">Music Experience</div>
                  <div
                    className="sub-subtitle2 profileDescription"
                    style={{ textAlign: "left" }}
                  >
                    {artist?.music_experience_info}
                  </div>
                </div>
                <div className="artistProfile-description">
                  <div className="title2 profileTitle">Guidance Needed</div>
                  <div
                    className="sub-subtitle2 profileDescription"
                    style={{ textAlign: "left" }}
                  >
                    {artist?.guidance_needed_info}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ArtistProfile;
