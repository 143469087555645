import {
  CONFIRM_MAIL_FAILURE,
  CONFIRM_MAIL_LOADING,
  CONFIRM_MAIL_SUCCESS,
  RESET_CONFIRM_MAIL,
} from "../actions/actionTypes";

export const confirmMailReducer = (
  state = {
    confirmMail: {
      isLoading: false,
      isError: false,
      error: undefined,
      confirmed: false,
    },
  },
  action
) => {
  switch (action.type) {
    case CONFIRM_MAIL_LOADING:
      return {
        ...state,
        confirmMail: {
          isLoading: true,
          isError: false,
          error: undefined,
          confirmed: false,
        },
      };
    case CONFIRM_MAIL_SUCCESS:
      return {
        ...state,
        confirmMail: {
          isLoading: false,
          isError: false,
          error: undefined,
          confirmed: true,
        },
      };
    case CONFIRM_MAIL_FAILURE:
      return {
        ...state,
        confirmMail: {
          isLoading: false,
          isError: true,
          error: action.error,
          confirmed: false,
        },
      };
    case RESET_CONFIRM_MAIL:
      return {
        ...state,
        confirmMail: {
          isLoading: false,
          isError: false,
          error: undefined,
          confirmed: false,
        },
      };
    default:
      return state;
  }
};
