import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import closeIcon from "../../../assets/Images/closeIcon.svg";
import { AlertTriangleIcon } from "lucide-react";
import "./RestrictionPopup.css";
import plans from "../../../pages/SignUpPage/ChoosePlan/features.json";
import checkBox from "../../../assets/Images/checkboxIcon.svg";

const RevisionRestrictionPopup = ({
  handleAddOnPlanClick,
  onClose,
  showChoosePlan,
  setShowChoosePlan,
}) => {
  const plan = plans[0];
  const handleCardClick = () => {
    handleAddOnPlanClick(plan, 0);
  };
  return (
    <Modal
      show={true}
      onHide={onClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="restriction-sub-modal"
    >
      <div className="restriction-sub-modal-content-wrapper">
        <div
          className="restriction-sub-modal-close-btn"
          onClick={() => {
            // setShowChoosePlan(true);
            onClose();
          }}
        >
          <img src={closeIcon} alt="Close" />
        </div>
        <div className="restriction-sub-modal-body-wrapper">
          <AlertTriangleIcon className="restriction-sub-modal-icon" />
          <div className="restriction-sub-modal-title">
            Track Analysis Service
          </div>
          <div className="restriction-sub-modal-message">
            <p style={{ fontWeight: "600", marginBottom: "2rem" }}>
              Get professional feedback on your track
            </p>
            <div
              className="special-plan-card1"
              style={{ margin: "0" }}
              onClick={() => handleCardClick()}
            >
              <div className="add-on-services-btn">
                <div>
                  <span style={{ fontSize: "1.6rem", fontWeight: "900" }}>
                    $149
                  </span>
                  /track
                </div>
                <div>Professional Analysis</div>
              </div>
            </div>
            <p className="d-flex align-items-end gap-2 checbox-restriction-popup">
              <span className="">
                <img src={checkBox} />
              </span>
              Detailed track analysis within 48 hours
              {/* {plan.features.map((item, index) => {
                return index === 0 ? <p>{item}</p> : <div></div>;
              })} */}
            </p>
            <p className="d-flex align-items-end gap-2 checbox-restriction-popup">
              <span className="">
                <img src={checkBox} />
              </span>
              Professional feedback from industry experts
            </p>
          </div>
          <div className="d-flex-col align-items-center justify-content-center gap-4">
            <div
              className="special-plan-card-button2"
              style={{ margin: "0" }}
              onClick={() => handleCardClick()}
            >
              <div className="add-on-services-btn">
                <div style={{fontWeight : "500"}}>Purchase Analysis</div>
              </div>
            </div>
            <div>
              <button
                className="restriction-sub-action-button secondary"
                onClick={() => {
                  // setShowChoosePlan(true);
                  onClose();
                }}
                style={{ marginTop : "0.3rem" }}
              >
                Maybe Later
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RevisionRestrictionPopup;
