import React, { useState, useEffect } from 'react';
import Styles from './NewsStrip.module.css';
import { useNavigate } from 'react-router-dom';
import { ExternalLink, Clock } from 'lucide-react';

const NewsStrip = ({ brief, totalBriefs, currentIndex, onPrevious, onNext }) => {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState('');
  const [urgencyLevel, setUrgencyLevel] = useState('normal');
  const [animationKey, setAnimationKey] = useState(0);

  useEffect(() => {
    const calculateTimeLeft = () => {
      const difference = new Date(brief.deadline).getTime() - new Date().getTime();
      
      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        
        // Set urgency level
        if (days === 0 && hours < 12) {
          setUrgencyLevel('urgent');
        } else if (days === 0) {
          setUrgencyLevel('warning');
        } else {
          setUrgencyLevel('normal');
        }

        if (days > 0) {
          setTimeLeft(`${days}d ${hours}h left`);
        } else {
          setTimeLeft(`${hours}h left`);
        }
      } else {
        setTimeLeft('Expired');
        setUrgencyLevel('expired');
      }
    };

    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 1000 * 60);

    return () => clearInterval(timer);
  }, [brief.deadline]);

  useEffect(() => {
    setAnimationKey(prev => prev + 1);
  }, [brief, currentIndex]);

  const handleViewDetails = (e) => {
    e.stopPropagation();
    if (brief.fileUrl) {
      window.open(brief.fileUrl, '_blank');
    } else {
      window.open(`/resources/brief/${brief.id}`, '_blank');
    }
  };

  const formatExpiryDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const handleIndicatorClick = (index) => {
    if (index < currentIndex) {
      onPrevious();
    } else if (index > currentIndex) {
      onNext();
    }
  };

  return (
    <div className={Styles.stripWrapper}>
      <div className={Styles.briefIndicators}>
        {[...Array(totalBriefs)].map((_, index) => (
          <div
            key={index}
            className={`${Styles.indicator} ${index === currentIndex ? Styles.activeIndicator : ''}`}
          />
        ))}
      </div>
      
      <div 
        key={animationKey}
        className={`${Styles.stripContainer} ${Styles[urgencyLevel]}`}
      >
        <div className={Styles.content} onClick={handleViewDetails}>
          <span className={`${Styles.tag} ${Styles[`tag-${urgencyLevel}`]}`}>Brief</span>
          <span className={Styles.title}>{brief.title}</span>
          <span className={Styles.description}>{brief.description}</span>
        </div>

        <div className={Styles.rightContent}>
          <div className={`${Styles.timer} ${Styles[`timer-${urgencyLevel}`]}`}>
            <Clock size={14} />
            <span>{timeLeft}</span>
            <span className={Styles.expiryDate}>
              Expires on {formatExpiryDate(brief.deadline)}
            </span>
          </div>
          <button className={Styles.viewMore} onClick={handleViewDetails}>
            <ExternalLink size={16} />
            <span>View Details</span>
          </button>
        </div>
      </div>
      <div className={Styles.progressBar}>
        <div className={Styles.progressBarInner} />
      </div>
    </div>
  );
};

export default NewsStrip; 