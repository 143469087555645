import React from 'react';
import { GoogleLogin } from "@react-oauth/google";
import SMLButtonWithIcon from '../../Shared/SMLButtonWithIcon/SMLButtonWithIcon';

const GoogleLoginButton = ({ onSuccess, onError }) => {
  return (
    <div style={{ width: '100%', maxWidth: '280px', position: 'relative' }}>
      <GoogleLogin
        onSuccess={onSuccess}
        onError={onError}
        useOneTap={false}
        width="280"
        theme="outline"
        size="large"
        text="sign_in_with"
        shape="rectangular"
        type="standard"
        logo_alignment="center"
        context="signin"
        ux_mode="popup"
        style={{
          justifyContent: 'center',
          alignItems: 'center'
        }}
      />
    </div>
  );
};

export default GoogleLoginButton; 