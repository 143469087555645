import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import SMLVideoPlayer from "./components/Shared/SMLVideoPlayer/SMLVideoPlayer";
import UploadTrack from "./components/Shared/UploadTrack/UploadTrack";
import AboutTrack from "./components/UI/aboutTrack/aboutTrack";
import ArtistProfile from "./components/UI/artistProfile/artistProfile";
import ViewSubmission from "./components/UI/viewSubmission/viewSubmission";
import FeedbackForum from "./pages/Feedback/FeedbackForum";
import FeedbackReceived from "./pages/Feedback/FeedbackReceived";
import Login from "./pages/LoginPage/LoginPage";
import Logintest from "./pages/LoginPage/LoginTest";
import MyProfile from "./pages/MyProfilePage/MyProfile";
import ViewPlan from "./pages/MyProfilePage/ViewPlan/ViewPlan";
import Review from "./pages/Reviews/Reviews";
import SignUpPage from "./pages/SignUpPage/SignUpPage";
import UploadedTrackPage from "./pages/UploadedTrackPage/UploadedTrackPage";
// import Dashboard from "./pages/dashboard/dashboard";
import SupervisorLogin from "./SupervisorPage/SupervisorLogin/SupervisorLogin";
//import SupervisiorPage from "./SupervisorPage/SupervisorPage";
import SupervisorDasboard from "./pages/Supervisor-Dashboard/SupDashboard";
import TrackCheck from "./pages/TrackCheck/TrackCheck";
import TrackEvaluation from "./SupervisorPage/TrackEvaluationPage/TrackEvaluation";
import TrackEvaluationResults from "./SupervisorPage/TrackEvaluationPage/TrackEvaluatedResults";
import Payment from "./pages/SignUpPage/Payment/Payment";
import SubmitTrackPopup from "./SupervisorPage/SubmitTrackPopups/SubmitTrackPopup";
import Dashboard from "./pages/dashboard/Dashboard";
import TrackUnderReview from "./pages/Reviews/TrackUnderReview";
import TrackFeedback from "./pages/Supervisor-Dashboard/TrackFeedback";
import SupervisiorPage from "./SupervisorPage/SupervisorPage";
import SignUpPlanPage from "./SignUpPlanPages/SignUpPlanPages";
import PlanPayment from "./SignUpPlanPages/PlanPayment/PlanPayment";

import ParallaxCertified from "./components/Parallax/parallaxGetCertified/parallaxCertified";
import GetFeedbackExpert from "./components/Parallax/parallaxGetFeedbackExpert/getFeedbackExpert";
import ParallaxLogin from "./components/Parallax/parallaxLogin/parallaxLogin";
import UploadYourTracks from "./components/Parallax/parallaxUploadTracks/uploadYourTracks";
import DynamicText from "./components/Parallax/parallaxDynamic/parallxDynamic";
import Home from "./components/Parallax/parallaxHome";
import TermsCondition from "./components/UI/TermsAndConditions/TermsAndCondition";
import ParallaxVideo from "./components/Parallax/parallaxVideoPage/parallaxVideo";
import ParallaxPlan from "./components/Parallax/planParallax/ParallaxPlan";
import ProtectedArtistRoutes from "./protectedRoutes/ProtectedArtistRoutes";
import ProtectedSupervisorRoutes from "./protectedRoutes/ProtectedSupervisorRoutes";
import TrackEvaluatedArtist from "./SupervisorPage/TrackEvaluationPage/TrackEvaluationArtist";
import TrackDetail from "./pages/Supervisor-Dashboard/TrackDetail";

import PrivacyPolicy from "./pages/PrivacyPolicyPage";
import TermsAndConditions from "./pages/TermsAndConditions";
import { useEffect, useRef, useState } from "react";
import TrackDetailFinal from "./pages/Supervisor-Dashboard/TrackDetailFinal";
import ConfirmMail from "./components/common/confirm-mail/ConfirmMail";
import ResetPassword from "./components/common/forget-password/ResetPassword";
import SubmitFeedback from "./pages/Supervisor-Dashboard/SubmitFeedback";
import TrackFeedbackReviewed from "./pages/Supervisor-Dashboard/TrackFeedbackReviewed";
import SupervisorPage from "./SupervisorPage/SupervisorPage";
import { Terms } from "./pages/TermsAndConditions/TermsAndConditions";
import { useDispatch } from "react-redux";
import { websocketConnect } from "./redux/actions/websocketActions";
import ClosedAccount from "./components/common/confirm-mail/ClosedAccount";
import ResourcesPage from "./pages/ResourcesPage/ResourcesPage";
import ResourceManagement from "./pages/Supervisor-Dashboard/Resources/ResourceManagement";
import LogoutConfirm from "./components/common/logout-confirm/LogoutConfirm";
import ArtistManagement from "./pages/Supervisor-Dashboard/ArtistManagement/ArtistManagement";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const loggedIn = localStorage.getItem('token');
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  useEffect(() => {
    if (loggedIn) {
      dispatch(websocketConnect());
    }
  }, [dispatch]);
  // useEffect(() => {
  //   const handleTabClose = () => {
  //     localStorage.clear(); // Remove specific key
  //     // Or use localStorage.clear() to remove all localStorage data
  //   };
  //   window.addEventListener('beforeunload', handleTabClose);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleTabClose);
  //   };
  // }, []);
  // const INACTIVITY_LIMIT = 60 * 5 * 1000;
  // const INACTIVITY_LIMIT = 59 * 60 * 1000;

  // let logoutTimer;

  const logout = () => {
    console.log('logout called');
    localStorage.clear();
    navigate('/');
  };

  const handleConfirm = () => {
    setShowLogoutPopup(false);
    logout();
  };

  const handleCancel = () => {
    console.log('triggered');
    setShowLogoutPopup(false);
  };

  // import { useRef } from 'react';

  const logoutTimerRef = useRef(null);

  const handleVisibilityChange = () => {
    const token = localStorage.getItem("token");
    if (document.visibilityState === 'hidden' && token ) {
      logoutTimerRef.current = setTimeout(() => {
        setShowLogoutPopup(true);
      }, 3600000);
      // }, 5000)
    } else if (document.visibilityState === 'visible') {
      clearTimeout(logoutTimerRef.current);
    }
  };

  // const handleVisibilityChange = () => {
  //   // if (location.pathname === '/') return;
  //   if (document.visibilityState === 'hidden') {
  //     logoutTimer = setTimeout(() => {
  //       setShowLogoutPopup(true);
  //     }, INACTIVITY_LIMIT);
  //   } else if (document.visibilityState === 'visible') {
  //     clearTimeout(logoutTimer);
  //   }
  // };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  useEffect(() => {
    // Define routes to exclude from clearing localStorage
    const isProtectedRoute =
      location.pathname.startsWith('/track-feedback') ||
      location.pathname.startsWith('/reset-password') ||
      // Add protection for the redirect after confirmation
      (location.pathname === '/' && location.state?.emailConfirmed);

    // Only clear localStorage if this is a new tab and not on protected routes
    if (!isProtectedRoute && sessionStorage.getItem('isTabOpen') !== 'true') {
      console.log('Clearing localStorage:', {
        pathname: location.pathname,
        state: location.state,
        isProtectedRoute,
      });
      // localStorage.clear();
      // sessionStorage.setItem('isTabOpen', 'true');
    }
  }, [location]);
  return (
    <div>
      <Routes>
        <Route path="*" element={<Navigate to="/" />} />
        {/* Home routes with section navigation */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<Home />} />
        <Route exact path="/how-it-works" element={<Home />} />
        <Route exact path="/pricing" element={<Home />} />

        {/* login routes */}
        <Route exact path="/logintest" element={<Logintest />} />
        <Route exact path="/supervisor/login" element={<SupervisorLogin />} />
        <Route exact path="/supervisor/signup" element={<SupervisorPage />} />
        <Route exact path="/SMLVideoPlayer" element={<SMLVideoPlayer />} />
        {/* <Route exact path="/login" element={<Login title={"login"} />} /> */}
        {/* <Route exact path="/signUp" element={<SignUpPage />} /> */}
        <Route
          exact
          path="/confirm-email"
          element={<Home confirmMail={true} />}
        />
        <Route exact path="/closed-account" element={<ClosedAccount />} />
        <Route exact path="/reset-password" element={<ResetPassword />} />

        <Route
          exact
          path="/track-feedback/:trackId"
          element={<SubmitFeedback />}
        />
        {/* <Route
          exact
          path="/Supervisor/Login"
          element={<SupervisorLogin title={"Supervisor Login"} />}
        /> */}
        <Route exact path="/SupervisorPage" element={<SupervisiorPage />} />
        {/* artist protected routes */}
        <Route element={<ProtectedArtistRoutes />}>
          <Route
            exact
            path="/TrackDetails/:trackId"
            element={<TrackDetail />}
          />
          <Route
            exact
            path="/TrackDetailsFinal/:trackId"
            element={<TrackDetailFinal />}
          />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/viewPlan" element={<ViewPlan />} />
          <Route exact path="/myProfile" element={<MyProfile />} />
          <Route exact path="/uploadedtrack" element={<UploadedTrackPage />} />
          <Route exact path="/review" element={<Review />} />
          <Route
            exact
            path="/feedbackreceived"
            element={<FeedbackReceived />}
          />
          <Route exact path="/TrackCheck" element={<TrackCheck />} />
          <Route
            exact
            path="/TrackUnderReview"
            element={<TrackUnderReview />}
          />
          <Route
            exact
            path="/TrackEvaluated/:trackId"
            element={<TrackEvaluatedArtist />}
          />
          <Route exact path="/resources" element={<ResourcesPage />} />
        </Route>

        {/* protected routes for supervisor */}
        <Route element={<ProtectedSupervisorRoutes />}>
          <Route exact path="/TrackPage/:trackId" element={<TrackFeedback />} />
          <Route
            exact
            path="/TrackEvaluationPage/:trackId"
            element={<TrackEvaluation />}
          />
          <Route
            exact
            path="/TrackEvaluatedPage/:trackId"
            element={<TrackEvaluationResults />}
          />
          <Route
            exact
            path="/supervisorDashboard"
            element={<SupervisorDasboard />}
          />
          <Route
            exact
            path="/TrackFeedback/:trackId"
            element={<TrackFeedback />}
          />
          <Route
            exact
            path="/TrackFeedbackReviewed/:trackId"
            element={<TrackFeedbackReviewed />}
          />
          <Route
            exact
            path="/supervisor/resources"
            element={<ResourceManagement />}
          />
          <Route
            exact
            path="/supervisor/artists"
            element={<ArtistManagement />}
          />
        </Route>

        {/* not protected routes which may be used for testing the component */}
        <Route exact path="/payment" element={<Payment />} />
        <Route exact path="/feedbackforum" element={<FeedbackForum />} />
        <Route exact path="/uploadtrack" element={<UploadTrack />} />
        <Route exact path="/viewSubmission" element={<ViewSubmission />} />
        <Route exact path="/aboutTrack" element={<AboutTrack />} />
        <Route exact path="/artistProfile" element={<ArtistProfile />} />
        <Route exact path="/SubmitTrackPopup" element={<SubmitTrackPopup />} />
        <Route exact path="/signUpPlanPages" element={<SignUpPlanPage />} />
        <Route exact path="/PlanPayment" element={<PlanPayment />} />
        <Route exact path="/DynamicText" element={<DynamicText />} />
        <Route exact path="/ParallaxLogin" element={<ParallaxLogin />} />
        <Route
          exact
          path="/ParallaxCertified"
          element={<ParallaxCertified />}
        />
        <Route
          exact
          path="/GetFeedbackExpert"
          element={<GetFeedbackExpert />}
        />
        <Route exact path="/UploadYourTracks" element={<UploadYourTracks />} />
        <Route exact path="/ParallaxVideo" element={<ParallaxVideo />} />
        <Route exact path="/ParallaxPlan" element={<ParallaxPlan />} />

        <Route
          path="/privacy-policy"
          element={<PrivacyPolicy standalone={true} />}
        />
        <Route exact path="/terms" element={<Terms />} />
      </Routes>
      {showLogoutPopup && (
        <LogoutConfirm onConfirm={handleConfirm} onCancel={handleCancel} />
      )}
    </div>
  );
}

export default App;
