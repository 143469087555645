import api from "../api";

export const getResourcesApi = async (category) => {
  const accessToken = localStorage.getItem("token");
  if (!accessToken) {
    throw new Error("No access token found");
  }

  const searchPayload = {
    category: category.split(','),
    hidden: false,
    archived: false,
    expired: false,
    offset: 0,
    limit: 50,
    sortBy: "created_at",
    sortOrder: "desc"
  };

  const response = await api.post('/resources/search', searchPayload, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    }
  });
  return response.data;
};

export const getUploadUrlApi = async (data) => {
  const accessToken = localStorage.getItem("token");
  if (!accessToken) {
    throw new Error("No access token found");
  }

  const response = await api.post('/resources/upload-url', data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    }
  });
  return response.data;
};

export const uploadToS3Api = async (uploadUrl, file, contentType) => {
  const response = await fetch(uploadUrl, {
    method: 'PUT',
    body: file,
    headers: {
      'Content-Type': contentType
    }
  });
  return response;
};

export const createResourceApi = async (data) => {
  const accessToken = localStorage.getItem("token");
  if (!accessToken) {
    throw new Error("No access token found");
  }

  const response = await api.post('/resources', data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    }
  });
  return response.data;
};

export const deleteResourceApi = async (resourceId) => {
  const accessToken = localStorage.getItem("token");
  if (!accessToken) {
    throw new Error("No access token found");
  }

  const response = await api.delete(`/resources/${resourceId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    }
  });
  return response.data;
};

export const updateResourceApi = async (resourceId, updateData) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/resources/${resourceId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify(updateData)
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Failed to update resource');
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};
