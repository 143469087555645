import React from "react";
import Styles from "./SideBar.module.css";
import logo from "../../../assets/Images/Logo.svg";
import support from "../../../assets/Images/Support.svg";
import Dashboard from "../../../assets/Images/dashboardIcon.svg";
import feedbackReceived from "../../../assets/Images/feebbackIcon.svg";
import myProfile from "../../../assets/Images/profileIcon.svg";
import underReview from "../../../assets/Images/reviewIcon.svg";
import uploadedTracks from "../../../assets/Images/uploadedTracksIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  RESET_GET_ALL_TRACK,
  RESET_SEARCH,
} from "../../../redux/actions/actionTypes";
import { getAllTracksFetch } from "../../../redux/actions/smlActions";

export default function SideBar() {
  return (
    <div className={Styles.container}>
      <Header />
      <Navigation />
      <ProfileLink />
    </div>
  );
}

const Header = () => {
  return (
    <div className={Styles.header}>
      <img src={logo} alt="Company Logo" />
    </div>
  );
};

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isDashboard = location.pathname.includes("Dashboard")
  const isUploadedTrack = location.pathname.includes("uploadedtrack");
  const isReview = location.pathname.includes("Review");
  const isFeedbackReceived = location.pathname.includes("FeedbackReceived");
  const dispatch = useDispatch();
  const handleNavigateClick = (Path) => {
    navigate(Path);
  };
  return (
    <div className={Styles.navigation}>
      <ul>
        <li
          onClick={() => {
            !Dashboard && dispatch({ type: RESET_SEARCH });
            !Dashboard && dispatch({ type: RESET_GET_ALL_TRACK });
            handleNavigateClick("/Dashboard");
          }}
        >
          <img
            onClick={() => handleNavigateClick("/Dashboard")}
            src={Dashboard}
            alt="Dashboard"
          />
          <span>Dashboard</span>
        </li>
        <li
          onClick={() => {
            handleNavigateClick("/uploadedtrack");
            !isUploadedTrack && dispatch({ type: RESET_GET_ALL_TRACK });
            !isUploadedTrack && dispatch({ type: RESET_SEARCH });
          }}
        >
          <img
            onClick={() => handleNavigateClick("/uploadedtrack")}
            src={uploadedTracks}
            alt="Uploaded Tracks"
          />
          <span>Uploaded Tracks</span>
        </li>
        <li
          onClick={() => {
            handleNavigateClick("/Review");
            !isReview && dispatch({ type: RESET_SEARCH });
            !isReview && dispatch({ type: RESET_GET_ALL_TRACK });
          }}
        >
          <img
            onClick={() => handleNavigateClick("/Review")}
            src={underReview}
            alt="Under Review"
          />
          <span>Under Review</span>
        </li>
        <li
          onClick={() => {
            handleNavigateClick("/FeedbackReceived");
            !isFeedbackReceived && dispatch({ type: RESET_SEARCH });
            !isFeedbackReceived && dispatch({ type: RESET_GET_ALL_TRACK });
          }}
        >
          <img
            onClick={() => handleNavigateClick("/FeedbackReceived")}
            src={feedbackReceived}
            alt="Feedback Received"
          />
          <span>Feedback Received</span>
        </li>
        <li onClick={() => handleNavigateClick("/Resources")}>
          <img
            onClick={() => handleNavigateClick("/Resources")}
            src={support}
            alt="Resources"
          />
          <span>Resources</span>
        </li>
      </ul>
    </div>
  );
};

const ProfileLink = () => {
  const navigate = useNavigate();
  
  return (
    <div className={Styles.profileLink}>
      <ul>
        <li onClick={() => navigate("/Myprofile")}>
          <img
            onClick={() => navigate("/Myprofile")}
            src={myProfile}
            alt="My Profile"
          />
          <span>My Profile</span>
        </li>
      </ul>
    </div>
  );
};
