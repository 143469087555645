import React, { useEffect, useMemo, useRef, useState } from "react";
import "../../styles/Fonts.scss";
import "../Supervisor-Dashboard/TrackDetailFinal.css";
import "../../styles/Fonts.scss";
import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import SMLButton from "../../components/Shared/SMLButton/SMLButton";
import { Col, Modal, Row } from "react-bootstrap";
import trackImg from "../../assets/Images/TrackRectangle.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArtistProfile from "../../components/UI/artistProfile/artistProfile";
import AboutTrack from "../../components/UI/aboutTrack/aboutTrack";
import { useDispatch, useSelector } from "react-redux";
import { getSingleTrackRequest } from "../../redux/actions/uploadTrackActions";
import {
  getOriginalTrackRequest,
  getProAnalysisRequest,
  getSingleAritstRequest,
} from "../../redux/actions";

import { useWavesurfer } from "@wavesurfer/react";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions.esm.js";
import { Box, Stack, IconButton } from "@mui/material";
import { Close, ArrowBack } from "@mui/icons-material";

import TrackPlayer from "../../components/common/track-details/TrackPlayer";
import CommentIcon from "../../assets/Icons/comment.svg";
import CommentGrayIcon from "../../assets/Icons/comment-grey.svg";
import CommentTealIcon from "../../assets/Icons/comment-teal.svg";

import { getAllCommentsApi } from "../../api/supervisor/comment";
import { downloadTrackApi } from "../../api/track/downloadTrackApi";

import "../Reviews/AudioGraph.css";
import { Bars } from "react-loader-spinner";
import { formatTime } from "../Reviews/TrackUnderReview";
import HomeLayout from "../../components/Layout/HomeLayout";
import TrackEvaluationArtist from "../../SupervisorPage/TrackEvaluationPage/TrackEvaluationArtist";
import { Tooltip } from "react-tooltip";
import { requestVideoConsultation } from "../../redux/actions/videoConsultationActions";
import CalendlyConsultation from "../../components/Appointment/CalendlyConsultation/CalendlyConsultation";
import VideoCard from "../../components/common/video-card/VideoCard";
import { getVideoUrlRequest } from "../../redux/actions";
import UploadTrack from "../../components/Shared/UploadTrack/UploadTrack";
import DurationFormatter from "../../components/Shared/DurationFormatter/DurationFormatter";
import RevisionRestrictionPopup from "../../components/common/restricted-popup/Revision-RestrictionPopup";
import { GET_UPLOAD_STATS_REQUEST, RESET_CREATE_SUB } from "../../redux/actions/actionTypes";
import PaymentPopup from "../../components/common/payment-popup/PaymentPopup";
import ViewPlanSuccess from "../MyProfilePage/ViewPlan/ViewPlanSuccess";
import VidRestrictionPopup from "../../components/common/restricted-popup/Video-RestrictionPopup";
import TrackComments from '../../components/common/track-comments/TrackComments';
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const TrackDetailFinal = (props) => {
  const location = useLocation();
  const status = location.state?.status;
  const fromDashboard = location.state?.fromDashboard;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { trackId } = useParams();
  const track = useSelector((state) => state.uploadTrack?.singleTrack);
  const artistId = useSelector(
    (state) => state.uploadTrack?.singleTrack?.artist_id
  );
  const artist = useSelector((state) => state.singleArtist?.details);
  const [showArtistProfile, setShowArtistProfile] = useState(false);
  const [showAboutTrack, setShowAboutTrack] = useState(false);
  const [done, setDone] = useState(false);
  const [showConsultation, setShowConsultation] = useState(false);
  const [showVideoPopup, setShowVideoPopup] = useState(false);
  const [showUploadTrack, setShowUploadTrack] = useState(false);
  const [showVidRestriction, setShowVidRestriction] = useState(false);
  const [showRevisionRestrictionPopup, setShowRevisionRestrictionPopup] =
    useState(false);
  const [showChoosePlan, setShowChoosePlan] = useState(false);
  const [selectedPlanData, setSelectedPlanData] = useState(null);
  const [isYearly, setIsYearly] = useState("");
  const [currentComponent, setCurrentComponent] = useState("plan");
  const [index, setIndex] = useState();
  const [serviceType, setServiceType] = useState("");

  const handleAboutTrack = () => {
    setShowAboutTrack(true);
  };

  const handleArtistProfile = () => {
    setShowArtistProfile(true);
  };

  const handleCloseArtistProfile = () => {
    setShowArtistProfile(false);
  };

  const handleCloseAboutTrack = () => {
    setShowAboutTrack(false);
  };

  useEffect(() => {
    console.log(trackId, "trackId");
    if (trackId) {
      dispatch(getSingleTrackRequest(trackId));
      dispatch(getProAnalysisRequest({ trackId }));
    }
  }, [dispatch, trackId]);

  useEffect(() => {
    if (track?.original_track_id) {
      dispatch(getOriginalTrackRequest(track?.original_track_id));
    }
  }, [track, dispatch]);

  const originalTrack = useSelector((state) => state.originalTrack?.track);

  const isSubscription = useSelector(
    (state) => state.payment?.getProAnalysisReducer?.data
  );

  useEffect(() => {
    if (artistId) {
      dispatch(getSingleAritstRequest(artistId));
    }
  }, [artistId, dispatch]);

  const [peaks, setPeaks] = useState(null);
  const [trackUrl, setTrackUrl] = useState();
  const waveformRef = useRef(null);
  const regions = useMemo(() => RegionsPlugin.create(), []);

  const wavesurferOptions = {
    container: waveformRef,
    waveColor: '#d1d5db',
    progressColor: '#ff4d4f',
    barWidth: 2,
    barGap: 1,
    barRadius: 2,
    responsive: true,
    normalize: true,
    height: 100,
    url: trackUrl,
    peaks,
  };

  const waveformContainerStyle = {
    height: '128px',
    marginBottom: '20px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    padding: '14px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.05)'
  };

  const { wavesurfer, isPlaying } = useWavesurfer(wavesurferOptions);

  async function fetchTrackUrl(trackId) {
    try {
      const { audio_signed_url, peaks_signed_url } = await downloadTrackApi({
        trackId,
      });
      const peaksResponse = await fetch(peaks_signed_url);
      const peaksData = await peaksResponse.json();
      setPeaks(peaksData.peaks);
      setTrackUrl(audio_signed_url);
    } catch (error) {
      console.error("Error fetching track URL:", error);
    }
  }
  useEffect(() => {
    fetchTrackUrl(trackId);
    // if (status === "final_feedback_submitted") {
    getAllCommentsApi(trackId).then((uploadedComments) => {
      setComments(
        uploadedComments.map(
          ({ _id, start_time, end_time, comment }, index) => ({
            id: _id,
            time: start_time === end_time ? start_time : [start_time, end_time],
            transcript: comment,
            idSuffix: `${index}-${start_time}`,
          })
        )
      );
    });
    // }
  }, [trackId]);
  useEffect(() => {
    if (wavesurfer) {
      wavesurfer.on("ready", () => {
        setDone(true);
        console.log("Wavesurfer is ready");
      });

      wavesurfer.on("error", (error) => {
        if (error?.name === "AbortError") {
          console.warn("Audio loading aborted");
        } else {
          console.error("Error loading the audio", error);
        }
      });
    }

    return () => {
      if (wavesurfer && wavesurfer.isReady && wavesurfer.destroy) {
        wavesurfer.destroy();
      }
    };
  }, [wavesurfer]);

  useEffect(() => {
    return () => {
      if (wavesurfer) {
        wavesurfer.destroy();
      }
    };
  }, [wavesurfer]);

  const [comments, setComments] = useState([]);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [showAddCommentButton, setShowAddCommentButton] = useState(false);
  const [currentComment, setCurrentComment] = useState("");
  const [charLimit, setCharLimit] = useState(50);

  const inputRef = useRef(null);
  const [commentPosition, setCommentPosition] = useState(0);
  const commentBoxPosition = useMemo(() => {
    if (!waveformRef.current) return 0;
    const rect = waveformRef.current.getBoundingClientRect();
    const x = (commentPosition / wavesurfer.getDuration()) * rect.width;
    return Math.min(x, rect.width - 300);
  }, [commentPosition, wavesurfer, waveformRef]);

  const [focusedComment, setFocusedComment] = useState(null);
  const focusedCommentContainer = useRef(null);

  useEffect(() => {
    if (!!wavesurfer && focusedComment !== null) {
      if (typeof comments[focusedComment].time === "number") {
        wavesurfer.setTime(comments[focusedComment].time);
      } else {
        regions.clearRegions();
        regions.addRegion({
          start: comments[focusedComment].time[0],
          end: comments[focusedComment].time[1],
          color: "#ff440540",
          resize: false,
        });
      }
    }
  }, [focusedComment, wavesurfer, regions, comments]);

  const handleGlobalClick = (e) => {
    // Only proceed if there's a focused comment
    if (focusedComment !== null) {
      // Check if click is on a comment that's not focused
      const clickedComment = e.target.closest('.input-track-notes');
      const isFocusedComment = clickedComment?.classList.contains('focused');
      
      // Release focus if clicked outside or on a different comment
      if (!isFocusedComment) {
        setFocusedComment(null);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  };

  const handleCommentClick = (e, comment, index) => {
    e.stopPropagation();
    if (focusedComment === index) {
      // If clicking the same comment, release focus
      setFocusedComment(null);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      // Focus new comment and redirect
      setFocusedComment(index);
      reDirectToComment(
        typeof comment.time === "number"
          ? comment.time
          : comment.time[0]
      );
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.preventDefault();
      event.stopPropagation();
      regions.clearRegions();
      setFocusedComment(null);
      setActivePosition(null);
      setActiveRegion(null);
      setShowCommentBox(false);
      setShowAddCommentButton(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleKeyDown);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("mousedown", handleKeyDown);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const [selcetedPart, setSelectedPart] = useState();
  const [activePosition, setActivePosition] = useState();
  const [activeRegion, setActiveRegion] = useState();

  useEffect(() => {
    if (!!wavesurfer)
      waveformRef.current.addEventListener("click", (event) => {
        if (selcetedPart === "region") regions.clearRegions();
        setSelectedPart("point");
        setActivePosition(wavesurfer.getCurrentTime());
        setCommentPosition(wavesurfer.getCurrentTime());
        setShowAddCommentButton(true);
        setShowCommentBox(false);
      });
  }, [
    waveformRef,
    wavesurfer,
    regions,
    setSelectedPart,
    setActivePosition,
    selcetedPart,
  ]);

  useEffect(() => {
    if (!showCommentBox) {
      setActivePosition(undefined);
      setActiveRegion(undefined);
      setSelectedPart(undefined);
      setCurrentComment("");
    } else if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [showCommentBox]);

  const user = useSelector((state) => state.sml.allArtistFetched?.artists);
  const videoConsultationLoading = useSelector(
    (state) => state.videoConsultation.loading
  );
  const reDirectToComment = (timeInSeconds) => {
    if (wavesurfer) {
      wavesurfer.seekTo(timeInSeconds / wavesurfer.getDuration());
    }
  };

  const handleVideoConsultation = (e) => {
    e.preventDefault();
    dispatch(requestVideoConsultation(trackId));
  };

  useEffect(() => {
    const updateCharLimit = () => {
      const width = window.innerWidth;

      // Set character limits based on screen size
      if (width > 1600) {
        setCharLimit(90); // Large screens
      } else if (width > 1200) {
        setCharLimit(60); // Tablet screens
      } else if (width > 1024) {
        setCharLimit(40); // Tablet screens
      } else if (width > 992) {
        setCharLimit(30); // Tablet screens
      } else if (width > 768) {
        setCharLimit(20); // Tablet screens
      } else {
        setCharLimit(10); // Mobile screens
      }
    };

    // Run on load and add listener for screen resize
    updateCharLimit();
    window.addEventListener("resize", updateCharLimit);

    // Clean up event listener on unmount
    return () => window.removeEventListener("resize", updateCharLimit);
  }, []);

  const feedbackId = useSelector(
    (state) => state.getFeedback?.feedback?.[0]?._id
  );
  const videoUrl = useSelector(
    (state) => state.video?.signedUrlData?.video_url
  );

  useEffect(() => {
    if (feedbackId) {
      dispatch(getVideoUrlRequest(feedbackId));
    }
  }, [feedbackId, dispatch]);

  const handleConsultationClick = () => {
    setCurrentComponent("payment");
    // wavesurfer.pause();
    const vidConsultationCount = isSubscription?.data?.filter(
      (sub) => sub.serviceType === "VIDEO_CONSULTATION"
    ).length;
    if (vidConsultationCount > 0) {
      setShowConsultation((prev) => !prev);
    } else {
      setShowVidRestriction(true);
      setServiceType("VIDEO_CONSULTATION");
    }
  };

  const handleAddOnPlanClick = (plan, index) => {
    setSelectedPlanData(plan);
    setIsYearly(false);
    setShowChoosePlan(true);
    setIndex(index);
    setCurrentComponent("payment");
  };

  const handleAddOnPlanClick1 = (plan, index) => {
    setSelectedPlanData(plan);
    setIsYearly(false);
    setShowChoosePlan(true);
    setIndex(index);
    setCurrentComponent("payment");
  };

  const handleResubmitButtonClick = () => {
    console.log(isSubscription?.data?.length, "see");
    if (track?.revision_number === 0 && track?.total_revisions < 1) {
      setShowUploadTrack(true);
    }
    if (track?.revision_number > 0 && isSubscription?.data?.length === 0) {
      setShowRevisionRestrictionPopup(true);
      // wavesurfer.pause();
      setServiceType("PAY_PER_TRACK");
    }
    const payPerTrackCount = isSubscription?.data?.filter(
      (sub) => sub.serviceType === "PAY_PER_TRACK"
    ).length;
    if (track?.revision_number <= originalTrack?.total_revisions) {
      if (payPerTrackCount >= originalTrack?.total_revisions) {
        setShowUploadTrack(true);
        console.log(payPerTrackCount, "count");
      } else {
        setShowRevisionRestrictionPopup(true);
        setServiceType("PAY_PER_TRACK");
        // wavesurfer.pause();
      }
    }
  };

  const handleOpenUploadTrack = () => {
    navigate(-1);
  };
  const handleOpenVideoConsultation = () => {
    dispatch({type : RESET_CREATE_SUB})
    setShowChoosePlan(false);
    setShowVidRestriction(false)

    setCurrentComponent("payment")
    setShowConsultation(true)
    dispatch(getProAnalysisRequest({trackId }))
  };

  const handleBack = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  // Add state to track active comment
  const [isCommentActive, setIsCommentActive] = useState(false);

  return (
    <HomeLayout>
      <div
        className="full-screen-artist"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {videoConsultationLoading && (
          <div className="spinner-overlay">
            <Bars
              height="80"
              width="80"
              color="#23f0c7"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
        <div className="track-header">
          <a href="#" onClick={handleBack} className="back-link">
            <ArrowBackIcon /> Back
          </a>
          <h1 className="page-title">Sync Music Lab Evaluation</h1>
          <div style={{ width: "100px" }}></div>
        </div>
        <div className="d-flex justify-content-center w-100 mb-3"></div>
        <div className="d-flex align-items-center justify-content-between w-100 gap-2 mb-4">
          <div
            className="d-flex align-items-center gap-3"
            style={{ margin: "0 1.2rem", width: "80%" }}
          >
            <div onClick={handleAboutTrack} style={{ maxWidth: "100%" }}>
              <div
                style={{ width: "100%" }}
                className="track-title"
                data-tooltip-id="trackTitle-details-final"
                data-tooltip-content={track?.title}
                data-tooltip-place="bottom"
              >
                {track?.title}
              </div>
              <Tooltip id="trackTitle-details-final" multiline={true} />
            </div>
            <span
              style={{
                backgroundColor: "#f8f9fa",
                color: "#6c757d",
                padding: "2px 8px",
                borderRadius: "12px",
                fontSize: "12px",
              }}
            >
              {track?.duration ? (
                <DurationFormatter seconds={track.duration} />
              ) : (
                "00:00"
              )}
            </span>
          </div>

          {track?.status?.code === "final_feedback_submitted" && (
            <div className="d-flex justify-content-end align-items-center gap-3">
              {/* {track?.total_revisions === 0 && ( */}
              {track.is_latest_revision && (
                <SMLButton
                  value="Re-Submit"
                  style="resubmit-track-btn"
                  handleClickEvent={handleResubmitButtonClick}
                />
              )}
              {/* )} */}
              {videoUrl && (
                <SMLButton
                  value="Download Video Eval"
                  style="req-video-btn"
                  handleClickEvent={() => setShowVideoPopup(true)}
                />
              )}
              <SMLButton
                value="Schedule Live Consultation"
                style="req-video-btn2"
                handleClickEvent={handleConsultationClick}
                // handleClickEvent={() => setShowVidRestriction(true)}
              />
            </div>
          )}
        </div>
        <div
          className=""
          style={{ display: "flex", flexDirection: "column", flex: "1 1" }}
        >
          <div
            className="track-main-div"
            style={{
              position: "relative",
              marginLeft: "20px",
            }}
          >
            <div className="d-flex align-items-center justify-content-start gap-5">
              <Col className="d-flex flex-column">
                <div style={waveformContainerStyle}>
                  <div ref={waveformRef}>
                    {(!wavesurfer || wavesurfer.getDuration() === 0) && (
                      <div className="spinner-overlay">
                        <Bars
                          height="80"
                          width="80"
                          color="#23f0c7"
                          ariaLabel="bars-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      </div>
                    )}
                  </div>
                </div>
                
                {/* Comment icons below waveform */}
                <div className="comment-icons-wrapper">
                  {wavesurfer &&
                    comments.map((comment, index) => (
                      <div
                        onClick={() => {
                          document
                            .getElementById(`transcript-${comment.idSuffix}`)
                            .scrollIntoView({
                              behavior: "smooth",
                              block: "center",
                            });
                          setFocusedComment(index);
                          regions.clearRegions();
                        }}
                        key={`comment-${comment.idSuffix}`}
                        className={`comment-icon ${focusedComment === index ? 'active' : ''}`}
                        style={{
                          left: `${
                            ((typeof comment.time === "object"
                              ? comment.time[0]
                              : comment.time) /
                              wavesurfer.getDuration()) *
                            100
                          }%`,
                        }}
                      >
                        <img
                          src={
                            focusedComment === index
                              ? CommentTealIcon
                              : CommentIcon
                          }
                          alt="Comment"
                        />
                      </div>
                    ))}
                </div>
                
                <div style={{ position: "relative", height: "20px" }}>
                  {showCommentBox && focusedComment === null && (
                    <div
                      className="comment-box"
                      style={{ left: commentBoxPosition }}
                    >
                      <div className="comment-header">
                        <span className="timestamp">
                          <div style={{ height: "24px", width: " 24px" }}>
                            <img src={CommentIcon} alt="Comment" />
                          </div>
                          {selcetedPart === "point"
                            ? `${formatTime(activePosition)}`
                            : selcetedPart === "region"
                            ? `${formatTime(activeRegion[0])} - ${formatTime(
                                activeRegion[1]
                              )}`
                            : ""}
                        </span>
                        <IconButton
                          sx={{ padding: 0.7 }}
                          onClick={() => {
                            if (selcetedPart === "region")
                              regions.clearRegions();
                            setShowCommentBox(false);
                            setShowAddCommentButton(false);
                          }}
                        >
                          <Close sx={{ height: 20, width: 20 }} />
                        </IconButton>
                      </div>
                      <label>{user?.name}</label>
                    </div>
                  )}
                </div>
              </Col>
            </div>
          </div>
          <div
            className="track-main-div"
            style={{ flex: "1 1", paddingTop: "" }}
          >
            {track && (
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  flexDirection: "column",
                }}
              >
                <Stack
                  sx={{
                    flexGrow: 1,
                    height: "100%",
                    maxHeight: "100%",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      height:
                        status === "final_feedback_status" ? "100%" : "auto",
                      maxHeight:
                        status === "final_feedback_status" ? "100%" : "none",
                      overflow: "hidden",
                      paddingTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                    }}
                  >
                    <div onClick={handleGlobalClick}>
                      <div className="detail-results">
                        <div className="track-evaluation-section">
                          <TrackEvaluationArtist />
                        </div>
                        <div className="comments-section">
                          <TrackComments 
                            comments={comments}
                            focusedComment={focusedComment}
                            onCommentClick={handleCommentClick}
                            user={user}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Stack
                    sx={{
                      position: "fixed",
                      right: "0",
                      bottom: "0",
                      width: "calc(100% - 245px)",
                      "@media (max-width: 1200px)": {
                        width: "90%",
                      },
                      zIndex: 50,
                      backgroundColor: "#fff",
                      borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                      boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.05)"
                    }}
                  >
                    <TrackPlayer
                      track={track}
                      isPlaying={
                        !showVidRestriction && !showRevisionRestrictionPopup
                          ? isPlaying
                          : false
                      }
                      wavesurfer={wavesurfer}
                      done={
                        !showVidRestriction && !showRevisionRestrictionPopup
                          ? done
                          : false
                      }
                      commentEnabled={showUploadTrack}
                    />
                  </Stack>
                </Stack>
              </Box>
            )}
          </div>
        </div>
        {showArtistProfile && (
          <div className="popup-background-blur">
            <div className="ArtistProfile-popup">
              <ArtistProfile
                onArtistProfileClose={handleCloseArtistProfile}
                artist={artist}
              />
            </div>
          </div>
        )}
        {showAboutTrack && (
          <div className="popup-background-blur">
            <div className="ArtistProfile-popup">
              <AboutTrack
                onAboutTrackClose={handleCloseAboutTrack}
                track={track}
                fromArtistPortal={true}
              />
            </div>
          </div>
        )}
        {showVideoPopup && (
          <div>
            <VideoCard
              setShowVideoPopup={setShowVideoPopup}
              trackId={trackId}
              videoUrl={videoUrl}
            />
          </div>
        )}
        {showConsultation && (
          <CalendlyConsultation
            isOpen={showConsultation}
            onClose={() => setShowConsultation(false)}
            trackTitle={track?.title}
          />
        )}
        {showUploadTrack && (
          <UploadTrack
            forResubmit={true}
            parentTrack={track}
            onPopupClose={() => setShowUploadTrack(false)}
          />
        )}
        {showRevisionRestrictionPopup && (
          <RevisionRestrictionPopup
            handleAddOnPlanClick={handleAddOnPlanClick}
            onClose={() => {
              setShowVidRestriction(false);
              setShowRevisionRestrictionPopup(false);
            }}
          />
        )}
        {showVidRestriction && (
          <VidRestrictionPopup
            onClose={() => {
              setShowVidRestriction(false);
              setShowRevisionRestrictionPopup(false);
            }}
            handleAddOnPlanClick={handleAddOnPlanClick1}
          />
        )}
        {showChoosePlan && (
          <Modal
            className="custom-modal"
            show={true}
            onHide={() => {
              if (currentComponent !== "payment") {
                setShowChoosePlan(false);
                setIndex(null);
                dispatch({ type: GET_UPLOAD_STATS_REQUEST });
                setCurrentComponent("plan");
              }
              if (currentComponent === "welcome") {
                window.location.reload();
              }
            }}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            {currentComponent === "payment" && (
              <PaymentPopup
                selectedPlan={selectedPlanData}
                closePopup={() => {
                  setShowChoosePlan(false);
                  window.location.reload();
                }}
                nextClick={() => setCurrentComponent("welcome")}
                backClick={() => {
                  setShowChoosePlan(false);
                  setShowRevisionRestrictionPopup(false);
                  setShowVidRestriction(false);
                }}
                specialService={serviceType}
                forUpgrade={true}
                isYearly={isYearly}
              />
            )}
            {currentComponent === "welcome" && (
              <ViewPlanSuccess
                specialService={serviceType}
                upgradePlan="upgrade"
                paymentSuccessClick={
                  serviceType === "PAY_PER_TRACK"
                    ? handleOpenUploadTrack
                    : serviceType === "VIDEO_CONSULTATION"
                    ? handleOpenVideoConsultation
                    : setShowChoosePlan(false)
                }
                closePopup={() => {
                  setShowChoosePlan(false);
                  window.location.reload();
                }}
              />
            )}
          </Modal>
        )}
      </div>
    </HomeLayout>
  );
};

export default TrackDetailFinal;
