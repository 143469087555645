import api from "../api";

export const getArtistsApi = async (params = {}) => {
  try {
    const accessToken = localStorage.getItem("token");
    if (!accessToken) {
      throw new Error("No access token found");
    }

    // Construct base payload with root level parameters
    const payload = {
      offset: params.offset || 0,
      limit: params.limit || 10,
      sortBy: params.sortBy || 'createdAt',
      sortOrder: params.sortOrder || -1
    };

    // Only add searchParams if keyword exists and is not empty
    const hasKeyword = params.keyword && params.keyword.trim().length > 0;
    if (hasKeyword) {
      payload.searchParams = {
        keyword: params.keyword.trim()
      };
    }

    const response = await api.post('/profiles/artists/search', payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching artists:", error);
    throw error;
  }
};