import React, { useState, useEffect } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import SMLLabel from "../../../components/Shared/SMLLabel/SMLLabel";
import ViewPlan from "../../SignUpPage/ChoosePlan/ChoosePlan.json";
import "./ViewPlan.css";
import checkBox from "../../../assets/Images/checkboxIcon.svg";
import closeIcon from "../../../assets/Images/closeIcon.svg";
import "../../../styles/Fonts.scss";
import { useNavigate } from "react-router-dom";
import SMLToggleButton from "../../../components/Shared/SMLToggleButton/SMLToggleButton";
import PriceTags from "../../../components/PriceTags/PriceTags";

const ViewPlans = ({ viewPlanClose }) => {
  const [selectedCard, setSelectedCard] = useState(ViewPlan[0]);
  const [isYearly, setIsYearly] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const cards = document.getElementsByClassName("payment-card");
    for (let i = 0; i < cards.length; i++) {
      if (
        parseInt(cards[i].getAttribute("data-index")) ===
        ViewPlan.indexOf(selectedCard)
      ) {
        cards[i].style.backgroundColor = "#FF4405";
      } else {
        cards[i].style.backgroundColor = "#E9EAEC";
      }
    }
  }, [selectedCard, ViewPlan]);

  const handleCardClick = (index) => {
    if (index !== 0) {
      setSelectedCard(ViewPlan[index]);
    }
  };

  const handleButtonClick = (index) => {
    if (index !== 0) {
      const selectedCardElement = document.querySelector(
        `.payment-card[data-index='${index}']`
      );
      if (
        selectedCardElement &&
        selectedCardElement.style.backgroundColor === "rgb(255, 68, 5)"
      ) {
        navigate("/PlanPayment", { state: { selectedCard: ViewPlan[index] } });
      }
    }
  };

  const handleSelectedCardClick = (index) => {
    const selectedCardElement = document.querySelector(
      `.payment-card[data-index='${index}']`
    );
    if (
      selectedCardElement &&
      selectedCardElement.style.backgroundColor === "rgb(255, 68, 5)"
    ) {
      navigate("/PlanPayment", { state: { selectedCard: ViewPlan[index] } });
    }
  };

  const formatPrice = (priceString) => {
    if (!priceString) return { whole: '0', decimal: '' };
    const price = priceString.replace('$', '').replace(',', '');
    const [whole, decimal] = price.split('.');
    return {
      whole: whole,
      decimal: decimal ? `.${decimal}` : '' // Only include decimal if it exists, with the dot
    };
  };

  return (
    <div className="viewPlan-container">
      <div className="viewPlan-card">
        <div className="viewPlan-close-div">
          <img src={closeIcon} onClick={viewPlanClose} />
        </div>
        <div className="viewPlan-main">
          <div className="viewPlan-label-div">
            <SMLLabel value="Upgrade Your Plan" style="Mainheader1" />
          </div>
          <div className="plans-main-div">
            <div className="plan-toggle">
              <label className="title2">Monthly</label>
              <SMLToggleButton
                value={isYearly}
                onChange={() => {
                  setIsYearly(!isYearly);
                }}
              />
              <label className="title2">Yearly</label>
              <span className="annual-discount-text">Get 2 months free with annual plans</span>
            </div>
            <div className="plans-main">
              {ViewPlan.map((payment, index) => (
                <div key={index}>
                  <Card
                    className="payment-card"
                    data-index={index}
                    style={{ borderStyle: "none" }}
                    onClick={() => handleCardClick(index)}
                  >
                    <Card.Header
                      className="card-Header"
                      style={{ background: "none", borderStyle: "none" }}
                    >
                      <div className="title1" style={{ textAlign: "center" }}>
                        <Card.Title style={{ top: "50px" }}>
                          {payment.name}
                        </Card.Title>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      {payment.price && (
                        <div className="viewPlan-duration">
                          {payment.oldPrice && (
                            <p className="plan-old-price">
                              {isYearly ? payment.oldYearlyPrice : payment.oldPrice}
                            </p>
                          )}
                          <span className="price-whole">
                            {formatPrice(isYearly ? payment.yearlyPrice : payment.price).whole}
                          </span>
                          <span className="price-decimal">
                            {formatPrice(isYearly ? payment.yearlyPrice : payment.price).decimal}
                          </span>
                          <span className="plan-duration">
                            {isYearly ? "/year" : "/month"}
                          </span>
                        </div>
                      )}

                      <div className="viewPlan-horizontal-line"></div>
                      <Card.Text
                        className={`viewPlan-card-content ${
                          selectedCard === index ? "selected-card" : ""
                        }`}
                      >
                        {payment.features.map((feature, featureIndex) => {
                          if (typeof feature === "object" && feature.type === "highlight") {
                            return (
                              <div key={featureIndex} className="highlight-block">
                                <PriceTags />
                                {feature.items.map((item, itemIndex) => (
                                  <div key={itemIndex} className="feature-with-checkbox">
                                    <img src={checkBox} alt="checkbox" />
                                    <SMLLabel value={item} />
                                  </div>
                                ))}
                              </div>
                            );
                          }
                          return (
                            <div key={featureIndex} className="feature-with-checkbox">
                              <img src={checkBox} alt="checkbox" />
                              <SMLLabel value={feature} />
                            </div>
                          );
                        })}
                        {index === 0 ? (
                          <button className="first-card-button">
                            {payment.buttonText}
                          </button>
                        ) : index === 1 ? (
                          <button
                            onClick={() => handleButtonClick(index)}
                            className="custom-button"
                          >
                            {payment.buttonText}
                          </button>
                        ) : (
                          <button
                            onClick={() => handleSelectedCardClick(index)}
                            className="last-card-button"
                          >
                            {payment.buttonText}
                          </button>
                        )}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPlans;
