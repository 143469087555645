import api from "../api";
const BASE_URL = process.env.REACT_APP_API_URL;
export const downloadTrackApi = async ({ trackId, forDownload }) => {
  try {
    const accessToken = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    trackId = trackId;
    forDownload = forDownload;
    const response = await api.get(
      `${BASE_URL}/tracks/${trackId}/download${
        forDownload ? "?forDownload=true" : "?peaks=true"
      }`,
      config
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error downloading single track",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};
