import React from "react";
import { Modal } from "react-bootstrap";
import closeIcon from "../../../assets/Images/closeIcon.svg";
import { AlertTriangleIcon } from "lucide-react";
import "./RestrictionPopup.css";
import plans from "../../../pages/SignUpPage/ChoosePlan/ChoosePlan.json";

const RestrictionPopup = ({
  handleAddOnPlanClick,
  onClose,
  showChoosePlan,
  setShowChoosePlan,
}) => {
  const plan = plans[3];
  const handleCardClick = () => {
    handleAddOnPlanClick(plan, 3);
  };

  return (
    <Modal
      show={true}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="restriction-sub-modal"
    >
      <div className="restriction-sub-modal-content-wrapper">
        <div
          className="restriction-sub-modal-close-btn"
          onClick={() => {
            setShowChoosePlan(false);
            onClose();
          }}
        >
          <img src={closeIcon} alt="Close" />
        </div>
        <div className="restriction-sub-modal-body-wrapper">
          <AlertTriangleIcon className="restriction-sub-modal-icon" />
          <div className="restriction-sub-modal-title">Upgrade Required</div>
          <div className="restriction-sub-modal-message">
            You need to upgrade your plan to upload more tracks.
          </div>

          <div className="d-flex-col align-items-center justify-content-center gap-4">
            <div
              className="special-plan-card-button2"
              style={{ margin: "0" }}
              onClick={() => {
                setShowChoosePlan(true);
                onClose();
              }}
            >
              <div>
                <div style={{fontWeight: "500"}}>Upgrade Plan</div>
              </div>
            </div>
            <div>
              <button
                className="restriction-sub-action-button secondary"
                onClick={() => {
                  setShowChoosePlan(false);
                  onClose();
                }}
                style={{ marginTop: "0.3rem" }}
              >
                Maybe Later
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RestrictionPopup;