import axios from "axios";
import store from "../redux/store/configureStore";
import {
  refreshTokenRequest,
  logoutRequest,
} from "../redux/actions/authActions";
import { refreshToken } from "./auth/refreshToken";
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log("the error is here ", error);
    const originalRequest = error.config;
    if (
      error.response.data.message === "Token expired" &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return api(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const access_token = localStorage.getItem("token");
        const refresh_token = localStorage.getItem("refresh_token");
        
        const new_access_token = localStorage.getItem("token");
        if (!refresh_token || !access_token ) {
          store.dispatch(logoutRequest());
          localStorage.clear();
          return Promise.reject("No refresh token found. Logged out.");
        }
        
        await refreshToken({ access_token, refresh_token });
        api.defaults.headers.common["Authorization"] =
          "Bearer " + new_access_token;
        originalRequest.headers["Authorization"] = "Bearer " + new_access_token;
        processQueue(null, new_access_token);
        window.location.reload();
        return api(originalRequest);
      } catch (err) {
        processQueue(err, null);
        store.dispatch(logoutRequest());
        localStorage.clear();
        isRefreshing = false;
        return Promise.reject(err);
      } finally {
        isRefreshing = false;
      }
    }
    return Promise.reject(error);
  }
);

export const unAuthorizedApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export default api;
