import React, { useEffect, useRef, useState } from "react";
import "./ParallaxPlan.css";
import "../../../styles/Fonts.scss";
import plans from "../../../pages/SignUpPage/ChoosePlan/ChoosePlan.json";
import checkBox from "../../../assets/Images/checkboxIcon.svg";
import SMLToggleButton from "../../Shared/SMLToggleButton/SMLToggleButton";
import bestValue from "../../../assets/Images/popular.svg";
import earlyAccessBadge from "../../../assets/Images/earlyAccess.svg";
import { useDispatch, useSelector } from "react-redux";
import { getPlansRequest } from "../../../redux/actions";
import SignUpPage from "../../../pages/SignUpPage/SignUpPage";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import PriceTags from "../../PriceTags/PriceTags";
gsap.registerPlugin(ScrollTrigger);

const ParallaxPlan = () => {
  const dispatch = useDispatch();
  const [sendIndex, setSendIndex] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(sendIndex);
  const [selectedPlanData, setSelectedPlanData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [isYearly, setIsYearly] = useState(false);

  const containerRef = useRef(null);


  useGSAP(() => {
    gsap.fromTo(
      ".pricing-title",
      {
        opacity: 0,
        y: -40,
      },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: containerRef.current,
          start: "top 80%",
        },
      }
    );

    gsap.fromTo(
      ".toggle-buttons",
      {
        opacity: 0,
        y: -40,
      },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: containerRef.current,
          start: "top 80%",
        },
      }
    );

    // Plan cards animation

    gsap.fromTo(
      ".plan-card",
      {
        opacity: 0,
        filter: "blur(10px)",
        y: 40,
      },
      {
        opacity: 1,
        filter: "blur(0px)",
        y: 0,
        delay: 0.2,
        duration: 1,
        ease: "expo.out",
        stagger: 0.1,
        onComplete: () => {
          gsap.fromTo(
            ".best-value-image",
            {
              rotation: -5,
            },
            {
              rotation: 5,
              duration: 0.5,
              ease: "power1.inOut",
              yoyo: true,
              repeat: 1, // Infinite repeat

            }
          );
        },
        scrollTrigger: {
          trigger: containerRef.current,
          start: "top 80%",
        },
      }
    );

  }, { scope: containerRef }); // Empty dependency array means this will run once when component mounts

  useEffect(() => {
    dispatch(getPlansRequest()); // Fetch available plans from endpoint
  }, []);

  const plans1 = useSelector((state) => state.payment.getPlansReducer?.plans);

  //logic to match plan in json to the plan price in endpoint
  const findMatchingPlan = (plan) => {
    // If it's a free plan, return formatted free plan
    if (plan.name.toLowerCase() === 'free') {
      return {
        ...plan,
        amount: 0,
        name: 'Free',
        price: '$0',
        skipPayment: true
      };
    }
    
    // For paid plans, find matching stripe plan and merge with original plan data
    const priceId = isYearly ? plan.YearlyId : plan.monthlyId;
    const stripePlan = plans1?.find((p1) => p1.id === priceId);
    
    if (stripePlan) {
      return {
        ...stripePlan,
        name: plan.name,  // Keep original plan name
        description: stripePlan.description || plan.friendlyName,
        skipPayment: false
      };
    }
    
    return plan; // Fallback to original plan if no match found
  };

  const handleCardClick = (index) => {
    setSelectedPlan(index);
    setSendIndex(index);
    
    const plan = plans[index];
    console.log('Selected plan in ParallaxPlan:', plan);
    
    // Find matching plan (handles both free and paid plans)
    const matchedPlan = findMatchingPlan(plan);
    console.log('Setting plan:', matchedPlan);
    setSelectedPlanData(matchedPlan);
    
    setShowPopup(true);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const handleIndex = (index, isYearly) => {
    setSelectedPlan(index);
    setIsYearly(isYearly);
  };

  const formatPrice = (priceString) => {
    if (!priceString) {
      // Return empty formatting for plans without pricing
      return {
        dollar: '',
        whole: '',
        decimal: ''
      };
    }
    
    const price = priceString.substring(1);
    const [whole, decimal] = price.split('.');
    return {
      dollar: '$',
      whole: whole,
      decimal: decimal ? `.${decimal}` : ''
    };
  };

  return (
    <>
      {showPopup && (
        <SignUpPage
          onClose={handleClosePopup}
          selectedPlan={selectedPlanData}
          planIndex={sendIndex}
          isYearly={isYearly}
          fromHome={true}
          handleIndex={handleIndex}
        />
      )}
      <div className="parallaxPlan-container" ref={containerRef}>
        <div className="parallaxPlan-main">
          <div className="mains-container">
            <h2 className="heading pricing-title">
              We've got the right plan for you.
            </h2>
            <div className="toggle-buttons" onClick={stopPropagation}>
              <label className="title2">Monthly</label>
              <SMLToggleButton
                value={isYearly}
                checked={isYearly}
                onChange={() => {
                  setSelectedPlan(null);
                  setIsYearly(!isYearly);
                }}
              />
              <label className="title2">Yearly</label>
              <span className="annual-discount-text">Get 2 months free with annual plans</span>
            </div>
            <div className="plan-cards-container">
            {plans.slice(0,5).map((plan, index) => (
                <div
                  key={index}
                  className={`plan-card ${selectedPlan === index ? "selected-card" : ""}`}
                  data-layout={plan.layout}
                >
                  {plan.earlyAccess && (
                    <img
                      src={earlyAccessBadge}
                      alt="Limited Time Offer"
                      className="limited-time-image"
                    />
                  )}
                  {plan.bestValue && (
                    <img
                      src={bestValue}
                      alt="Best Value"
                      className="best-value-image"
                    />
                  )}
                  <div className="plan-card-content">
                    <h2 className="title1">{plan.name}</h2>
                    <div className="price-container">
                      {plan.oldPrice && (
                        <p className="plan-old-price">
                          {isYearly ? plan.oldYearlyPrice : plan.oldPrice}
                        </p>
                      )}
                      <p className="plan-price">
                        {(isYearly ? plan.yearlyPrice : plan.price) && (
                          <>
                            <span className="price-dollar">
                              {formatPrice(isYearly ? plan.yearlyPrice : plan.price).dollar}
                            </span>
                            <span className="price-whole">
                              {formatPrice(isYearly ? plan.yearlyPrice : plan.price).whole}
                            </span>
                            <span className="price-decimal">
                              {formatPrice(isYearly ? plan.yearlyPrice : plan.price).decimal}
                            </span>
                            <span className="plan-duration">
                              {isYearly ? "/year" : "/month"}
                            </span>
                          </>
                        )}
                      </p>
                    </div>
                    {plan.friendlyName && (
                      <div className="friendlyName">
                        {plan.friendlyName}
                      </div>
                    )}
                    <div className="horizontal-line"></div>
                    <ul className="features-list">
                    {plan.features.map((feature, i) => {
                      if (typeof feature === "object" && feature.type === "highlight") {
                        return (
                          <div key={i} className="highlight-block">
                            <PriceTags />
                            {feature.items.map((item, j) => {
                              if (typeof item === "string") {
                                return (
                                  <li key={j} className="features-item">
                                    <img src={checkBox} alt="checkbox" className="checkbox-icon" />
                                    <span className="feature-text">{item}</span>
                                  </li>
                                );
                              } else {
                                const [title, nestedFeatures] = Object.entries(item)[0];
                                return (
                                  <React.Fragment key={j}>
                                    <li className="features-item">
                                      <img src={checkBox} alt="checkbox" className="checkbox-icon" />
                                      <span className="feature-text">{title}</span>
                                    </li>
                                    {nestedFeatures.map((nestedFeature, k) => (
                                      <li key={k} className="features-item nested-feature-item">
                                        <span className="bullet-icon">•</span>
                                        <span className="feature-text">{nestedFeature}</span>
                                      </li>
                                    ))}
                                  </React.Fragment>
                                );
                              }
                            })}
                          </div>
                        );
                      }
                      
                      if (typeof feature === "string") {
                        return (
                          <li key={i} className="features-item">
                            <img src={checkBox} alt="checkbox" className="checkbox-icon" />
                            <span className="feature-text">{feature}</span>
                          </li>
                        );
                      }
                      
                      if (typeof feature === "object") {
                        const [title, nestedFeatures] = Object.entries(feature)[0];
                        return (
                          <React.Fragment key={i}>
                            <li className="features-item">
                              <img src={checkBox} alt="checkbox" className="checkbox-icon" />
                              <span className="feature-text">{title}</span>
                            </li>
                            {nestedFeatures.map((nestedFeature, j) => (
                              <li key={`${i}-${j}`} className="features-item nested-feature-item">
                                <span className="bullet-icon">•</span>
                                <span className="feature-text">{nestedFeature}</span>
                              </li>
                            ))}
                          </React.Fragment>
                        );
                      }
                      return null;
                    })}
                    </ul>
                  </div>
                  {plan.button && (
                    <div onClick={() => handleCardClick(index)}>
                      <button className={`plan-button plan-button-${index}`}>
                      {selectedPlan === index ? "Current Plan" : plan.button}
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParallaxPlan;
