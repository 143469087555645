import React, { useEffect } from 'react';
import SMLButtonWithIcon from '../../Shared/SMLButtonWithIcon/SMLButtonWithIcon';
import { APPCONFIG } from '../../../config/APPConfig';

const FacebookLoginButton = ({ onSuccess, onError }) => {
  useEffect(() => {
    // Load the Facebook SDK
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: APPCONFIG.FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v17.0'
      });
    };

    // Load SDK
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, []);

  const handleFacebookLogin = () => {
    window.FB.login(function(response) {
      if (response.status === 'connected') {
        // Get all user data including profile picture
        window.FB.api('/me', { fields: 'name,email,picture.type(large)' }, function(userInfo) {
          onSuccess({
            accessToken: response.authResponse.accessToken,
            userInfo  // Pass the entire userInfo object
          });
        });
      } else {
        onError('Login failed or was cancelled');
      }
    }, { scope: 'public_profile,email' });
  };

  return (
    <div style={{ width: '100%', maxWidth: '280px' }}>
      <SMLButtonWithIcon
        type="button"
        value="Sign in with Facebook"
        icon="Facebook"
        onClick={handleFacebookLogin}
        style={{ 
          width: '100%',
          height: '40px',
          borderRadius: '4px'
        }}
      />
    </div>
  );
};

export default FacebookLoginButton; 