import api from "../api";
import { unAuthorizedApi } from "../api";
const BASE_URL = process.env.REACT_APP_API_URL;

// Reuse the same content type helper
const getContentType = (filename) => {
  const extension = filename.split('.').pop().toLowerCase();
  const contentTypes = {
    'mp3': 'audio/mpeg',
    'wav': 'audio/wav',
    'aac': 'audio/aac',
    'm4a': 'audio/mp4',
    'ogg': 'audio/ogg',
    'flac': 'audio/flac'
  };
  return contentTypes[extension] || 'audio/mpeg';
};

export const getTrackUploadUrlApi = async ({ trackId, fileName, fileType }) => {
  try {
    const accessToken = localStorage.getItem("token");
    if (!accessToken) {
      throw new Error("No token found. Please log in.");
    }

    const contentType = getContentType(fileName);
    const url = `${BASE_URL}/tracks/${trackId}/signed-url`;
    
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };

    const data = {
      file_name: fileName,
      file_type: contentType
    };

    const res = await api.post(url, data, config);
    return res.data;
  } catch (error) {
    console.error("Error getting track upload URL:", error);
    throw error;
  }
};

export const uploadToS3 = async (uploadUrl, file) => {
  try {
    const contentType = getContentType(file.name);
    const config = {
      method: "put",
      maxBodyLength: Infinity,
      headers: {
        "Content-Type": contentType,
      },
    };

    await unAuthorizedApi.put(uploadUrl, file, config);
  } catch (error) {
    console.error("Error uploading to S3:", error);
    throw error;
  }
}; 