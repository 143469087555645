import React, { useRef } from "react";
import HomeLayout from "../../components/Layout/HomeLayout";
import ResourceSection from "../../components/Resources/ResourceSection";
import styles from "./ResourcesPage.module.css";

export default function ResourcesPage() {
  const resourcesRef = useRef(null);

  return (
    <HomeLayout>
      <div className={styles["resources-container"]}>
        <div className={styles["resources-content"]} ref={resourcesRef}>
          <ResourceSection />
        </div>
      </div>
    </HomeLayout>
  );
} 