import React, { useEffect, useState } from "react";
import "../../SignUpPage/SignUpPage.css";
import ProgressSteps from "../ProgressSteps/ProgressSteps";
import SMLLabel from "../../../components/Shared/SMLLabel/SMLLabel";
import SMLToggleButton from "../../../components/Shared/SMLToggleButton/SMLToggleButton";
import plans from "./ChoosePlan.json";
import bestValue from "../../../assets/Images/popular.svg";
import checkBox from "../../../assets/Images/checkboxIcon.svg";
import closeIcon from "../../../assets/Images/closeIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { getPlansRequest } from "../../../redux/actions";
import {
  RESET_GET_CLIENT,
  RESET_PB_KEY,
} from "../../../redux/actions/actionTypes";
import earlyAccessBadge from "../../../assets/Images/earlyAccess.svg";
import PriceTags from "../../../components/PriceTags/PriceTags";

const ChoosePlan = (props) => {
  const dispatch = useDispatch();
  const [selectedPlan, setSelectedPlan] = useState(props.planIndex);
  const [selectedPlan1Data, setSelectedPlan1Data] = useState(props.subscribed);
  const [selectedPlan1, setSelectedPlan1] = useState();
  const [isYearly, setIsYearly] = useState(props.isYearly);
  const [showFreePlan, setShowFreePlan] = useState(props.showFreePlan ?? true);
  const [showPlanWithPricingOnly] = useState(props.showPlanWithPricingOnly ?? false);

  // Filter plans based on pricing data
  const filteredPlans = plans.filter(plan => {
    if (showPlanWithPricingOnly) {
      // Only show plans that have pricing data (monthlyId or YearlyId)
      return plan.monthlyId || plan.YearlyId;
    }
    return true;
  });

  const mainPlans = showFreePlan
    ? filteredPlans.filter(plan => plan.monthlyId || plan.YearlyId || plan.name.toLowerCase() === 'free')
    : filteredPlans.filter(plan => plan.monthlyId || plan.YearlyId);

  // Only show sync partner and add-on services if not showing pricing-only plans
  const syncPartnerPlan = (!showPlanWithPricingOnly && showFreePlan) ? [plans.find(p => p.name === 'Sync Partner')] : [];
  const addOnPlan = !showPlanWithPricingOnly ? [plans.find(p => p.name === 'Add-on Services')] : [];

  const uploadStats = useSelector(
    (state) => state.uploadStats?.statsFetched?.stats
  );

  const findPlanByTier = (tier) => {
    return plans.find((plan) =>
      plan.name
        .toLowerCase()
        .replace(/\s+/g, "_")
        .includes(tier.toLowerCase().replace(/\s+/g, "_"))
    );
  };

  const findPlanByMembership = (membershipName) => {
    return plans.find((plan) =>
      plan.name
        .toLowerCase()
        .replace(/\s+/g, "_")
        .includes(membershipName.toLowerCase().replace(/_+/g, "_"))
    );
  };

  useEffect(() => {
    dispatch(getPlansRequest());
  }, [dispatch]);

  useEffect(() => {
    if (selectedPlan1Data) {
      const matchedPlan = findPlanByTier(selectedPlan1Data);
      if (matchedPlan && !selectedPlan1) {
        const matchedIndex = plans.indexOf(matchedPlan);
        setSelectedPlan1(matchedIndex);
      }
    }
  }, [selectedPlan1Data, selectedPlan1]);

  useEffect(() => {
    if (props.subscribed) {
      const matchedPlan = findPlanByTier(props.subscribed);
      if (matchedPlan && !selectedPlan) {
        const matchedIndex = plans.indexOf(matchedPlan);
        setSelectedPlan(matchedIndex);
      }
    }
  }, [props.subscribed, props.planWhileCreating, selectedPlan]);

  useEffect(() => {
    setShowFreePlan(props.showFreePlan ?? true);
  }, [props.showFreePlan]);

  const plans1 = useSelector((state) => state.payment.getPlansReducer?.plans);

  const findMatchingPlan = (plan) => {
    if (!plan.YearlyId && !plan.monthlyId) {
      return null;
    }
    const priceId = isYearly ? plan.YearlyId : plan.monthlyId;
    return plans1?.find((p1) => p1.id === priceId);
  };

  const getAdjustedPlanIndex = (index, isAdditional = false) => {
    if (!showFreePlan) {
      // Add 1 to compensate for hidden free plan
      return index + 1;
    }
    return index;
  };

  const handleCardClick = (index, isAdditional = false) => {
    let actualIndex;
    let plan;

    if (!isAdditional) {
      actualIndex = getAdjustedPlanIndex(index);
      plan = mainPlans[index];
    } else if (index === 0) {
      actualIndex = mainPlans.length + (showFreePlan ? 0 : 1);
      plan = syncPartnerPlan[0];
    } else {
      actualIndex = mainPlans.length + (showFreePlan ? 1 : 2);
      plan = addOnPlan[0];
    }

    if (
      props.subscribed &&
      ((isYearly === false && uploadStats?.interval === "MONTHLY") ||
        (isYearly === true && uploadStats?.interval === "YEARLY")) &&
      selectedPlan1 === actualIndex
    ) {
      return;
    }

    if (plan.name === "Add-on Services") {
      props.onNextClick1(plan, actualIndex, isYearly);
      return;
    }

    setSelectedPlan(actualIndex);

    // Check if it's a free plan first
    if (plan.name.toLowerCase() === "free") {
      props.onNextClick1(plan, actualIndex, isYearly);
      return;
    }

    const selectedPlanData = findMatchingPlan(plan);
    if (selectedPlanData) {
      props.onNextClick1(selectedPlanData, actualIndex, isYearly);
    } else {
      console.error("Selected plan not found in plans1");
    }
  };

  const formatPrice = (priceString) => {
    if (!priceString) return { dollar: "", whole: "", decimal: "" };
    const price = priceString.substring(1);
    const [whole, decimal] = price.split(".");
    return {
      dollar: "$",
      whole: whole,
      decimal: decimal ? `.${decimal}` : "",
    };
  };

  useEffect(() => {
    if (
      selectedPlan === null &&
      ((isYearly === false && uploadStats?.interval === "MONTHLY") ||
        (isYearly === true && uploadStats?.interval === "YEARLY"))
    ) {
      setSelectedPlan(selectedPlan1);
    }
  }, [isYearly]);

  // Function to determine card layout class
  const getCardLayoutClass = (plan) => {
    if (!showFreePlan) {
      return "column"; // Force column layout when free plan is hidden
    }
    return plan.layout || "column"; // Use default layout otherwise
  };

  return (
    <div style={{ height: '100%' }}>
      <div className="signUp-progress-steps">
        {props.upgradePlan ? (
          <ProgressSteps step="0" upgrade="true" />
        ) : (
          <ProgressSteps step="0" />
        )}
      </div>
      <div className="signUp-close-div" onClick={props.closePopup}>
        <img src={closeIcon} style={{ cursor: "pointer" }} alt="Close" />
      </div>

      <div className="plan-form">
        <div className="signUp-label-div-plan">
          <SMLLabel
            value={`${
              props.upgradePlan === "upgrade" ? "Upgrade Plan" : "Choose Plan"
            }`}
            style="Mainheader"
          />
        </div>
        <div
          className="toggle-buttons"
          style={{ top: "0px", position: "relative", bottom: "0px" }}
        >
          <label className="title2">Monthly</label>
          <SMLToggleButton
            value={isYearly}
            checked={isYearly}
            onChange={() => {
              setSelectedPlan(null);
              setIsYearly(!isYearly);
            }}
          />
          <label className="title2">Yearly</label>
          <span className="annual-discount-text">Get 2 months free with annual plans</span>
        </div>

        {/* Main Plans Row */}
        <div className="plan-cards">
          {mainPlans.map((plan, index) => (
            <div
              key={index}
              className={`plan-card ${
                selectedPlan === getAdjustedPlanIndex(index)
                  ? "selected-card"
                  : ""
              }`}
              onClick={() => handleCardClick(index)}
              data-layout={getCardLayoutClass(plan)}
              style={{ cursor: "pointer" }}
            >
              {plan.bestValue && (
                <img
                  src={bestValue}
                  alt="Popular"
                  className="best-value-image"
                />
              )}
              {plan.earlyAccess && (
                <img
                  src={earlyAccessBadge}
                  alt="Limited Time Offer"
                  className="limited-time-image"
                />
              )}
              <div className="plan-card-content">
                <h2 className="title1">{plan.name}</h2>
                <div className="price-container">
                  {plan.oldPrice && (
                    <p className="plan-old-price">
                      {isYearly ? plan.oldYearlyPrice : plan.oldPrice}
                    </p>
                  )}
                  <p className="plan-price">
                    <span className="price-dollar">
                      {formatPrice(isYearly ? plan.yearlyPrice : plan.price).dollar}
                    </span>
                    <span className="price-whole">
                      {formatPrice(isYearly ? plan.yearlyPrice : plan.price).whole}
                    </span>
                    <span className="price-decimal">
                      {formatPrice(isYearly ? plan.yearlyPrice : plan.price).decimal}
                    </span>
                    <span className="plan-duration">
                      {isYearly ? "/year" : "/month"}
                    </span>
                  </p>
                </div>
                <div className="friendlyName">
                  {plan.friendlyName || "\u00A0"}
                </div>
                <div className="horizontal-line"></div>
                <ul className="features-list">
                  {plan.features.map((feature, i) => {
                    if (typeof feature === "object" && feature.type === "highlight") {
                      return (
                        <div key={i} className="highlight-block">
                          <PriceTags />
                          {feature.items.map((item, j) => {
                            if (typeof item === "string") {
                              return (
                                <li key={j} className="features-item">
                                  <img src={checkBox} alt="checkbox" className="checkbox-icon" />
                                  <span className="feature-text">{item}</span>
                                </li>
                              );
                            } else {
                              const [title, nestedFeatures] = Object.entries(item)[0];
                              return (
                                <React.Fragment key={j}>
                                  <li className="features-item">
                                    <img src={checkBox} alt="checkbox" className="checkbox-icon" />
                                    <span className="feature-text">{title}</span>
                                  </li>
                                  {nestedFeatures.map((nestedFeature, k) => (
                                    <li key={k} className="features-item nested-feature-item">
                                      <span className="bullet-icon">•</span>
                                      <span className="feature-text">{nestedFeature}</span>
                                    </li>
                                  ))}
                                </React.Fragment>
                              );
                            }
                          })}
                        </div>
                      );
                    }
                    
                    // Handle regular string features
                    if (typeof feature === "string") {
                      return (
                        <li key={i} className="features-item">
                          <img src={checkBox} alt="checkbox" className="checkbox-icon" />
                          <span className="feature-text">{feature}</span>
                        </li>
                      );
                    }
                    
                    // Handle nested features
                    if (typeof feature === "object") {
                      const [title, nestedFeatures] = Object.entries(feature)[0];
                      return (
                        <React.Fragment key={i}>
                          <li className="features-item">
                            <img src={checkBox} alt="checkbox" className="checkbox-icon" />
                            <span className="feature-text">{title}</span>
                          </li>
                          {Array.isArray(nestedFeatures) && nestedFeatures.map((nestedFeature, j) => (
                            <li key={`${i}-${j}`} className="features-item nested-feature-item">
                              <span className="bullet-icon">•</span>
                              <span className="feature-text">{nestedFeature}</span>
                            </li>
                          ))}
                        </React.Fragment>
                      );
                    }
                    return null;
                  })}
                </ul>
              </div>

              <button
                className={`plan-button plan-button-${index} ${
                  selectedPlan === getAdjustedPlanIndex(index)
                    ? "selected-button"
                    : ""
                } ${
                  uploadStats?.plan?.toLowerCase() === plan.name.toLowerCase()
                    ? "current-plan"
                    : ""
                }`}
              >
                {uploadStats?.plan?.toLowerCase() === plan.name.toLowerCase()
                  ? "Current Plan"
                  : selectedPlan === getAdjustedPlanIndex(index)
                  ? "Selected Plan"
                  : props.upgradePlan
                  ? "Upgrade Plan"
                  : plan.button}
              </button>
            </div>
          ))}
        </div>

        {/* Sync Partner Row */}
        <div className="" style={{ marginTop: "2rem" }}>
          {showFreePlan &&
            syncPartnerPlan.map((plan, index) => (
              <div
                key={index}
                className={`plan-card ${
                  selectedPlan ===
                  getAdjustedPlanIndex(index + mainPlans.length)
                    ? "selected-card"
                    : ""
                }`}
                onClick={() => handleCardClick(index, true)}
                data-layout={getCardLayoutClass(plan)}
                style={{ cursor: "pointer" }}
              >
                {plan.earlyAccess && (
                  <img
                    src={earlyAccessBadge}
                    alt="Limited Time Offer"
                    className="limited-time-image"
                  />
                )}
                <div className="plan-card-content">
                  <h2 className="title1">{plan.name}</h2>
                  <div className="price-container">
                    {plan.oldPrice && (
                      <p className="plan-old-price">
                        {isYearly ? plan.oldYearlyPrice : plan.oldPrice}
                      </p>
                    )}
                    <p className="plan-price">
                      <span className="price-dollar">
                        {formatPrice(isYearly ? plan.yearlyPrice : plan.price).dollar}
                      </span>
                      <span className="price-whole">
                        {formatPrice(isYearly ? plan.yearlyPrice : plan.price).whole}
                      </span>
                      <span className="price-decimal">
                        {formatPrice(isYearly ? plan.yearlyPrice : plan.price).decimal}
                      </span>
                      <span className="plan-duration">
                        {isYearly ? "/year" : "/month"}
                      </span>
                    </p>
                  </div>
                  <div className="friendlyName">
                    {plan.friendlyName || "\u00A0"}
                  </div>
                  <div className="horizontal-line"></div>
                  <ul className="features-list">
                    {plan.features.map((feature, i) => {
                      if (typeof feature === "object" && feature.type === "highlight") {
                        return (
                          <div key={i} className="highlight-block">
                            <PriceTags />
                            {feature.items.map((item, j) => {
                              if (typeof item === "string") {
                                return (
                                  <li key={j} className="features-item">
                                    <img src={checkBox} alt="checkbox" className="checkbox-icon" />
                                    <span className="feature-text">{item}</span>
                                  </li>
                                );
                              } else {
                                const [title, nestedFeatures] = Object.entries(item)[0];
                                return (
                                  <React.Fragment key={j}>
                                    <li className="features-item">
                                      <img src={checkBox} alt="checkbox" className="checkbox-icon" />
                                      <span className="feature-text">{title}</span>
                                    </li>
                                    {nestedFeatures.map((nestedFeature, k) => (
                                      <li key={k} className="features-item nested-feature-item">
                                        <span className="bullet-icon">•</span>
                                        <span className="feature-text">{nestedFeature}</span>
                                      </li>
                                    ))}
                                  </React.Fragment>
                                );
                              }
                            })}
                          </div>
                        );
                      }
                      
                      // Handle regular string features
                      if (typeof feature === "string") {
                        return (
                          <li key={i} className="features-item">
                            <img src={checkBox} alt="checkbox" className="checkbox-icon" />
                            <span className="feature-text">{feature}</span>
                          </li>
                        );
                      }
                      
                      // Handle nested features
                      if (typeof feature === "object") {
                        const [title, nestedFeatures] = Object.entries(feature)[0];
                        return (
                          <React.Fragment key={i}>
                            <li className="features-item">
                              <img src={checkBox} alt="checkbox" className="checkbox-icon" />
                              <span className="feature-text">{title}</span>
                            </li>
                            {Array.isArray(nestedFeatures) && nestedFeatures.map((nestedFeature, j) => (
                              <li key={`${i}-${j}`} className="features-item nested-feature-item">
                                <span className="bullet-icon">•</span>
                                <span className="feature-text">{nestedFeature}</span>
                              </li>
                            ))}
                          </React.Fragment>
                        );
                      }
                      return null;
                    })}
                  </ul>
                </div>

                <button
                  className={`plan-button plan-button-${
                    index + mainPlans.length
                  } ${
                    selectedPlan ===
                    getAdjustedPlanIndex(index + mainPlans.length)
                      ? "selected-button"
                      : ""
                  } ${
                    uploadStats?.plan?.toLowerCase() === plan.name.toLowerCase()
                      ? "current-plan"
                      : ""
                  }`}
                >
                  {uploadStats?.plan?.toLowerCase() === plan.name.toLowerCase()
                    ? "Current Plan"
                    : selectedPlan ===
                      getAdjustedPlanIndex(index + mainPlans.length)
                    ? "Selected Plan"
                    : props.upgradePlan
                    ? "Upgrade Plan"
                    : plan.button}
                </button>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ChoosePlan;
