import React, { useState, useRef, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import "../../../styles/Fonts.scss";
import "../../../styles/HTMLControls.scss";
import "./UploadTrack.css";
import SMLLabel from "../SMLLabel/SMLLabel";
import { useNavigate } from "react-router-dom";
import SMLTextBox from "../SMLTextBox/SMLTextBox";
import SMLButton from "../SMLButton/SMLButton";
import trackIcon from "../../../assets/Images/trackIcon.svg";
import trash from "../../../assets/Images/trash.svg";
import UploadIcon from "../../../assets/Images/UploadIcon.svg";
import closeIcon from "../../../assets/Images/closeIcon.svg";
import FeedbackForum from "../../../pages/Feedback/FeedbackForum";
import { getReuploadSignedUrl, uploadToS3 } from "../../../api/track/postTrackApi";

const UploadTrack = ({ forReupload, trackId, parentTrack, onPopupClose, onDrop }) => {
  const [trackName, setTrackName] = useState("");
  const [genreName, setGenreName] = useState("");
  const [audio, setAudio] = useState(null);
  const [fileName, setFileName] = useState("No selected file");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [errors, setErrors] = useState({});
  const [fileError, setFileError] = useState("");

  const inputRef = useRef(null);
  const popupRef = useRef(null);

  // Function to handle outside click
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
        onPopupClose();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [onPopupClose]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "audio/mpeg": [".mp3"],
      "audio/wav": [".wav"],
      "audio/aiff": [".aiff", ".aif"],
    },
    onDrop: async (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        // Check if file is empty
        if (file.size === 0) {
          setFileError("File is empty. Please select a valid audio file.");
          return;
        }

        // Validate file content type
        try {
          const audioContext = new (window.AudioContext || window.webkitAudioContext)();
          const arrayBuffer = await file.arrayBuffer();
          const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
          
          if (!audioBuffer || audioBuffer.length === 0) {
            setFileError("Invalid audio file. Please select a valid audio file.");
            return;
          }

          const nameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
          setFileName(file.name);
          setTrackName(nameWithoutExtension);
          
          // If onDrop callback is provided, just pass the file back without changing UI state
          if (onDrop) {
            onDrop(file);
            return;
          }
          
          handleUpload(file);
        } catch (error) {
          console.error("Audio validation error:", error);
          setFileError("Invalid audio file format. Please select a valid audio file.");
        }
      }
    },
    onDragOver: (e) => e.preventDefault(),
    onDropRejected: () =>
      setFileError(
        "Invalid file type. Please upload an MP3, WAV, or AIFF file."
      ),
  });

  const handleUpload = async (file) => {
    setAudio(file);
    setFileName(file.name);
    const nameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
    setTrackName(nameWithoutExtension);

    if (forReupload && trackId) {
      try {
        setUploadProgress(0);
        setIsUploadComplete(false);

        // Get signed URL for re-upload
        const { upload_url, file_type } = await getReuploadSignedUrl(trackId, file);
        
        // Simulate progress while uploading
        let progress = 0;
        const interval = setInterval(() => {
          progress += 10;
          if (progress <= 90) {
            setUploadProgress(progress);
          }
        }, 200);

        // Upload to S3
        await uploadToS3(upload_url, file, file_type);
        
        clearInterval(interval);
        setUploadProgress(100);
        setIsUploadComplete(true);
        
        // Close popup and refresh parent after a short delay
        setTimeout(() => {
          onPopupClose();
        }, 1000);
      } catch (error) {
        console.error("Re-upload failed:", error);
        setFileError("Failed to upload file. Please try again.");
        setUploadProgress(0);
      }
    } else {
      // Original upload logic for new tracks
      setUploadProgress(0);
      setIsUploadComplete(false);
    
      let simulatedProgress = 0;
      const interval = setInterval(() => {
        simulatedProgress += 33;
        if (simulatedProgress > 100) {
          clearInterval(interval);
          setUploadProgress(100);
          setIsUploadComplete(true);
        } else {
          setUploadProgress(simulatedProgress);
        }
      }, 200);
    }
  };
  
  function containsXSS(input) {
    const xssPattern = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
    return xssPattern.test(input);
  }
  const validate = () => {
    const newErrors = {};
    if (!trackName) newErrors.trackName = "Track name is required";
    if (containsXSS(trackName))
      newErrors.trackName = "This format is not allowed.";
    if (!genreName) newErrors.genreName = "Genre name is required";
    if (!audio) newErrors.audio = "Audio file is required";
    return newErrors;
  };

  const handleSaveClick = () => {
    // Only show feedback popup for new uploads, not re-uploads
    if (!forReupload) {
      const validationErrors = validate();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }
      setShowPopup(true);
    }
  };

  const handleClosePopup = () => {
    setShowFeedbackPopup(false);
    setShowPopup(false);
    onPopupClose();
    window.location.reload();
  };
  const handleBackClick = () => {
    setShowPopup(false);
    setIsUploadComplete(false);
  };

  return (
    <div className="container-fluid upload-container">
      <div
        className="upload-card card"
        style={{ height: "fit-content", paddingBottom: "5rem" }}
        ref={popupRef}
      >
        <div className="">
          <div className="d-flex justify-content-end upload-close-div">
            <img
              src={closeIcon}
              onClick={onPopupClose}
              style={{ cursor: "pointer" }}
              alt="Close"
            />
          </div>
        </div>
        <div className="upload-main">
          <div className=" upload-label-div text-center">
            <SMLLabel value={forReupload ? "Re-upload Track" : "Upload New Track"} style="Mainheader" />
          </div>
          <div className="upload-content-container mt-3">
            {isUploadComplete ? (
              <div className="upload-box-complete">
                <div className="upload-grid">
                  <div className=" upload-grid-left-content">
                    <img
                      src={UploadIcon}
                      className="uploadImg-inner-frame"
                      alt="Upload Icon"
                    />
                  </div>
                  <div className=" upload-grid-right-content">
                    <div className="upload-controls">
                      {!forReupload && (
                        <>
                          <div className="upload-fields">
                            <SMLLabel value="Track Name" style="title2" />
                            <SMLTextBox
                              value={trackName}
                              onChange={(e) => setTrackName(e.target.value)}
                            />
                            {errors.trackName && (
                              <p className="error-text">{errors.trackName}</p>
                            )}
                          </div>
                          <div className="upload-fields">
                            <SMLLabel value="Genre" style="title2" />
                            <div className="SMLDropdownWrapper">
                              <select
                                value={genreName}
                                style={{ cursor: "pointer" }}
                                onChange={(e) => setGenreName(e.target.value)}
                                className="form-control SMLDropdown"
                              >
                                <option value="" disabled>
                                  Select Genre
                                </option>
                                <option value="rock">Rock</option>
                                <option value="pop">Pop</option>
                                <option value="hiphop_rap">Hip Hop/Rap</option>
                                <option value="electronic">Electronic</option>
                                <option value="jazz">Jazz</option>
                                <option value="blues">Blues</option>
                                <option value="country">Country</option>
                                <option value="rb_soul">R&B/Soul</option>
                                <option value="metal">Metal</option>
                                <option value="neo_classical">Neo-Classical</option>
                                <option value="world_music">World Music</option>
                                <option value="folk">Folk</option>
                                <option value="experimental">Experimental</option>
                                <option value="trailer_music">Trailer Music</option>
                                <option value="other">Other</option>
                              </select>
                            </div>

                            {errors.genreName && (
                              <p
                                className="error-text"
                                style={{ marginTop: "45px" }}
                              >
                                {errors.genreName}
                              </p>
                            )}
                          </div>
                        </>
                      )}
                      <div className="upload-btn-Bottom-right d-flex">
                        {!forReupload && (
                          <SMLButton
                            style="SMLSendFeedback"
                            value="Continue"
                            handleClickEvent={handleSaveClick}
                          />
                        )}
                        {showPopup && !forReupload && (
                          <div className="popup">
                            <FeedbackForum
                              forResubmit={forReupload}
                              parentTrack={parentTrack}
                              audio={audio}
                              trackName={trackName}
                              genreName={genreName}
                              onClose={handleClosePopup}
                              onBack={handleBackClick}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                {...getRootProps({ className: "upload-box" })}
                className="upload-box"
              >
                <input
                  {...getInputProps()}
                  type="file"
                  className="input-field"
                  hidden
                />
                <>
                  <img src={UploadIcon} alt="Upload Icon" width={58} />
                  <p className="title2">Drop your track here</p>
                  <p>Upload from device</p>
                  <p className="sub-subtitle2">Max file size - 0.0MB</p>
                  {errors.audio && <p className="error-text">{errors.audio}</p>}
                  {fileError && <p className="error-text">{fileError}</p>}
                </>
              </div>
            )}
          </div>
          {!isUploadComplete && (
            <div className="progressbar-div">
              <div className="progressbar-content">
                <div className="progressbar-content">
                  <img
                    src={trackIcon}
                    alt={fileName}
                    onClick={() => {
                      setFileName("No selected File");
                      setAudio(null);
                    }}
                  />
                  <SMLLabel value={fileName} />
                </div>
                <img
                  src={trash}
                  alt="Trash Icon"
                  style={{ float: "right" }}
                  onClick={() => {
                    setFileName("No selected File");
                    setAudio(null);
                    setUploadProgress(0);
                    setTrackName(null);
                    setGenreName("");
                  }}
                />
              </div>
              <progress
                value={uploadProgress}
                max="100"
                className="progressbar-width"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadTrack;
