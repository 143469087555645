import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  loginRequest,
  socialLoginRequest,
} from "../../redux/actions/authActions";
import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import "../../styles/Fonts.scss";
import "./LoginPage.css";
import closeIcon from "../../../src/assets/Images/closeIcon.svg";
import SMLTextBox from "../../components/Shared/SMLTextBox/SMLTextBox";
import SMLButton from "../../components/Shared/SMLButton/SMLButton";
import SMLButtonWithIcon from "../../components/Shared/SMLButtonWithIcon/SMLButtonWithIcon";
import SMLLinkButton from "../../components/Shared/SMLLinkButton/SMLLinkButton";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import SignUpPage from "../SignUpPage/SignUpPage";
import { BiQuestionMark } from "react-icons/bi";
import { Bars } from "react-loader-spinner";
import { Modal } from "react-bootstrap";
import { GoogleLogin } from "@react-oauth/google";
import ForgetPassword from "../../components/common/forget-password/ForgetPassword";
import {
  RESET_DELETE_PROFILE,
  RESET_FORGET_PASSWORD,
  RESET_CONFIRM_MAIL,
} from "../../redux/actions/actionTypes";
import { websocketConnect } from "../../redux/actions/websocketActions";
import { APPCONFIG } from "../../config/APPConfig";
import FacebookLoginButton from "../../components/common/FacebookLoginButton/FacebookLoginButton";
import { confirmMailRequest } from "../../redux/actions";
import { CheckBoxRounded } from "@mui/icons-material";
import { Check, X } from "lucide-react";
import GoogleLoginButton from '../../components/common/GoogleLoginButton/GoogleLoginButton';

const Login = ({ title, onClose, confirmMail }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [signUpShow, setSignUpShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [showForgetPass, setShowForgetPass] = useState(false);
  const [fbError, setFbError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const profile_type = "artist";
  const { user, loading, error } = useSelector((state) => state.auth);

  const {
    isLoading: confirmMailLoading,
    isError: isConfirmMailError,
    error: confirmMailError,
    confirmed: confirmedMail,
  } = useSelector((state) => state.confirmMail.confirmMail);

  // useEffect(() => {}, [
  const query = new URLSearchParams(useLocation().search);
  const username = query.get("email");
  const code = query.get("code");

  const decodedUsername = decodeURIComponent(username);
  // ])

  useEffect(() => {
    if (decodedUsername && code) {
      dispatch(confirmMailRequest({ mail: decodedUsername, code }));
    }
  }, [dispatch, decodedUsername, code]);

  useEffect(() => {
    dispatch({ type: RESET_DELETE_PROFILE });
  }, []);

  useEffect(() => {
    if (user && user.profile_type === "artist") {
      console.log("user data available", user);
      // Store all user data in localStorage
      localStorage.setItem("username", user.profile?.name || "");
      localStorage.setItem("emails", user.profile?.email || "");
      localStorage.setItem("profile_type", user.profile_type);
      localStorage.setItem("user_id", user.profile?._id || "");
      localStorage.setItem("auth_provider", user.profile?.auth_provider || "");
      localStorage.setItem("user_email", user.profile?.email || "");
      
      // Subscription data
      if (user.profile?.subscription) {
        localStorage.setItem("subscription_interval", user.profile.subscription.interval || "");
        localStorage.setItem("subscription_status", user.profile.subscription.status || "");
        localStorage.setItem("subscription_id", user.profile.subscription.subscription_id || "");
        localStorage.setItem("subscription_tier", user.profile.subscription.tier || "");
      }
      
      // Create userData object for the dataLayer
      const userData = {
        username: user.profile?.name || "",
        email: user.profile?.email || "",
        profile_id: user.profile?._id || "",
        profile_type: user.profile_type,
        profile_links: user.profile?.personal_links || "",
        auth_provider: user.profile?.auth_provider || "",
        subscription_tier: user.profile?.subscription?.tier || "",
        subscription_status: user.profile?.subscription?.status || "",
        subscription_interval: user.profile?.subscription?.interval || "",
        subscription_id: user.profile?.subscription?.subscription_id || "",
        subscription_monthly_uploads: user.profile?.subscription?.monthly_upload_count || ""
      };

      console.log("userData for dataLayer", userData);
      
      // Push event and userData to dataLayer
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'userLoggedIn',
        ...userData
      });
      
      // Navigate to dashboard after successful login
      navigate("/dashboard", { state: { username: user.name } });
      dispatch(websocketConnect());
    }
  }, [user, navigate]);

  useEffect(() => {
    if (confirmedMail) {
      // After successful confirmation, update URL with returnUrl
      const currentPath = location.pathname;
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('returnUrl', '/dashboard');
      
      navigate(`${currentPath}?${searchParams.toString()}`, {
        state: { emailConfirmed: true }
      });
    }
  }, [confirmedMail, navigate, location]);

  const handleLoginContainerClick = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (error) setLoginError(true);
  }, [error]);

  useEffect(() => {
    setLoginError(false);
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleLogin = (e) => {
    e.preventDefault();

    if (!email.trim()) {
      setEmailError("Email is required");
      return;
    } else if (!validateEmail(email)) {
      setEmailError("Invalid email format");
      return;
    } else {
      setEmailError("");
    }

    if (!password.trim()) {
      setPasswordError("Password is required");
      return;
    } else {
      setPasswordError("");
    }

    // Get returnUrl from query params
    const searchParams = new URLSearchParams(location.search);
    const returnUrl = searchParams.get('returnUrl');

    // Pass returnUrl to login action
    dispatch(loginRequest(email, password, profile_type, returnUrl));
  };

  const handleSignUpClick = (e) => {
    setSignUpShow(true);
  };
  const handleForgetPassClick = (e) => {
    e.preventDefault();
    dispatch({ type: RESET_FORGET_PASSWORD });
    setShowForgetPass(true);
  };

  const responseFacebook = (response) => {
    const profile = {
      name: response.name,
      email: response.email,
    };
    localStorage.setItem("username", profile.name);
    localStorage.setItem("emails", profile.email);
    localStorage.setItem("isFacebookLoggedIn", "true");
    navigate("/dashboard");
  };

  const handleGoogleSuccess = (credentialResponse) => {
    dispatch(
      socialLoginRequest(credentialResponse.credential, "google", "artist")
    );
  };

  const handleGoogleError = (error) => {
    console.error("Google login error:", error);
  };

  const handleFacebookSuccess = ({ accessToken, userInfo }) => {
    dispatch(socialLoginRequest(accessToken, "facebook", "artist"));

    localStorage.setItem("username", userInfo.name);
    localStorage.setItem("emails", userInfo.email);
    localStorage.setItem("isFacebookLoggedIn", "true");
  };

  const handleFacebookError = (error) => {
    console.error("Facebook login error:", error);
    setFbError(error);
  };

  // Reset states when modal opens
  useEffect(() => {
    // Reset all states when modal opens
    setLoginError(false);
    setEmailError("");
    setPasswordError("");
    setEmail("");
    setPassword("");
    
    // Reset redux states
    dispatch({ type: RESET_DELETE_PROFILE });
    dispatch({ type: RESET_FORGET_PASSWORD });
    dispatch({ type: RESET_CONFIRM_MAIL });
  }, []);

  // Update the close handler
  const handleClose = () => {
    // Reset all states
    setLoginError(false);
    setEmailError("");
    setPasswordError("");
    setEmail("");
    setPassword("");
    
    // Reset redux states
    dispatch({ type: RESET_DELETE_PROFILE });
    dispatch({ type: RESET_FORGET_PASSWORD });
    dispatch({ type: RESET_CONFIRM_MAIL });

    // Call the original onClose
    onClose();
  };

  return showForgetPass ? (
    <ForgetPassword onClose={onClose} />
  ) : signUpShow ? (
    <SignUpPage onClose={onClose} />
  ) : (
    <Modal show={true} onHide={handleClose} centered className="custom-login-modal">
      <div className="login-containers" onClick={handleLoginContainerClick}>
        <form
          className="login-card"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleLogin(e);
            }
          }}
        >
          {(confirmMailLoading || loading) && (
            <div className="spinner-overlay">
              <Bars
                height="80"
                width="80"
                color="#23f0c7"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          )}

          <div className="Login-close-div" onClick={onClose}>
            <img src={closeIcon} style={{ cursor: "pointer" }} alt="Close" />
          </div>

          <div className="login-main">
            <div className="login-label-div">
              <SMLLabel value={"Log In"} style="Mainheader" />
            </div>
            {confirmedMail && (
              <div className="login-confirm-label-div">
                <span
                  style={{
                    backgroundColor: "#03c849",
                    padding: "0.1rem",
                    color: "white",
                    borderRadius: "10px",
                    marginRight: "2px",
                  }}
                >
                  <Check />
                </span>
                Email confirmed successfully! Please login to continue
              </div>
            )}

            {isConfirmMailError &&
              (confirmMailError === "User is already confirmed." ? (
                <div className="login-confirm-label-div">
                  <span
                    style={{
                      backgroundColor: "#03c849",
                      padding: "0.1rem",
                      color: "white",
                      borderRadius: "10px",
                      marginRight: "1rem",
                    }}
                  >
                    <Check style={{ fontSize: "2px" }} />
                  </span>
                  {confirmMailError} Please login to continue
                </div>
              ) : (
                <div className="login-not-confirm-label-div">
                  <span
                    style={{
                      backgroundColor: "#c80303",
                      padding: "0.1rem",
                      color: "white",
                      borderRadius: "10px",
                      marginRight: "1rem",
                    }}
                  >
                    <X />
                  </span>
                  {confirmMailError}
                </div>
              ))}
            <div className="Login-content" onSubmit={handleLogin}>
              <div className="login-controls">
                <div className="login-fields">
                  {loginError && <span className="error">{error}</span>}
                  <SMLLabel value="Email" style="title2"></SMLLabel>
                  <SMLTextBox
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    tabIndex={1}
                  />
                  {emailError && (
                    <span className="button1 error">{emailError}</span>
                  )}
                </div>
                <div className="login-fields">
                  <div className="login-groupby">
                    <label className="title2">Password</label>
                  </div>
                  <div className="passwordfield">
                    <SMLTextBox
                      value={password}
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => setPassword(e.target.value)}
                      tabIndex={2}
                    />
                    <span
                      className="toggle-password-visibility-login"
                      onClick={() => setShowPassword(!showPassword)}
                      tabIndex={4}
                    >
                      {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                    </span>
                  </div>
                  <div className="forgot-password-container">
                    <SMLLinkButton
                      handleClickEvent={handleForgetPassClick}
                      value={
                        <span className="forgotPassword sub-subtitle3">
                          Forgot Password
                          <BiQuestionMark className="questionMark" />
                        </span>
                      }
                      tabIndex={3}
                    />
                  </div>
                  {passwordError && (
                    <span className="button1 error">{passwordError}</span>
                  )}
                </div>
              </div>
              <div className="login-controls1">
                <div className="login-fields1">
                  <SMLButton
                    style="SMLButton1"
                    value="Log In"
                    handleClickEvent={handleLogin}
                  />
                  <div className="login-groupby">
                    <div className="div-line"></div>
                    <p className="or">or</p>
                    <div className="div-line"></div>
                  </div>
                </div>
                <div className="login-fields1">
                  <div className="login-groupbyBtns google">
                    <GoogleLoginButton 
                      onSuccess={handleGoogleSuccess}
                      onError={handleGoogleError}
                    />
                    <FacebookLoginButton
                      onSuccess={handleFacebookSuccess}
                      onError={handleFacebookError}
                    />
                  </div>
                  {fbError && <div className="error-message">{fbError}</div>}
                  <div className="login-card-end">
                    <div className="login-groupbyBtns">
                      <span className="not-member-text">
                        Not a member?
                      </span>
                      <button
                        className="signup-link"
                        type="button"
                        onClick={handleSignUpClick}
                      >
                        Sign up
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default Login;
