import {
  GET_RESOURCES_REQUEST,
  GET_RESOURCES_SUCCESS,
  GET_RESOURCES_FAILURE,
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isError: false,
  data: [],
  error: null,
};

const resourceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RESOURCES_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: null,
      };

    case GET_RESOURCES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
        error: null,
      };

    case GET_RESOURCES_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: [],
        error: action.payload,
      };

    default:
      return state;
  }
};

export default resourceReducer; 