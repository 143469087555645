import { call, put, takeLatest, all } from "redux-saga/effects";
import { 
  GET_RESOURCES_REQUEST,
  GET_RESOURCES_SUCCESS,
  GET_RESOURCES_FAILURE
} from '../actions/actionTypes';
import { getResourcesApi } from '../../api/resources/getResourcesApi';

function* getResourcesSaga() {
  try {
    const resources = yield call(getResourcesApi);
    yield put({ type: GET_RESOURCES_SUCCESS, payload: resources });
  } catch (error) {
    yield put({ type: GET_RESOURCES_FAILURE, payload: error.message });
  }
}

export default function* resourceSaga() {
  yield all([takeLatest(GET_RESOURCES_REQUEST, getResourcesSaga)]);
} 