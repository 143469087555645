import React from 'react';
import limitedTime from '../../assets/Images/limitedTimeTag.svg';

const PriceTags = () => {
  return (
    <div className="highlight-tags">
      <div className="price-tags">
        <img 
          src={limitedTime} 
          className="price-tag" 
          alt="Limited Time! $149.99 Value" 
        />
      </div>
    </div>
  );
};

export default PriceTags;