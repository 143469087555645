import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import api from "../api";
import { unAuthorizedApi } from "../api";
const BASE_URL = process.env.REACT_APP_API_URL;

// Move getContentType to be accessible by all functions
const getContentType = (filename) => {
  const extension = filename.split('.').pop().toLowerCase();
  const contentTypes = {
    'mp3': 'audio/mpeg',
    'wav': 'audio/wav',
    'aac': 'audio/aac',
    'm4a': 'audio/mp4',
    'ogg': 'audio/ogg',
    'flac': 'audio/flac'
  };
  return contentTypes[extension] || 'audio/mpeg';
};

export const postTrackApi = async ({
  original_track_id,
  audio,
  trackName,
  genreName,
  selectedRoles,
  workStatus,
  syncedBefore,
  samplesUsed,
  sampleSources,
  targetOptions,
  additionalInfo,
}) => {
  try {
    const contentType = getContentType(audio.name);
    
    const accessToken = localStorage.getItem("token");
    if (!accessToken) {
      console.error("No token found. Please log in.");
      return;
    }
    const decodedToken = jwtDecode(accessToken);
    const artistId = decodedToken["custom:artist_id"];
    let data = new FormData();
    if (original_track_id) {
      data.append("original_track_id", original_track_id);
    }
    data.append("artist_id", artistId);
    data.append("title", trackName);
    data.append("genre", genreName);
    data.append("status", workStatus);
    data.append("roles", selectedRoles);
    data.append("work_status", workStatus);
    data.append("synced_before", syncedBefore);
    data.append("samples_used", samplesUsed);
    data.append("sample_sources", sampleSources);
    data.append("targets", targetOptions);
    data.append("additional_info", additionalInfo);
    data.append("file_name", audio.name);
    data.append("file_type", contentType);  // Use correct content type

    const url = `${BASE_URL}/tracks/signed-url`;
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };
    const res = await api.post(url, data, config);
    let uploadUrl = res.data.file_url;
    let track = res.data;

    // Use the same content type for the upload
    let newConfig = {
      method: "put",
      maxBodyLength: Infinity,
      headers: {
        "Content-Type": contentType,  // Use correct content type
      },
    };

    console.log('Uploading file:', {
      fileName: audio.name,
      contentType,
      size: audio.size
    });

    const uploadResponse = await unAuthorizedApi.put(uploadUrl, audio, newConfig);
    return track;
  } catch (error) {
    if (error.response) {
      console.error("Error response data:", error.response.data);
      console.error("Error response status:", error.response.status);
      console.error("Error response headers:", error.response.headers);
    } else if (error.request) {
      console.error("Error request data:", error.request);
    } else {
      console.error("Error message:", error.message);
    }
    console.error("Error config:", error.config);
    throw error;
  }
};

export const getReuploadSignedUrl = async (trackId, file) => {
  try {
    const accessToken = localStorage.getItem("token");
    if (!accessToken) {
      throw new Error("No token found. Please log in.");
    }

    const contentType = getContentType(file.name);
    
    const url = `${BASE_URL}/tracks/${trackId}/reupload-url`;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };

    const data = {
      file_name: file.name,
      file_type: contentType
    };

    const res = await api.get(url, { ...config, params: data });
    return res.data;
  } catch (error) {
    console.error("Error getting reupload signed URL:", error);
    throw error;
  }
};

export const uploadToS3 = async (uploadUrl, file, contentType) => {
  try {
    const config = {
      method: "put",
      maxBodyLength: Infinity,
      headers: {
        "Content-Type": contentType,
      },
    };

    await unAuthorizedApi.put(uploadUrl, file, config);
  } catch (error) {
    console.error("Error uploading to S3:", error);
    throw error;
  }
};