import { call, put, take, takeLatest, all, fork, delay } from 'redux-saga/effects';
import {
  WEBSOCKET_REQUEST,
  WEBSOCKET_SUCCESS,
  WEBSOCKET_LOADING,
  WEBSOCKET_ERROR,
} from '../actions/actionTypes';
import WebsocketApi from '../../api/websocket/websocketApi';

const MAX_RECONNECT_ATTEMPTS = 5;
const RECONNECT_DELAY = 5000; // 5 seconds

function* webSocketRequest(reconnectAttempt = 0) {
  yield put({ type: WEBSOCKET_LOADING });
  const token = localStorage.getItem('token');
  const endpoint = `${process.env.REACT_APP_WS_URL}?token=${token}`;
  
  try {
    // Create WebSocket channel
    const socketChannel = yield call(WebsocketApi, endpoint);
    
    while (true) {
      try {
        const message = yield take(socketChannel); // Wait for messages from the WebSocket
        yield put({ type: WEBSOCKET_SUCCESS, payload: message });
      } catch (error) {
        console.error('WebSocket message handling error:', error);
        yield put({ type: WEBSOCKET_ERROR, payload: error.message });
        
        // If channel throws error, attempt reconnection
        if (reconnectAttempt < MAX_RECONNECT_ATTEMPTS) {
          console.log(`WebSocket reconnecting... Attempt ${reconnectAttempt + 1} of ${MAX_RECONNECT_ATTEMPTS}`);
          yield delay(RECONNECT_DELAY);
          yield* webSocketRequest(reconnectAttempt + 1);
        } else {
          console.error('Max reconnection attempts reached');
        }
        break;
      }
    }
  } catch (error) {
    console.error('WebSocket connection error:', error);
    yield put({ type: WEBSOCKET_ERROR, payload: error.message });
    
    // Attempt reconnection for connection errors
    if (reconnectAttempt < MAX_RECONNECT_ATTEMPTS) {
      console.log(`WebSocket reconnecting... Attempt ${reconnectAttempt + 1} of ${MAX_RECONNECT_ATTEMPTS}`);
      yield delay(RECONNECT_DELAY);
      yield* webSocketRequest(reconnectAttempt + 1);
    } else {
      console.error('Max reconnection attempts reached');
    }
  }
}

export default function* websocketSaga() {
  yield all([takeLatest(WEBSOCKET_REQUEST, function* () {
    yield call(webSocketRequest, 0);
  })]);
}
