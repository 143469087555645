import React, { useEffect, useMemo, useRef, useState } from "react";
import "../../styles/Fonts.scss";
import "../Supervisor-Dashboard/TrackFeedback.css";
import "../../styles/Fonts.scss";
import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import SMLButton from "../../components/Shared/SMLButton/SMLButton";
import trackImg from "../../assets/Images/TrackRectangle.svg";
import SUPHeader from "../../components/Shared/Header/SupHeader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArtistProfile from "../../components/UI/artistProfile/artistProfile";
import AboutTrack from "../../components/UI/aboutTrack/aboutTrack";
import { useDispatch, useSelector } from "react-redux";
import { getSingleTrackRequest } from "../../redux/actions/uploadTrackActions";
import {
  assignSuperVisorRequest,
  getSingleAritstRequest,
} from "../../redux/actions";

import { useWavesurfer } from "@wavesurfer/react";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions.esm.js";
import { Box, Stack, IconButton } from "@mui/material";
import { Edit, Close, ArrowBack, Videocam } from "@mui/icons-material";

import TrackPlayer from "../../components/common/track-details/TrackPlayer";
import CommentIcon from "../../assets/Icons/comment.svg";
import CommentGrayIcon from "../../assets/Icons/comment-grey.svg";
import AddNoteIcon from "../../assets/Icons/addNote.svg";
import EnhancedCustomTimePicker from "../../components/Shared/TimeInput/EnhancedCustomTimePicker";
import DurationFormatter from "../../components/Shared/DurationFormatter/DurationFormatter";
import { ExternalLink, Info } from "lucide-react";

import {
  addCommentApi,
  deleteCommentApi,
  getAllCommentsApi,
  updateCommentApi,
} from "../../api/supervisor/comment";
import { downloadTrackApi } from "../../api/track/downloadTrackApi";

import "../Reviews/AudioGraph.css";
import { Bars } from "react-loader-spinner";
import { formatTime, parseTime } from "../Reviews/TrackUnderReview";
import SupAssignPopup from "./SupAssignPopup";
import {
  RESET_ASSIGNED_SUPERVISOR,
  RESET_GET_FEEDBACK,
  RESET_POST_FEEDBACK_ASSIGNED,
} from "../../redux/actions/actionTypes";
import { Tooltip } from "react-tooltip";
import { X } from "lucide-react";
import Recorder from "../../components/Shared/Recorder/Recorder";
import { getVideoUrlRequest } from "../../redux/actions/videoActions";
import { getFeedbackRequest } from "../../redux/actions";
import VidFeedbackRestrictionPopup from "../../components/common/restricted-popup/Video-feedback-RestrictionPopup";
import { AlertCircle } from "lucide-react";
import { Spinner } from "react-bootstrap";

const TrackFeedback = (props) => {
  const location = useLocation();
  const status = location.state?.status;
  const fromUserPortal = location.state?.fromUserPortal;
  const fromDashboard = location.state?.fromDashboard;
  const isMaster = localStorage.getItem("isMaster");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { trackId } = useParams();
  const track = useSelector((state) => state.uploadTrack?.singleTrack);

  const artistId = useSelector(
    (state) => state.uploadTrack?.singleTrack?.artist_id
  );
  const artist = useSelector((state) => state.singleArtist?.details);

  const assigned = useSelector(
    (state) => state.uploadTrack?.singleTrack?.assigned_supervisor_id ?? null
  );
  const [showArtistProfile, setShowArtistProfile] = useState(false);
  const [showAboutTrack, setShowAboutTrack] = useState(false);
  const [isCompleteEnabled, setIsCompleteEnabled] = useState(false);
  const [isAssignEnabled, setIsAssignEnabled] = useState(true);
  const [buttonText, setButtonText] = useState("Complete Evaluation");
  const [showAssignPopup, setShowAssignPopup] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(status);
  const [selfAssign, setSelfAssign] = useState(false);
  const [done, setDone] = useState(false);
  const [isEditClick, setIsEditClick] = useState(false);
  const [commentEnabled, setcommentEnabled] = useState(false);
  const [xssError, setXssError] = useState("");
  const [showGuide, setShowGuide] = useState(false);
  const [showVidFeedbackRestriction, setShowVidFeedbackRestriction] =
    useState(false);
  const supId = useSelector(
    (state) => state.sml.allArtistFetched?.artists?._id
  );
  const assignedSupId = useSelector(
    (state) => state.uploadTrack?.singleTrack?.assigned_supervisor?.id
  );
  const supFromAssigned = useSelector(
    (state) => state.assignSupervisor?.assigned?.assigned_supervisor_id
  );
  const assignedStatus = useSelector(
    (state) => state.assignSupervisor?.assigned?.status?.code
  );
  const [showRecorder, setShowRecorder] = useState(false);

  const videoUrl = useSelector(
    (state) => state.video?.signedUrlData?.video_url
  );
  const videoLoading = useSelector((state) => state.video?.loading);

  const feedback = useSelector((state) => state.getFeedback?.feedback);
  const [feedbackId, setFeedbackId] = useState();

  const [processingError, setProcessingError] = useState(false);

  // First useEffect to fetch feedback data
  useEffect(() => {
    if (trackId) {
      dispatch({ type: RESET_GET_FEEDBACK });
      dispatch(getFeedbackRequest(trackId));
    }
  }, [trackId]);

  // Second useEffect to handle feedback data changes
  useEffect(() => {
    if (feedback && Array.isArray(feedback) && feedback.length > 0) {
      setFeedbackId(feedback[0]._id);
    }
  }, [feedback]);

  useEffect(() => {
    if (
      (currentStatus === "feedback_submitted" && isMaster === "false") ||
      currentStatus === "final_feedback_submitted"
    )
      navigate(`/TrackFeedbackReviewed/${trackId}`);
  }, [currentStatus]);
  const handleAssign = () => {
    dispatch({ type: RESET_ASSIGNED_SUPERVISOR });
    setShowAssignPopup(!showAssignPopup);
  };
  const handleStartAssign = async () => {
    dispatch({ type: RESET_ASSIGNED_SUPERVISOR });

    try {
      const response = dispatch(
        assignSuperVisorRequest({ trackId, supervisor: supId })
      );
      setSelfAssign(true);
      setCurrentStatus(assignedStatus);
    } catch (error) {}
  };

  useEffect(() => {
    if (assignedSupId && isMaster === "false" && selfAssign === true) {
      setCurrentStatus(assignedStatus);
      setIsCompleteEnabled(true);
    }
  }, [assignedStatus]);
  const handleTrackEvaluation = () => {
    if (
      (buttonText === "View Evaluation" &&
        status === "final_feedback_submitted") ||
      ((buttonText === "View Evaluation" ||
        buttonText === "Final Evaluation") &&
        status === "feedback_submitted" &&
        isMaster === "false")
    ) {
      dispatch({ type: RESET_GET_FEEDBACK });
      navigate(`/TrackEvaluatedPage/${trackId}`, {
        state: { fromUserPortal, status },
      });
    } else if (buttonText === "View Evaluation" && fromUserPortal) {
      dispatch({ type: RESET_GET_FEEDBACK });
      navigate(`/TrackEvaluated/${trackId}`, {
        state: { fromUserPortal, status },
      });
    } else {
      dispatch({ type: RESET_GET_FEEDBACK });
      dispatch({ type: RESET_POST_FEEDBACK_ASSIGNED });
      navigate(`/TrackEvaluationPage/${trackId}`, {
        state: { fromUserPortal, status, assignedSupId },
      });
    }
  };

  useEffect(() => {
    if (assigned && assigned.length > 0) {
      if (
        isMaster === "true" &&
        currentStatus !== "submitted" &&
        supFromAssigned === supId &&
        supFromAssigned !== undefined
      ) {
        setIsAssignEnabled(false);
        setIsCompleteEnabled(true);
      } else if (isMaster === "false" && currentStatus !== "submitted") {
        setIsAssignEnabled(false);
        setIsCompleteEnabled(true);
      } else if (
        supFromAssigned === supId &&
        isMaster === "true" &&
        currentStatus === "submitted"
      ) {
        setIsAssignEnabled(false);
        setIsCompleteEnabled(true);
      }
    }
  }, [assigned]);
  const statusFromTrack = useSelector(
    (state) => state.uploadTrack?.singleTrack?.status?.code
  );
  useEffect(() => {
    setCurrentStatus(statusFromTrack);
  }, [statusFromTrack]);
  useEffect(() => {
    if (currentStatus === "final_feedback_submitted") {
      setIsCompleteEnabled(true);
      setButtonText("View Evaluation");
    } else if (!fromUserPortal && currentStatus === "in_review") {
      if (assignedSupId === supId) {
        setIsAssignEnabled(false);
        setIsCompleteEnabled(true);
      }
    } else if (currentStatus === "feedback_submitted") {
      if (isMaster === "false") {
        setButtonText("View Evaluation");
      } else {
        setIsCompleteEnabled(true);
        setButtonText("Final Evaluation");
      }
    } else if (currentStatus === "final_feedback_submitted") {
      if (isMaster === "false") {
        setButtonText("View Evaluation");
      } else {
        setIsCompleteEnabled(true);
        setButtonText("View Evaluation");
      }
    } else if (
      supFromAssigned === supId &&
      isMaster === "false" &&
      currentStatus === "submitted"
    ) {
      setIsCompleteEnabled(true);
    }
  }, [
    currentStatus,
    fromUserPortal,
    isAssignEnabled,
    isCompleteEnabled,
    showAssignPopup,
  ]);

  const handleAboutTrack = () => {
    setShowAboutTrack(true);
  };

  const handleArtistProfile = () => {
    setShowArtistProfile(true);
  };

  const handleCloseArtistProfile = () => {
    setShowArtistProfile(false);
  };

  const handleCloseAboutTrack = () => {
    setShowAboutTrack(false);
  };

  useEffect(() => {
    dispatch(getSingleTrackRequest(trackId));
  }, [dispatch, fromUserPortal, trackId]);

  useEffect(() => {
    if (artistId) {
      dispatch(getSingleAritstRequest(artistId));
    }
  }, [artistId, dispatch]);

  const [peaks, setPeaks] = useState(null);
  const [trackUrl, setTrackUrl] = useState();
  const [isLoading, setIsLoading] = useState(true); // Loading state

  const wavesurfRef = useRef(null);
  const regions = useMemo(() => RegionsPlugin.create(), []);

  const { wavesurfer, isPlaying } = useWavesurfer({
    container: wavesurfRef,
    waveColor: "#CBCBCB",
    progressColor: "#FF4405",
    cursorColor: "#FF4405",
    selectionColor: "#FF4405",
    barWidth: 3,
    url: trackUrl,
    peaks,
    height: 150,
    plugins: useMemo(() => [regions], [regions]),
  });
  
  const fetchTrackUrl = async (trackId) => {
    try {
      const { audio_signed_url, peaks_signed_url } = await downloadTrackApi({
        trackId,
      });
      
      // Check if peaks data is available
      if (!peaks_signed_url) {
        console.error("Peaks signed URL not available for track:", trackId);
        setProcessingError(true);
        setIsLoading(false);
        return;
      }
      
      try {
        const peaksResponse = await fetch(peaks_signed_url);
        const peaksData = await peaksResponse.json();
        setPeaks(peaksData.peaks);
        setTrackUrl(audio_signed_url);
        setProcessingError(false);
      } catch (error) {
        console.error("Error fetching peaks data:", error);
        setProcessingError(true);
      }
    } catch (error) {
      console.error("Error fetching track URL:", error);
      setProcessingError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTrackUrl(trackId);
  }, [trackId]);

  useEffect(() => {
    if (wavesurfer) {
      wavesurfer.on("ready", () => {
        console.log("Wavesurfer is ready");
        setDone(true);
        setIsLoading(false);
      });

      wavesurfer.on("error", (error) => {
        if (error?.name === "AbortError") {
          console.warn("Audio loading aborted");
        } else {
          console.error("Error loading the audio", error);
        }
        setIsLoading(false);
      });
    }

    return () => {
      if (wavesurfer && wavesurfer.isReady && wavesurfer.destroy) {
        wavesurfer.destroy();
      }
    };
  }, [wavesurfer]);

  useEffect(() => {
    if (trackId && !processingError) {
      fetchCommentsData();
    }
  }, [trackId, processingError]);

  const [comments, setComments] = useState([]);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [showAddCommentButton, setShowAddCommentButton] = useState(false);
  const [currentComment, setCurrentComment] = useState("");
  const inputRef = useRef(null);
  const guideRef = useRef(null);
  const [commentPosition, setCommentPosition] = useState(0);
  const commentBoxPosition = useMemo(() => {
    if (!wavesurfRef.current) return 0;
    const rect = wavesurfRef.current.getBoundingClientRect();
    const x = (commentPosition / wavesurfer.getDuration()) * rect.width;
    return Math.min(x, rect.width - 300);
  }, [commentPosition, wavesurfer, wavesurfRef]);
  const [charLimit, setCharLimit] = useState(50);
  const [focusedComment, setFocusedComment] = useState(null);
  const [highlightComment, setHighlightComment] = useState(null);
  const focusedCommentContainer = useRef(null);
  useEffect(() => {
    if (!!wavesurfer && focusedComment !== null) {
      if (typeof comments[focusedComment].time === "number") {
        wavesurfer.setTime(comments[focusedComment].time);
      } else {
        regions.clearRegions();
        regions.addRegion({
          start: comments[focusedComment].time[0],
          end: comments[focusedComment].time[1],
          color: "#ff440540",
          resize: false,
        });
      }
    }
  }, [focusedComment, wavesurfer, regions, comments]);

  const handleClickOutside = (event) => {
    if (
      focusedCommentContainer.current &&
      !focusedCommentContainer.current.contains(event.target)
    ) {
      event.preventDefault();
      event.stopPropagation();
      regions.clearRegions();
      setFocusedComment(null);
      setcommentEnabled(false);
      setIsEditClick(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      setIsEditClick(false);
      event.preventDefault();
      event.stopPropagation();
      regions.clearRegions();
      setFocusedComment(null);
      setcommentEnabled(false);
      setActivePosition(null);
      setActiveRegion(null);
      setShowCommentBox(false);
      setShowAddCommentButton(false);
    }
  };
  function containsXSS(input) {
    const xssPattern =
      /<\s*\/?\s*([a-z][a-z0-9]*)\b[^>]*>(?:[^<]*|<(?!\/?\1\b)[^<]*>)*<\/\1>/gi;
    return xssPattern.test(input);
  }
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const [selcetedPart, setSelectedPart] = useState();
  const [activePosition, setActivePosition] = useState();
  const [activeRegion, setActiveRegion] = useState(null);

  useEffect(() => {
    if (
      ((currentStatus === "feedback_submitted" && isMaster === "true") ||
        currentStatus === "in_review" ||
        (currentStatus === "submitted" && isMaster === "false")) &&
      !!wavesurfer &&
      !processingError
    ) {
      regions.enableDragSelection({
        color: "#ff440540",
      });

      regions.on("region-created", (newRegion) => {
        const allRegions = regions.getRegions();
        allRegions.forEach((region) => {
          if (newRegion !== region) region.remove();
        });

        setSelectedPart("region");
        setActiveRegion([newRegion.start, newRegion.end]);
        setCommentPosition(newRegion.start);
        setShowAddCommentButton(true);
        setShowCommentBox(false);
      });

      regions.on("region-updated", (updatedRegion) => {
        setSelectedPart("region");
        setActiveRegion([updatedRegion.start, updatedRegion.end]);
        setCommentPosition(updatedRegion.start);
        setShowAddCommentButton(true);
        setShowCommentBox(false);
      });

      regions.on("region-clicked", (region, e) => {
        e.stopPropagation();
        region.play();
      });
    }
  }, [wavesurfRef, wavesurfer, regions, processingError]);

  useEffect(() => {
    if (!!wavesurfer && !processingError)
      wavesurfRef.current.addEventListener("click", (event) => {
        if (selcetedPart === "region") regions.clearRegions();
        setSelectedPart("point");
        setActivePosition(wavesurfer.getCurrentTime());
        setCommentPosition(wavesurfer.getCurrentTime());
        setShowAddCommentButton(true);
        setShowCommentBox(false);
      });
  }, [
    wavesurfRef,
    wavesurfer,
    regions,
    setSelectedPart,
    setActivePosition,
    selcetedPart,
    processingError,
  ]);

  useEffect(() => {
    if (!showCommentBox) {
      setActivePosition(undefined);
      setActiveRegion(undefined);
      setSelectedPart(undefined);
      setCurrentComment("");
    } else if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [showCommentBox]);

  const handleCommentSubmit = async (event) => {
    if (event.key === "Enter" && currentComment.trim()) {
      let newComment;
      setFocusedComment(null);
      setcommentEnabled(false);
      const commentData = {
        trackId,
        comment: currentComment,
      };

      if (selcetedPart === "point") {
        commentData.startTime = activePosition;
        commentData.endTime = activePosition;
        newComment = {
          time: activePosition,
          transcript: currentComment,
          idSuffix: `${comments.length}-${activePosition}`,
        };
      } else if (selcetedPart === "region") {
        commentData.startTime = activeRegion[0];
        commentData.endTime = activeRegion[1];
        newComment = {
          time: activeRegion,
          transcript: currentComment,
          idSuffix: `${comments.length}-${activePosition}`,
        };
      }
      if (containsXSS(commentData.comment)) {
        setXssError("These scripts not allowed");
        return;
      } else {
        setXssError("");
      }
      try {
        if (
          currentStatus === "submitted" &&
          assignedSupId === supId &&
          isMaster === "false"
        )
          await handleStartAssign();
        const response = await addCommentApi(commentData);
        if (response) {
          getAllCommentsApi(trackId).then((uploadedComments) => {
            setComments(
              uploadedComments.map(
                ({ _id, start_time, end_time, comment }, index) => ({
                  id: _id,
                  time:
                    start_time === end_time
                      ? start_time
                      : [start_time, end_time],
                  transcript: comment,
                  idSuffix: `${index}-${start_time}`,
                })
              )
            );
          });
        } else {
          console.error("Added comment is undefined");
        }

        setShowCommentBox(false);
        setShowAddCommentButton(false);
      } catch (error) {
        console.error("Error adding comment:", error);
      }
    }
  };

  const reDirectToComment = (timeInSeconds) => {
    if (wavesurfer) {
      wavesurfer.seekTo(timeInSeconds / wavesurfer.getDuration());
    }
  };
  const user = useSelector((state) => state.sml.allArtistFetched?.artists);
  const handleCloseAssignPopup = () => {
    setShowAssignPopup(false);
    dispatch(getSingleTrackRequest(trackId));
  };
  useEffect(() => {
    if (assignedSupId !== undefined) {
      if (currentStatus !== "final_feedback_submitted") {
        if (assignedSupId === supId) {
          setIsCompleteEnabled(true);
        } else {
          if (currentStatus === "in_review") {
            setIsCompleteEnabled(false);
          }
        }
      }
    }
  }, [
    showAssignPopup,
    assignedSupId,
    getSingleTrackRequest,
    currentStatus,
    supId,
  ]);

  const handleStatus = () => {
    if (currentStatus === "submitted") {
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (guideRef.current && !guideRef.current.contains(event.target)) {
        setShowGuide(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    assignedSupId === supId && comments.length === 0
      ? setShowGuide(true)
      : setShowGuide(false);
  }, [comments, supId, assignedSupId]);

  useEffect(() => {
    const updateCharLimit = () => {
      const width = window.innerWidth;

      // Set character limits based on screen size
      if (width > 1200) {
        setCharLimit(100); // Large screens
      } else if (width > 1024) {
        setCharLimit(60); // Tablet screens
      } else if (width > 992) {
        setCharLimit(50); // Tablet screens
      } else if (width > 768) {
        setCharLimit(40); // Tablet screens
      } else {
        setCharLimit(10); // Mobile screens
      }
    };

    // Run on load and add listener for screen resize
    updateCharLimit();
    window.addEventListener("resize", updateCharLimit);

    // Clean up event listener on unmount
    return () => window.removeEventListener("resize", updateCharLimit);
  }, []);
  const handleVidFeedbackClick = (decision) => {
    if (decision) {
      setShowRecorder(true);
    }
  };
  const renderRecorderButton = () => {
    if (!isMaster) return null;

    return (
      <button
        onClick={() => {
          setShowVidFeedbackRestriction(true);
          // setShowRecorder(true);
        }}
        className="video-feedback-button"
      >
        <Videocam sx={{ color: "white" }} />
        <span>Give Video Feedback</span>
      </button>
    );
  };

  const feedbackLoading = useSelector((state) => state.getFeedback?.loading);

  // Add this useEffect near the top with other track-related effects
  useEffect(() => {
    // Reset states when track changes or component unmounts
    const resetStates = () => {
      dispatch({ type: "RESET_VIDEO_URL" });
      setShowRecorder(false);
    };

    resetStates(); // Reset on mount and track change

    // Fetch track data
    dispatch(getSingleTrackRequest(trackId));

    if (artistId) {
      dispatch(getSingleAritstRequest(artistId));
    }

    // Cleanup function
    return resetStates;
  }, [trackId]); // Only depend on trackId

  // Separate useEffect for video URL fetching
  useEffect(() => {
    if (feedbackId && currentStatus !== "submitted") {
      dispatch(getVideoUrlRequest(feedbackId));
    }
  }, [feedbackId, currentStatus]);

  // Add fetchCommentsData function
  const fetchCommentsData = async () => {
    try {
      const uploadedComments = await getAllCommentsApi(trackId);
      setComments(
        uploadedComments.map(
          ({ _id, start_time, end_time, comment }, index) => ({
            id: _id,
            time: start_time === end_time ? start_time : [start_time, end_time],
            transcript: comment,
            idSuffix: `${index}-${start_time}`,
          })
        )
      );
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  return (
    <>
      {showAssignPopup && (
        <SupAssignPopup
          trackId={trackId}
          onClose={handleCloseAssignPopup}
          currentStatus={currentStatus}
          handleStatus={handleStatus}
          assignedSupervisor={assigned ? true : false}
        />
      )}
      <div
        className="full-screen-background vh-100 px-3"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {!fromUserPortal && <SUPHeader />}
        <div className="trackFeedback-main" style={{ margin: "0 2.5rem" }}>
          <div className="back-to-dashboard">
            <IconButton
              sx={{
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
              onClick={() => {
                dispatch({ type: RESET_ASSIGNED_SUPERVISOR });
                if (fromDashboard) {
                  navigate("/dashboard");
                } else if (!fromUserPortal) {
                  navigate("/supervisorDashboard");
                } else {
                  if (status === "submitted") navigate("/uploadedTrack");
                  else if (status === "in_review") navigate("/Review");
                  else if (status === "final_feedback_submitted")
                    navigate("/FeedbackReceived");
                  else navigate("/dashboard");
                }
              }}
            >
              <div>
                <ArrowBack style={{ cursor: "pointer" }} />
              </div>
              <div>
                <SMLLabel style="title2" value="Back" />
              </div>
            </IconButton>
          </div>
          <div
            className="track-main-div"
            style={{
              paddingTop: "15px",
              position: "relative",
              marginLeft: "4rem",
            }}
          >
            {/* Replace the header section */}
            <div className="d-flex align-items-center justify-content-between">
              {/* Left side */}
              <div className="d-flex align-items-start gap-3 w-100">
                {/* Track image */}
                <div className="d-flex align-items-start">
                  <img
                    src={trackImg}
                    alt="Track"
                    width="80"
                    height="80"
                    className="track-image"
                  />
                </div>

                {/* Track title and info */}
                <div className="d-flex-col flex-grow-1">
                  {/* Title and icons */}
                  <div 
                    style={{
                      display: "flex",
                      alignItems: "start",
                      gap: "4px",
                      marginBottom: "0px" // Reduced space between title and artist
                    }}
                  >
                    <div onClick={handleAboutTrack} style={{ maxWidth: "100%" }}>
                      <div
                        style={{ 
                          fontSize: "16px",
                          fontWeight: "600", // Made track name bold
                          lineHeight: "24px" // Added to align with info icon
                        }}
                        className="track-title"
                        data-tooltip-id="trackTitle-details-final"
                        data-tooltip-content={track?.title}
                        data-tooltip-place="bottom"
                      >
                        {track?.title}
                      </div>
                      <Tooltip id="trackTitle-details-final" multiline={true} />
                    </div>

                    <div
                      onClick={handleAboutTrack}
                      style={{
                        cursor: "pointer",
                        color: "#6c757d",
                        padding: "4px 0", // Adjusted padding to align vertically
                        display: "flex",
                        alignItems: "center",
                        height: "24px" // Match line-height of title
                      }}
                      data-tooltip-id="trackInfo"
                    >
                      <Info size={16} />
                      <Tooltip id="trackInfo" content="Track Details" />
                    </div>
                  </div>

                  {/* Artist name and badge line */}
                  <div className="d-flex align-items-center gap-2">
                    <span onClick={handleArtistProfile} className="artist-link">
                      {artist?.name || "Unknown Artist"}
                    </span>
                    {artist?.subscription?.tier && (
                      <span className={`plan-badge ${(artist?.subscription?.tier).toLowerCase()}`}>
                        {artist.subscription.tier}
                      </span>
                    )}
                  </div>
                </div>

                {/* Right side content */}
                <div className="d-flex align-items-center" style={{ marginLeft: "32px" }}>
                  {/* Duration */}
                  <span className="duration-badge me-3">
                    {track?.duration ? <DurationFormatter seconds={track.duration} /> : "00:00"}
                  </span>

                  {/* Revision badge */}
                  {track?.original_track_id && (
                    <div className="revision-badge me-3">
                      <span className="revision-number">
                        Revision: {track.revision_number || 1}
                      </span>
                      <a
                        href={`/TrackFeedback/${track.original_track_id}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(`/TrackFeedback/${track.original_track_id}`, "_blank");
                        }}
                        className="revision-link"
                      >
                        <ExternalLink size={14} />
                      </a>
                    </div>
                  )}

                  {/* Buttons */}
                  <div className="d-flex align-items-center gap-2">
                    {(currentStatus === "submitted" || currentStatus === "in_review") && 
                      isMaster === "true" && (
                      <div style={{ position: "relative" }}>
                        <SMLButton
                          style={`SMLAssignBtn SMLAssignBtn-disabled ${(isAssignEnabled && !processingError) ? "" : "disabled"}`}
                          value="Assign"
                          handleClickEvent={processingError ? null : handleAssign}
                          textColor="#ff4405"
                          data-tooltip-id="assign-tooltip"
                        />
                        {processingError && (
                          <Tooltip id="assign-tooltip" place="top">
                            Cannot assign track with processing error. Artist needs to re-upload.
                          </Tooltip>
                        )}
                      </div>
                    )}
                    <div style={{ position: "relative" }}>
                      <SMLButton
                        style={`SMLButton2 SMLButton-Orange textwrap-nowrap-button ${(isCompleteEnabled && !processingError) ? "" : "disabled"}`}
                        value={buttonText}
                        handleClickEvent={processingError ? null : () => {
                          if (currentStatus === "submitted" && isMaster === "false" && supId === assignedSupId) {
                            handleStartAssign();
                          }
                          handleTrackEvaluation();
                        }}
                        handleDisable={!isCompleteEnabled || processingError}
                        data-tooltip-id="complete-tooltip"
                      />
                      {processingError && (
                        <Tooltip id="complete-tooltip" place="top">
                          Cannot complete evaluation due to processing error. Artist needs to re-upload.
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="track-main-div"
            style={{ flex: "1 1", paddingTop: "20px" }}
          >
            {track && (
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  flexDirection: "column",
                }}
              >
                <Stack
                  sx={{
                    flexGrow: 1,
                    height: "100%",
                    maxHeight: "100%",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      height: "100%",
                      maxHeight: "100%",
                      overflow: "hidden",
                      paddingTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      marginLeft: "5rem",
                    }}
                  >
                    <div style={{ position: "relative", height: "30px" }}>
                      {!fromUserPortal &&
                        wavesurfer &&
                        (showCommentBox || showAddCommentButton) &&
                        focusedComment === null &&
                        (selcetedPart === "point" ? (
                          <div
                            className="time-box"
                            style={{ left: commentBoxPosition }}
                          >
                            <EnhancedCustomTimePicker
                              time={formatTime(commentPosition)}
                              setTime={(timeString) => {
                                const newPoint = parseTime(timeString);
                                setCommentPosition(newPoint);
                                if (!!wavesurfer) wavesurfer.setTime(newPoint);
                              }}
                            />
                          </div>
                        ) : selcetedPart === "region" &&
                          activeRegion &&
                          activeRegion.length !== 0 ? (
                          <div
                            className="time-box"
                            style={{ left: commentBoxPosition }}
                          >
                            <EnhancedCustomTimePicker
                              time={formatTime(activeRegion[0])}
                              setTime={(timeString) => {
                                const newPoint = parseTime(timeString);
                                setActiveRegion([newPoint, activeRegion[1]]);
                                const allRegions = regions.getRegions();
                                if (allRegions.length > 0) {
                                  allRegions[0].setOptions({
                                    start: newPoint,
                                  });
                                  setCommentPosition(newPoint);
                                }
                              }}
                            />
                            <EnhancedCustomTimePicker
                              time={formatTime(activeRegion[1])}
                              setTime={(timeString) => {
                                const newPoint = parseTime(timeString);
                                setActiveRegion([activeRegion[0], newPoint]);
                                const allRegions = regions.getRegions();
                                if (allRegions.length > 0) {
                                  allRegions[0].setOptions({
                                    end: newPoint,
                                  });
                                }
                              }}
                            />
                          </div>
                        ) : null)}
                      {wavesurfer &&
                        comments.map((comment, index) => (
                          <div
                            onClick={() => {
                              const element = document.getElementById(
                                `transcript-${comment.idSuffix}`
                              );
                              const container = element.parentElement;

                              container.scrollTo({
                                left: element.offsetLeft - container.offsetLeft,
                                behavior: "smooth",
                              });
                              setFocusedComment(index);
                              regions.clearRegions();
                            }}
                            key={`comment-${comment.idSuffix}`}
                            style={{
                              position: "absolute",
                              left: `${
                                ((typeof comment.time === "object"
                                  ? comment.time[0]
                                  : comment.time) /
                                  wavesurfer.getDuration()) *
                                100
                              }%`,
                              top: "12rem",
                              height: "24px",
                              width: " 24px",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={
                                focusedComment === index ||
                                highlightComment === index
                                  ? CommentIcon
                                  : CommentGrayIcon
                              }
                              alt="Comment"
                            />
                          </div>
                        ))}
                    </div>
                    {xssError && <SMLLabel style="error" value={xssError} />}
                    
                    <div style={{
                      position: "relative",
                      width: "100%",
                      marginBottom: "20px",
                      flex: 1
                    }}>
                      {processingError ? (
                        <div className="processing-error-container">
                          <div className="processing-error-message">
                            <AlertCircle size={32} color="#ff4405" />
                            <h3>Track Processing Error</h3>
                            <p>
                              The waveform for this track cannot be displayed due to a processing error.
                            </p>
                            <p>
                              Please inform the artist that they need to re-upload this track.
                            </p>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className={`waveform-container ${processingError ? 'hidden' : ''}`}>
                            <div ref={wavesurfRef} className="waveform" />
                            {!wavesurfer || (wavesurfer && wavesurfer.getDuration() === 0) ? (
                              <div className="spinnerOverlay">
                                <Spinner animation="border" variant="light" />
                              </div>
                            ) : null}
                          </div>
                        </>
                      )}
                    </div>
                    
                    {showCommentBox && focusedComment === null && (
                      <div
                        className="comment-box"
                        style={{ left: commentBoxPosition }}
                      >
                        <div className="comment-header">
                          <span className="timestamp">
                            <div style={{ height: "24px", width: " 24px" }}>
                              <img src={CommentIcon} alt="Comment" />
                            </div>
                            {selcetedPart === "point" &&
                            activeRegion &&
                            activeRegion.length !== 0
                              ? `${formatTime(activePosition)}`
                              : selcetedPart === "region"
                              ? `${formatTime(
                                  activeRegion[0]
                                )} - ${formatTime(activeRegion[1])}`
                              : ""}
                          </span>
                          {(currentStatus === "in_review" ||
                            currentStatus === "feedback_submitted") && (
                            <IconButton
                              sx={{ padding: 0.7 }}
                              onClick={() => {
                                if (selcetedPart === "region")
                                  regions.clearRegions();
                                setShowCommentBox(false);
                                setShowAddCommentButton(false);
                                if (xssError !== "") setXssError("");
                              }}
                            >
                              <Close sx={{ height: 20, width: 20 }} />
                            </IconButton>
                          )}
                        </div>
                        <label>{user?.name}</label>
                        <textarea
                          ref={inputRef}
                          type="text"
                          value={currentComment}
                          onChange={(e) => setCurrentComment(e.target.value)}
                          onKeyPress={handleCommentSubmit}
                          placeholder="Type your note..."
                        />
                      </div>
                    )}
                    {(currentStatus === "in_review" ||
                      (currentStatus === "submitted" &&
                        isMaster === "false") ||
                      (isMaster === "true" &&
                        currentStatus === "feedback_submitted")) &&
                      showAddCommentButton &&
                      focusedComment === null && (
                        <div
                          className="add-note"
                          style={{ left: commentBoxPosition - 8 }}
                          onClick={() => {
                            setShowAddCommentButton(false);
                            setShowCommentBox(true);
                            setcommentEnabled(true);
                          }}
                        >
                          <img src={AddNoteIcon} alt="Comment" width={20} />
                          Add Note
                        </div>
                      )}
                  </div>
                </Stack>
                <Stack className="trackPlay">
                  <TrackPlayer
                    track={track}
                    isPlaying={isPlaying}
                    wavesurfer={wavesurfer}
                    done={done}
                    commentEnabled={commentEnabled}
                  />
                </Stack>
              </Box>
            )}
          </div>
        </div>
        {showVidFeedbackRestriction && (
          <VidFeedbackRestrictionPopup
            handleClick={handleVidFeedbackClick}
            onClose={() => {
              setShowVidFeedbackRestriction(false);
            }}
          />
        )}

        {showArtistProfile && (
          <div className="popup-background-blur">
            <div className="ArtistProfile-popup">
              <ArtistProfile
                onArtistProfileClose={handleCloseArtistProfile}
                artist={artist}
              />
            </div>
          </div>
        )}

        {showAboutTrack && (
          <div className="popup-background-blur">
            <div className="ArtistProfile-popup">
              <AboutTrack
                onAboutTrackClose={handleCloseAboutTrack}
                track={track}
                fromArtistPortal={false}
              />
            </div>
          </div>
        )}
        {(currentStatus === "in_review" ||
          (isMaster === "true" && currentStatus === "feedback_submitted")) &&
          renderRecorderButton()}
        {showRecorder && isMaster && (
          <Recorder
            onClose={() => setShowRecorder(false)}
            trackId={trackId}
            feedbackId={feedbackId}
            existingVideoUrl={videoUrl}
          />
        )}
      </div>
    </>
  );
};

export default TrackFeedback;
