import React, { useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom';
import "./parallaxLogin.css";
import logo from "../../../assets/Images/Logo.svg";
import SignUpPage from "../../../pages/SignUpPage/SignUpPage";
import { useDispatch, useSelector } from "react-redux";
import TermsAndConditions from "../../UI/TermsAndConditions/TermsAndCondition";
import { useNavigate } from "react-router-dom";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import PrivacyPolicy from "../../../pages/PrivacyPolicyPage";



const ParallaxLogin = ({ onNavigate, isPopupOpen, setIsPopupOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const supShowLoginPage = useSelector((state) => state.auth.supShowLogin);
  const [showSuperVisorLogin, setShowSuperVisorLogin] = useState(false);
  const [showSignUpPage, setShowSignUpPage] = useState(false);
  const parallaxContainerRef = useRef(null);

  // Add useEffect to handle body scroll
  useEffect(() => {
    if (isModalOpen || isPrivacyModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    // Cleanup when component unmounts
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isModalOpen, isPrivacyModalOpen]);

  const openModal = () => {
    setIsModalOpen(true);
    setIsPopupOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsPopupOpen(false);
    document.body.style.overflow = 'unset';
  };

  const closeSignUpModal = () => {
    setIsPopupOpen(false);
    setShowSignUpPage(false);
  };

  const openSuperVisorLogin = () => {
    setIsPopupOpen(true);
    setShowSuperVisorLogin(true);
    sessionStorage.setItem("pageClosing", "true");
  };
  const closeSuperVisorLogin = () => {
    setIsPopupOpen(false);
    setShowSuperVisorLogin(false);
  };
  useEffect(() => {
    if (supShowLoginPage) {
      setShowSuperVisorLogin(true);
    }
  }, [supShowLoginPage]);
  const openSignUpPage = () => {
    setIsPopupOpen(true);
    setShowSignUpPage(true);
    sessionStorage.setItem("pageClosing", "true");
  };
  
  const openPrivacyModal = () => {
    setIsPrivacyModalOpen(true);
    setIsPopupOpen(true);
  };
  const closePrivacyModal = () => {
    setIsPrivacyModalOpen(false);
    setIsPopupOpen(false);
    document.body.style.overflow = 'unset';
  };

  useGSAP(() => {
    // Register ScrollTrigger plugin
    gsap.registerPlugin(ScrollTrigger);

    const words = parallaxContainerRef.current.querySelectorAll('.Parallax-Text .parallax-login-word');
    gsap.set(words, { opacity: 0 });


    gsap.to(words, {
      opacity: 1,
      duration: 0.5,
      stagger: 0.1,
      ease: "power2.out",
      scrollTrigger: {
        trigger: ".Parallax-Text",
        start: "top center+=100",
        end: "bottom center-=100",
        toggleActions: "play none none reverse",
      
      }
    });

    gsap.set(".Parallax-logo-nav", {
      opacity: 0,
      x: -100
    });
    gsap.to(".Parallax-logo-nav", {
      opacity: 1,
      x: 0,
      delay: 0.5,
      duration: 1,
      ease: "power2.out",
      scrollTrigger: {
        trigger: ".Parallax-bottom",
        start: "top bottom",
        
      }
    });

    gsap.set([".parallax-btn-group", ".parallax-btn-group-mobile"], {
      opacity: 0,
      x: 100
    });
    gsap.to([".parallax-btn-group", ".parallax-btn-group-mobile"], {
      opacity: 1,
      x: 0,
      delay: 0.8,
      duration: 1,
      ease: "power2.out",
      scrollTrigger: {
        trigger: ".Parallax-bottom",
        start: "top bottom",
      }
    });
  }, { scope: parallaxContainerRef });

  // Create portal container for modal
  const modalRoot = document.getElementById('root'); // or your root element id

  return (
    <>
      <div className="Parallax-container" ref={parallaxContainerRef}>
        <div className="Parallax-Content">
          <div className="Parallax-Text">
            {" FROM CONCEPT TO LICENSING, WE GOT YOUR BACK.".trim().split(" ").map((word, index) => (
              <span key={index} className="parallax-login-word" style={{ marginRight: '0.2em', display: "inline-block" }}>
                {word}
              </span>
            ))}
          </div>
        </div>
        <div className="Parallax-bottom">
          <div className="Parallax-logo-nav">
            <div className="Parallax-logo">
              <img src={logo} alt="Logo" />
            </div>
            <div className="Parallax-nav-group">
              <div className="parallav-nav-content">
                <div onClick={() => onNavigate("about")}>About SML</div>
                <div onClick={() => onNavigate("howto")}>How it works</div>
                <div onClick={() => onNavigate("pricing")}>Pricing</div>
              </div>
              <div className="parallav-nav-content">
                <div onClick={() => onNavigate("faq")}>FAQ</div>
                <div>
                  <a onClick={openModal} style={{ cursor: "pointer" }}>
                    Terms
                  </a>
                </div>
                <div>
                  <a onClick={openPrivacyModal} style={{ cursor: "pointer" }}>
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="parallax-btn-group">
            <button
              className="parallax-btn"
              onClick={() => {
                openSignUpPage();
              }}
            >
              Get Started
            </button>
          </div>
        </div>
      </div>
      
      {isModalOpen && ReactDOM.createPortal(
        <div 
          className="modal-portal" 
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              closeModal();
            }
          }}
          style={{ 
            position: 'fixed', 
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9999,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            padding: '8px'
          }}
        >
          <div style={{ width: '100%', maxWidth: '800px' }}>
            <TermsAndConditions
              isOpen={isModalOpen}
              onClose={closeModal}
            />
          </div>
        </div>,
        document.getElementById('root')
      )}

      {isPrivacyModalOpen && ReactDOM.createPortal(
        <div 
          className="modal-portal" 
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              closePrivacyModal();
            }
          }}
          style={{ 
            position: 'fixed', 
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9999,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            padding: '8px'
          }}
        >
          <div style={{ width: '100%', maxWidth: '800px' }}>
            <PrivacyPolicy
              isOpen={isPrivacyModalOpen}
              onClose={closePrivacyModal}
            />
          </div>
        </div>,
        document.getElementById('root')
      )}
      
      {showSuperVisorLogin && navigate("/supervisor/login")}
      {showSignUpPage && <SignUpPage onClose={closeSignUpModal} fromHome={false} />}
    </>
  );
};

export default ParallaxLogin;
