import { all } from "redux-saga/effects";
import authSaga from "./authSaga";
import searchSaga from "./searchSaga";
import trackStatsSaga from "./trackStatsSaga";
import smlSaga from "./smlSagas";
import assignSupervisorSaga from "./assignSupervisorSaga";
import {
  postFeedbackSaga,
  putFinalFeedbackMsSaga,
  putFeedbackMsSaga,
  getFeedbackSaga,
  putFeedbackSaga,
} from "./postFeedbackSaga";
import { downloadTrackSaga } from "./downloadTrackSaga";
import { getSingleArtistSaga } from "./artistSaga";
import supervisorSaga from "./supervisorSaga";
import paymentSaga from "./paymentSaga";
import forgetPasswordSaga from "./forgetPasswordSaga";
import confirmMailSaga from "./confirmMailSaga";
import websocketSaga from "./websocketSaga";
import getNotificationSaga from "./notificationSaga";
import videoSaga from "./videoSaga";
import uploadStatsSaga from "./uploadStatsSaga";
import getOriginalTrackSaga from "./originalTrackSaga";
import deleteProfileSaga from "./deleteProfileSaga";
import resourceSaga from "./resourceSaga";

export default function* rootSaga() {
  try {
    console.log("=== Root Saga Started ===");
    const sagas = [
      authSaga(),
      trackStatsSaga(),
      smlSaga(),
      assignSupervisorSaga(),
      postFeedbackSaga(),
      putFeedbackSaga(),
      putFeedbackMsSaga(),
      putFinalFeedbackMsSaga(),
      downloadTrackSaga(),
      getSingleArtistSaga(),
      getFeedbackSaga(),
      supervisorSaga(),
      paymentSaga(),
      forgetPasswordSaga(),
      confirmMailSaga(),
      websocketSaga(),
      getNotificationSaga(),
      videoSaga(),
      uploadStatsSaga(),
      getOriginalTrackSaga(),
      deleteProfileSaga(),
      resourceSaga(),
    ];

    console.log("=== About to start all sagas ===");
    yield all(sagas);
    console.log("=== All sagas started ===");
  } catch (error) {
    console.error("Root Saga ERROR:", error);
    throw error;
  }
}
