import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import SUPHeader from '../../../components/Shared/Header/SupHeader';
import SMLLabel from '../../../components/Shared/SMLLabel/SMLLabel';
import { Bars } from 'react-loader-spinner';
import { getArtistsApi } from '../../../api/artists/getArtistsApi';
import './ArtistManagement.css';

const formatDate = (dateString) => {
  if (!dateString) return '-';
  
  const date = new Date(dateString);
  const now = new Date();
  const diffTime = Math.abs(now - date);
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  
  // For dates within the last 7 days, show relative time
  if (diffDays < 7) {
    if (diffDays === 0) {
      const hours = Math.floor(diffTime / (1000 * 60 * 60));
      if (hours === 0) {
        const minutes = Math.floor(diffTime / (1000 * 60));
        return `${minutes} min${minutes !== 1 ? 's' : ''} ago`;
      }
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    }
    return `${diffDays} day${diffDays !== 1 ? 's' : ''} ago`;
  }
  
  // For dates older than 7 days, show formatted date and time together
  return date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  });
};

export default function ArtistManagement() {
  const [loading, setLoading] = useState(false);
  const [artists, setArtists] = useState([]);
  const [error, setError] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [expandedArtistId, setExpandedArtistId] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();

  // Initialize pagination and sorting with default values
  const [pagination, setPagination] = useState({
    total: 0,
    offset: 0,
    limit: 10
  });

  const [sorting, setSorting] = useState({
    sortBy: 'created_at',
    sortOrder: 'desc'
  });

  const fetchArtists = async (isLoadMore = false) => {
    try {
      // Check if we've already loaded all items
      if (isLoadMore && pagination.offset + pagination.limit >= pagination.total) {
        setHasMore(false);
        return;
      }

      setLoading(true);
      setError(null);

      // Construct params with root level parameters
      const params = {
        offset: isLoadMore ? pagination.offset + pagination.limit : 0,
        limit: pagination.limit,
        sortBy: sorting.sortBy,
        sortOrder: sorting.sortOrder
      };

      // Only add keyword if it exists and is not empty
      if (searchKeyword?.trim()) {
        params.keyword = searchKeyword.trim();
      }

      const response = await getArtistsApi(params);
      
      console.log('API Response:', response);
      
      const newArtists = response?.artists || [];
      console.log('Artists Data:', newArtists);
      
      setArtists(prevArtists => isLoadMore ? [...prevArtists, ...newArtists] : newArtists);
      
      const newPagination = response?.pagination || {};
      setPagination(prev => ({
        ...prev,
        total: newPagination.total || 0,
        offset: isLoadMore ? prev.offset + prev.limit : 0,
        limit: newPagination.limit || prev.limit
      }));

      // Check if we have more items to load
      const nextOffset = (isLoadMore ? pagination.offset + pagination.limit : 0) + newPagination.limit;
      setHasMore(nextOffset < newPagination.total);
    } catch (error) {
      console.error('Error fetching artists:', error);
      setError(error.message || 'Failed to fetch artists');
    } finally {
      setLoading(false);
    }
  };

  const handleRoleFilter = (role) => {
    setSelectedRoles(prev => {
      const isSelected = prev.includes(role);
      const newRoles = isSelected 
        ? prev.filter(r => r !== role)
        : [...prev, role];
      
      // Reset artists and pagination when changing filters
      setArtists([]);
      setPagination(prev => ({ ...prev, offset: 0 }));
      
      return newRoles;
    });
  };

  const handleSortChange = (sortBy) => {
    setSorting(prev => {
      const newSortOrder = prev.sortBy === sortBy 
        ? (prev.sortOrder === 'desc' ? 'asc' : 'desc')
        : 'desc';
      return {
        sortBy,
        sortOrder: newSortOrder
      };
    });
    
    // Reset artists and pagination when changing sort
    setArtists([]);
    setPagination(prev => ({ ...prev, offset: 0 }));
  };

  const lastArtistElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        fetchArtists(true);
      }
    });
    
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setArtists([]);
      setPagination(prev => ({ ...prev, offset: 0 }));
      fetchArtists();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchKeyword, selectedRoles, sorting]);

  const handleClearSearch = () => {
    setSearchKeyword('');
    setSelectedRoles([]);
  };

  const handleRowClick = (artistId) => {
    setExpandedArtistId(expandedArtistId === artistId ? null : artistId);
  };

  const renderExpandedContent = (artist) => {
    return (
      <div className="expanded-content">
        <div className="expanded-section">
          <h4>Artist Details</h4>
          <div className="details-grid">
            <div className="detail-item">
              <label>Email:</label>
              <span>{artist.email}</span>
            </div>
            <div className="detail-item">
              <label>Auth Provider:</label>
              <span>{artist.auth_provider}</span>
            </div>
            <div className="detail-item">
              <label>Roles:</label>
              <span>{artist.role?.join(', ')}</span>
            </div>
            <div className="detail-item">
              <label>Last Updated:</label>
              <span>{formatDate(artist.updated_at)}</span>
            </div>
            <div className="detail-item">
              <label>Sync Experience:</label>
              <span>{artist.experience_with_sync ? 'Yes' : 'No'}</span>
            </div>
            <div className="detail-item">
              <label>SML Lead Source:</label>
              <span>{artist.sml_lead_info}</span>
            </div>
            <div className="detail-item full-width">
              <label>Music Experience:</label>
              <span>{artist.music_experience_info}</span>
            </div>
            <div className="detail-item full-width">
              <label>Guidance Needed:</label>
              <span>{artist.guidance_needed_info}</span>
            </div>
          </div>
        </div>
        
        <div className="expanded-section">
          <h4>Personal Links</h4>
          <div className="links-list">
            {artist.personal_links?.map((link, index) => (
              <a key={index} href={link} target="_blank" rel="noopener noreferrer" className="personal-link">
                {link}
              </a>
            ))}
          </div>
        </div>

        <div className="expanded-section">
          <h4>Subscription Details</h4>
          <div className="details-grid">
            <div className="detail-item">
              <label>Tier:</label>
              <span>{artist.subscription?.tier}</span>
            </div>
            <div className="detail-item">
              <label>Status:</label>
              <span>{artist.subscription?.status}</span>
            </div>
            <div className="detail-item">
              <label>Interval:</label>
              <span>{artist.subscription?.interval}</span>
            </div>
            <div className="detail-item">
              <label>Monthly Upload Count:</label>
              <span>{artist.subscription?.monthly_upload_count}</span>
            </div>
            <div className="detail-item">
              <label>Last Upload Reset:</label>
              <span>{formatDate(artist.subscription?.last_upload_reset)}</span>
            </div>
            <div className="detail-item">
              <label>Subscription ID:</label>
              <span>{artist.subscription?.subscription_id}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="vh-100 px-3" style={{ background: 'white', color: 'black', overflowY: 'auto' }}>
      {loading && artists.length === 0 && (
        <div className="spinner-overlay">
          <Bars
            height="80"
            width="80"
            color="#23f0c7"
            ariaLabel="bars-loading"
            visible={true}
          />
        </div>
      )}
      
      <SUPHeader />
      
      <div className="padding-0">
        <div className="bg-main-div mt-3">
          <Row className="mb-3 supervisor-dashboard-container">
            <Col className="d-flex justify-content-center align-items-center py-3">
              <div className="box d-flex justify-content-between align-items-center supd-stats">
                <div className="d-flex align-items-center gap-3">
                  <SMLLabel style="title1" value="Artist Management" />
                  <span className="total-count">({pagination.total} Artists)</span>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="bg-main-div mt-3 mb-3">
          <Row className="mb-3">
            <Col>
              <div className="supd-header2">
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div className="search-container">
                    <div className="search-input-wrapper">
                      <input
                        type="text"
                        className="form-control search-input"
                        placeholder="Search artists..."
                        value={searchKeyword}
                        onChange={(e) => setSearchKeyword(e.target.value)}
                      />
                      {searchKeyword && (
                        <button
                          className="search-clear-button"
                          onClick={handleClearSearch}
                          type="button"
                        >
                          ×
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          {error && (
            <div className="alert alert-danger mx-3">
              {error}
            </div>
          )}

          <div className="p-4">
            {artists && artists.length > 0 ? (
              <div className="artist-table">
                <div className="artist-table-header">
                  <div className="header-cell">Name</div>
                  <div className="header-cell">Subscription Tier</div>
                  <div className="header-cell">Subscription Status</div>
                  <div className="header-cell">Monthly Uploads</div>
                  <div className="header-cell">Total Tracks</div>
                  <div className="header-cell">Submitted</div>
                  <div className="header-cell">In Review</div>
                  <div className="header-cell">Feedback Received</div>
                  <div className="header-cell">Created On</div>
                  <div className="header-cell">Status</div>
                </div>
                {artists.map((artist, index) => (
                  <React.Fragment key={artist._id}>
                    <div 
                      className={`artist-table-row ${expandedArtistId === artist._id ? 'expanded' : ''}`}
                      onClick={() => handleRowClick(artist._id)}
                      ref={index === artists.length - 1 ? lastArtistElementRef : null}
                    >
                      <div className="table-cell">{artist.name}</div>
                      <div className="table-cell">
                        <span className={`plan-badge ${artist.subscription?.tier?.toLowerCase() || 'free'}`}>
                          {artist.subscription?.tier || 'Free'}
                        </span>
                      </div>
                      <div className="table-cell">
                        <span className={`status-badge ${artist.subscription?.status?.toLowerCase() === 'inactive' ? 'inactive' : 'active'}`}>
                          {artist.subscription?.status === 'inactive' ? 'Inactive' : 'Active'}
                        </span>
                      </div>
                      <div className="table-cell track-stat">
                        {artist.subscription?.monthly_upload_count || 0}
                      </div>
                      <div className="table-cell track-stat">
                        {artist.track_stats?.total_tracks || 0}
                      </div>
                      <div className="table-cell track-stat">
                        {artist.track_stats?.submitted_tracks || 0}
                      </div>
                      <div className="table-cell track-stat">
                        {artist.track_stats?.assigned_tracks || 0}
                      </div>
                      <div className="table-cell track-stat">
                        {artist.track_stats?.final_feedback_submitted_tracks || 0}
                      </div>
                      <div className="table-cell date-cell">
                        {formatDate(artist.created_at)}
                      </div>
                      <div className="table-cell status-cell">
                        {artist.is_deleted && <span className="status-tag deleted">Deleted</span>}
                        {artist.is_disabled && <span className="status-tag disabled">Disabled</span>}
                        {!artist.is_deleted && !artist.is_disabled && <span className="status-tag active">Active</span>}
                      </div>
                    </div>
                    {expandedArtistId === artist._id && (
                      <div className="expanded-row">
                        {renderExpandedContent(artist)}
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            ) : (
              <div className="text-center py-4">
                {loading ? 'Loading artists...' : 'No artists found'}
              </div>
            )}

            {loading && artists.length > 0 && (
              <div className="text-center py-4">
                <Bars
                  height="40"
                  width="40"
                  color="#23f0c7"
                  ariaLabel="loading-indicator"
                  visible={true}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
} 