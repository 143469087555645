import React, { useEffect, useState } from "react";
import Styles from "./Header.module.css";
import search from "../../../assets/Images/Search.svg";
import ProfileDropdown from "./ProfileDropdown";
import NotifPanel from "../notif-panel/NotifPanel";
import { useDispatch, useSelector } from "react-redux";
import {
  searchRequest,
  setSearchTextAction,
} from "../../../redux/actions/searchActions";
import {
  GET_UPLOAD_STATS_REQUEST,
  RESET_SEARCH,
} from "../../../redux/actions/actionTypes";
import { getAllArtistFetch } from "../../../redux/actions/smlActions";
import { Crown, Star, Users, CircleDot, Zap, Gem } from 'lucide-react';

export default function Header({ status }) {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(null);

  const uploadStats = useSelector(
    (state) => state.uploadStats?.statsFetched
  );
  const isLoading = uploadStats?.isLoading;

  useEffect(() => {
    dispatch(getAllArtistFetch());
  }, []);

  const handleInputChange = (e) => {
    dispatch({ type: RESET_SEARCH });
    const value = e.target.value;
    setSearchText(value);

    dispatch(setSearchTextAction(value));

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    if (value.trim() !== "") {
      setTypingTimeout(
        setTimeout(() => {
          dispatch(searchRequest(value, status, 0, 10));
        }, 1000)
      );
    } else {
      setTypingTimeout(null);
    }
  };
  
  const websocketNotifications =
    useSelector((state) => state.websocket?.result) || [];
  const unread = useSelector(
    (state) => state.notifs?.notifs?.notifs?.unreadCount
  );

  const apiNotifications =
    useSelector((state) => state.notifs?.notifs?.notifs?.data) || [];

  const notifications =
    websocketNotifications.length > 0
      ? websocketNotifications
      : unread > 0
      ? apiNotifications
      : [];
  const isWebSocket = websocketNotifications.length > 0;
  
  useEffect(() => {
    dispatch({ type: GET_UPLOAD_STATS_REQUEST });
  }, []);

  // Get plan from Redux store
  const currentPlan = uploadStats?.stats?.plan;

  const getPlanIcon = (plan) => {
    if (!plan) return <CircleDot size={16} />;
    const planLower = plan.toLowerCase();
    switch (planLower) {
      case 'pro':
        return <Crown size={16} />;
      case 'basic':
      case 'starter':
        return <Star size={16} />;
      case 'essential':
        return <Gem size={16} />;
      case 'partner':
        return <Users size={16} />;
      case 'free':
        return <Zap size={16} />;
      default:
        return <CircleDot size={16} />;
    }
  };

  const getPlanClass = (plan) => {
    if (!plan) return `${Styles.currentPlan} ${Styles.free}`;
    const planLower = plan.toLowerCase();
    switch (planLower) {
      case 'pro':
        return `${Styles.currentPlan} ${Styles.pro}`;
      case 'basic':
      case 'starter':
        return `${Styles.currentPlan} ${Styles.basic}`;
      case 'essential':
        return `${Styles.currentPlan} ${Styles.essential}`;
      case 'partner':
        return `${Styles.currentPlan} ${Styles.partner}`;
      case 'free':
        return `${Styles.currentPlan} ${Styles.free}`;
      default:
        return `${Styles.currentPlan} ${Styles.free}`;
    }
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.leftContainer}>
        <input
          onChange={handleInputChange}
          value={searchText}
          placeholder="Search"
        />
        <img
          onClick={() => {
            if (searchText.trim() !== "") {
              dispatch(searchRequest(searchText));
            }
          }}
          src={search}
          alt="Search"
        />
      </div>
      <div className={Styles.rightContainer}>
        {!isLoading && currentPlan && (
          <span className={getPlanClass(currentPlan)}>
            {getPlanIcon(currentPlan)}
            <span style={{ marginLeft: '4px' }}>{currentPlan}</span>
          </span>
        )}
        <NotifPanel
          panelType={"artist"}
          data={notifications}
          isWebSocket={isWebSocket}
        />
        <ProfileDropdown />
      </div>
    </div>
  );
}
