import React, { useEffect } from "react";
import "./TermsAndConditions.css";
import { MdOutlineClose } from "react-icons/md";

const TermsAndConditions = ({ isOpen, onClose }) => {
  useEffect(() => {
    if (isOpen) {
      // Get scrollbar width to prevent layout shift
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.body.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);
      document.body.classList.add('modal-open');
    }
    
    return () => {
      document.body.classList.remove('modal-open');
      document.body.style.removeProperty('--scrollbar-width');
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="terms-modal-container">
      <div className="terms-modal-header">
        <h2>Terms and Conditions</h2>
        <button className="modal-close" onClick={onClose}>
          <MdOutlineClose />
        </button>
      </div>
      <div className="terms-modal-content">
        <p>Effective Date: September 6, 2024</p>

        <p>
          Welcome to Sync Music Lab! These Terms and Conditions ("Terms")
          govern your use of our platform. By accessing or using Sync Music
          Lab, you agree to these Terms. If you do not agree, do not use our
          platform.
        </p>

        <div className="terms-section">
          <h3>1. User Agreement</h3>
          <p className="terms-text">
            This Agreement is between you and Sync Music Lab. By creating an
            account, subscribing, or submitting tracks, you confirm that you
            have read and agreed to these Terms. If you're using the platform
            on behalf of an organization, you are representing that
            organization and confirm you have the authority to bind them to
            these Terms.
          </p>
        </div>

        <div className="terms-section">
          <h3>2. Services Provided</h3>
          <p className="terms-text">
            Sync Music Lab is a subscription-based educational platform
            offering expert feedback from music supervisors. Users can submit
            music tracks for review to improve their chances of placement in
            various media.
          </p>
        </div>

        <div className="terms-section">
          <h3>3. Subscription Plans</h3>
          <ul className="terms-list">
            <li className="terms-list-item">
              <strong>Subscription Options:</strong> Sync Music Lab offers
              monthly and annual subscription plans. The scope of services
              depends on the plan selected.
            </li>
            <li className="terms-list-item">
              <strong>Billing and Payments:</strong> Subscriptions auto-renew
              unless canceled before the next billing date. Payments are
              non-refundable, meaning no refunds for unused time within a
              billing cycle.
            </li>
            <li className="terms-list-item">
              <strong>Monthly Subscription:</strong> Cancellations take effect
              at the end of the current month.
            </li>
            <li className="terms-list-item">
              <strong>Annual Subscription:</strong> Cancellations take effect
              at the end of the current year, and no partial refunds are
              issued.
            </li>
            <li className="terms-list-item">
              <strong>Payment Methods:</strong> Users are responsible for
              maintaining updated payment information. Sync Music Lab may
              suspend services for non-payment.
            </li>
          </ul>
        </div>

        <div className="terms-section">
          <h3>4. Content Storage and Ownership</h3>
          <ul className="terms-list">
            <li className="terms-list-item">
              <strong>Ownership of Content:</strong> By submitting music
              tracks, you affirm that you own or have rights to all content
              uploaded. You retain ownership, but grant Sync Music Lab a
              non-exclusive, worldwide, royalty-free license to use the
              content for feedback, service improvement, and promotional
              purposes.
            </li>
            <li className="terms-list-item">
              <strong>Storage:</strong> Sync Music Lab stores your submitted
              tracks for service purposes and may retain them for future
              inquiries or feedback. Users can request deletion of content by
              contacting support, except where retention is necessary for
              legal reasons.
            </li>
          </ul>
        </div>

        <div className="terms-section">
          <h3>5. Indemnification for Unauthorized Use</h3>
          <p className="terms-text">
            Sync Music Lab facilitates the sharing of music tracks with music
            supervisors and other industry professionals for review and
            potential licensing opportunities. However, Sync Music Lab is not
            responsible for any unauthorized use of downloaded music by
            outside parties, including but not limited to music supervisors.
          </p>
          <p className="terms-text">
            By using our service, you agree to indemnify and hold harmless
            Sync Music Lab, its officers, directors, employees, and agents
            from any claims, damages, losses, liabilities, and all costs and
            expenses of defense, including but not limited to, attorneys'
            fees, resulting directly or indirectly from the unauthorized use
            of your music by any third party who has downloaded it through our
            platform.
          </p>
          <p className="terms-text">
            It is the responsibility of any party interested in using the
            music for commercial purposes to obtain proper licensing directly
            from the rights holder. Sync Music Lab does not grant any licenses
            for commercial use of the music shared through our platform.
          </p>
        </div>

        <div className="terms-section">
          <h3>6. User Responsibilities</h3>
          <p className="terms-text">Users agree to:</p>
          <ul className="terms-list">
            <li className="terms-list-item">
              Provide accurate information during registration and keep login
              credentials secure.
            </li>
            <li className="terms-list-item">
              Ensure all submitted content is lawful and does not infringe any
              third-party rights.
            </li>
            <li className="terms-list-item">
              Use the platform for intended purposes and avoid any conduct
              that could harm other users or the platform.
            </li>
          </ul>
        </div>

        <div className="terms-section">
          <h3>7. Intellectual Property Rights</h3>
          <p className="terms-text">
            All intellectual property related to Sync Music Lab, including
            trademarks, logos, and software, is owned by Sync Music Lab or its
            licensors. Users may not reproduce or redistribute any material
            from Sync Music Lab without express permission.
          </p>
        </div>

        <div className="terms-section">
          <h3>8. Termination and Suspension</h3>
          <p className="terms-text">
            Sync Music Lab may suspend or terminate user accounts if users
            violate these Terms, fail to pay fees, or engage in harmful
            conduct. Users may terminate their subscription at any time by
            contacting support, but fees already paid will not be refunded.
          </p>
        </div>

        <div className="terms-section">
          <h3>9. Limitation of Liability</h3>
          <p className="terms-text">
            Sync Music Lab is not responsible for indirect or consequential
            damages related to the use of the platform. Our total liability
            will not exceed the amount paid by the user in the 12 months
            preceding a claim.
          </p>
        </div>

        <div className="terms-section">
          <h3>10. Dispute Resolution</h3>
          <ul className="terms-list">
            <li className="terms-list-item">
              <strong>Governing Law:</strong> These Terms are governed by the
              laws of California.
            </li>
            <li className="terms-list-item">
              <strong>Arbitration:</strong> Disputes will be resolved through
              binding arbitration in California. Users agree to waive class
              action rights.
            </li>
          </ul>
        </div>

        <div className="terms-section">
          <h3>11. Changes to the Terms</h3>
          <p className="terms-text">
            Sync Music Lab reserves the right to update these Terms at any
            time. Changes will be communicated by updating the "Effective
            Date." Continued use of the platform after updates constitutes
            acceptance of the new Terms.
          </p>
        </div>

        <div className="terms-section">
          <h3>12. Contact Information</h3>
          <p className="terms-text">
            For questions or concerns, please contact us at:
          </p>
          <p className="terms-text">
            Sync Music Lab
            <br />
            2080 Empire Avenue, STE1168
            <br />
            Burbank, CA 91504
            <br />
            Email: support@syncmusiclab.com
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
