import React from 'react';
import { Row, Col } from 'react-bootstrap';
import SMLLabel from '../../../components/Shared/SMLLabel/SMLLabel';
import CommentIcon from '../../../assets/Icons/comment.svg';
import { formatTime } from '../../../pages/Reviews/TrackUnderReview';
import './TrackComments.css';

const TrackComments = ({ 
  comments, 
  focusedComment, 
  onCommentClick,
  user,
  showTitle = true 
}) => {
  return (
    <div className="comments-container">
      {showTitle && (
        <Row className="mb-3">
          <Col>
            <SMLLabel style="title1" value="Suggestions" />
          </Col>
        </Row>
      )}
      
      {comments.map((comment, index) => (
        <div
          id={`transcript-${comment.idSuffix}`}
          key={`transcript-${comment.idSuffix}`}
          className={`input-track-notes ${
            focusedComment !== null 
              ? focusedComment === index 
                ? 'focused' 
                : 'dimmed'
              : ''
          }`}
          onClick={(e) => onCommentClick(e, comment, index)}
        >
          <div className="comment-header">
            <span className="timestamp">
              <div style={{ height: "24px", width: "24px" }}>
                <img src={CommentIcon} alt="Comment" />
              </div>
              {typeof comment.time === "object"
                ? `${formatTime(comment.time[0])} - ${formatTime(comment.time[1])}`
                : `${formatTime(comment.time)}`}
            </span>
          </div>
          <div className="content">
            <div className="author">Sync Supervisor</div>
            <div className="comment-content">{comment.transcript}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TrackComments; 