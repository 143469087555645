import React from 'react';
import SMLButton from '../../Shared/SMLButton/SMLButton';
import { Modal } from 'react-bootstrap';
import closeIcon from '../../../assets/Images/closeIcon.svg';
import { AlertTriangleIcon } from 'lucide-react';
import { useLocation } from 'react-router-dom';

const LogoutConfirm = ({ show, onConfirm, onCancel }) => {
  const location = useLocation();
  if (location.pathname === '/') return;

  return (
    <Modal
      show={true}
      size="md"
      aria-labelledby="logout-modal-title"
      centered
      className="logout-confirm-modal"
      aria-hidden={!show}
    >
      <div
        style={{ position: 'relative' }}
        className="confirm-mail-container text-center"
      >
        <div
          style={{ position: 'absolute', top: '0.2rem', right: '0.2rem', cursor : "pointer" }}
          className="d-flex justify-content-end w-full p-4"
        >
          <img src={closeIcon} alt="close" onClick={onCancel} />
        </div>
        <div className="d-flex align-content-center gap-2">
          <AlertTriangleIcon style={{ marginTop: '0.5rem' }} />
          <h2 style = {{fontWeight : "700"}}>Session Timeout Warning</h2>
        </div>
        <div>
          <p style={{marginBottom : "0"}}>Due to inactivity, you will be logged out!</p>
          <p>Would you like to stay logged in?</p>
        </div>
        <div className="d-flex justify-content-center gap-3 mt-3">
          <SMLButton
            value="Stay Logged In"
            style="SMLButton1"
            handleClickEvent={onCancel}
          />
          <SMLButton
            value="Logout"
            style="req-video-btn2"
            handleClickEvent={onConfirm}
          />
        </div>
        {/* </div> */}
      </div>
    </Modal>
  );
};

export default LogoutConfirm;
