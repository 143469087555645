import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import SUPHeader from '../../../components/Shared/Header/SupHeader';
import SMLLabel from '../../../components/Shared/SMLLabel/SMLLabel';
import { Bars } from 'react-loader-spinner';
import './ResourceManagement.css';
import { 
  getResourcesApi, 
  getUploadUrlApi, 
  uploadToS3Api, 
  createResourceApi, 
  deleteResourceApi,
  updateResourceApi
} from '../../../api/resources/getResourcesApi';

export default function ResourceManagement() {
  const [selectedSection, setSelectedSection] = useState('video');
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadData, setUploadData] = useState({
    title: '',
    description: '',
    file: null,
    thumbnail: null,
    category: 'video',
    plans: ['free', 'starter', 'essential', 'pro', 'partner'],
    expiryDate: ''
  });

  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [resources, setResources] = useState([]);
  const [error, setError] = useState(null);

  // Add new state for edit modal
  const [showEditModal, setShowEditModal] = useState(false);
  const [editData, setEditData] = useState(null);

  const [filters, setFilters] = useState({
    hidden: false,
    archived: false,
    expired: false
  });

  const [searchKeyword, setSearchKeyword] = useState('');

  // Add pagination state
  const [pagination, setPagination] = useState({
    total: 0,
    offset: 0,
    limit: 50
  });

  const getCategory = (section) => {
    return section === 'template' ? 'template,guide' : section;
  };

  const fetchResources = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await getResourcesApi(getCategory(selectedSection), {
        keyword: searchKeyword,
        hidden: filters.hidden,
        archived: filters.archived,
        expired: filters.expired,
        offset: pagination.offset,
        limit: pagination.limit
      });
      setResources(response.resources);
      setPagination(response.pagination);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchResources();
  }, [selectedSection]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setUploadData(prev => ({
      ...prev,
      category: selectedSection
    }));
  }, [selectedSection]);

  // Add debounce for search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchKeyword.length >= 3) {
        fetchResources();
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchKeyword]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePlanToggle = (plan) => {
    setUploadData(prev => {
      const newPlans = prev.plans.includes(plan)
        ? prev.plans.filter(p => p !== plan)
        : [...prev.plans, plan];
      
      return {
        ...prev,
        plans: newPlans.length > 0 ? newPlans : prev.plans
      };
    });
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    
    // Updated validation - thumbnail only required for non-brief resources
    if (!uploadData.file) {
      alert('Please select a file');
      return;
    }
    if (selectedSection !== 'brief' && !uploadData.thumbnail) {
      alert('Please select a thumbnail image');
      return;
    }

    try {
      setUploading(true);
      setError(null);

      let thumbnailKey = null;
      
      // Only upload thumbnail if it exists
      if (uploadData.thumbnail) {
        const thumbnailUrlData = await getUploadUrlApi({
          title: `${uploadData.title}-thumbnail`,
          category: 'thumbnails',
          contentType: uploadData.thumbnail.type
        });

        await uploadToS3Api(thumbnailUrlData.uploadUrl, uploadData.thumbnail, uploadData.thumbnail.type);
        thumbnailKey = thumbnailUrlData.key;
      }

      const contentType = uploadData.file.type;
      const uploadUrlData = await getUploadUrlApi({
        title: uploadData.title,
        category: selectedSection,
        contentType
      });

      await uploadToS3Api(uploadUrlData.uploadUrl, uploadData.file, contentType);

      await createResourceApi({
        title: uploadData.title,
        description: uploadData.description,
        category: selectedSection,
        contentType,
        resourceFileKey: uploadUrlData.key,
        thumbnailKey, // This might be null for briefs
        plans: uploadData.plans,
        order: 0,
        expiryDate: uploadData.expiryDate
      });

      await fetchResources();
      setShowUploadModal(false);
      setUploadData({
        title: '',
        description: '',
        file: null,
        thumbnail: null,
        category: selectedSection,
        plans: ['free', 'starter', 'essential', 'pro', 'partner'],
        expiryDate: ''
      });
    } catch (error) {
      setError(error.message);
      alert('Upload failed: ' + error.message);
    } finally {
      setUploading(false);
    }
  };

  const handleDelete = async (resourceId) => {
    if (window.confirm('Are you sure you want to delete this resource?')) {
      try {
        setLoading(true);
        setError(null);
        await deleteResourceApi(resourceId);
        await fetchResources();
      } catch (error) {
        setError(error.message);
        alert('Delete failed: ' + error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  // Add update function
  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!editData) return;

    try {
      setUploading(true);
      setError(null);

      const updatePayload = {};

      // Only add if values have changed
      if (editData.title !== editData.originalTitle) {
        updatePayload.title = editData.title;
      }
      if (editData.description !== editData.originalDescription) {
        updatePayload.description = editData.description;
      }
      if (editData.order !== editData.originalOrder) {
        updatePayload.order = editData.order;
      }
      if (JSON.stringify(editData.plans) !== JSON.stringify(editData.originalPlans)) {
        updatePayload.plans = editData.plans;
      }

      // Handle new files if selected
      if (editData.newThumbnail) {
        const thumbnailUrlData = await getUploadUrlApi({
          title: `${editData.title}-thumbnail`,
          category: 'thumbnails',
          contentType: editData.newThumbnail.type
        });
        
        await uploadToS3Api(thumbnailUrlData.uploadUrl, editData.newThumbnail, editData.newThumbnail.type);
        updatePayload.thumbnailKey = thumbnailUrlData.key;
      }

      if (editData.newFile) {
        const uploadUrlData = await getUploadUrlApi({
          title: editData.title,
          category: 'video',
          contentType: editData.newFile.type
        });
        
        await uploadToS3Api(uploadUrlData.uploadUrl, editData.newFile, editData.newFile.type);
        updatePayload.resourceFileKey = uploadUrlData.key;
      }

      // Add checks for new fields
      if (editData.hidden !== editData.originalHidden) {
        updatePayload.hidden = editData.hidden;
      }
      if (editData.archived !== editData.originalArchived) {
        updatePayload.archived = editData.archived;
      }
      if (editData.category === 'brief' && editData.expiryDate !== editData.originalExpiryDate) {
        updatePayload.expiryDate = editData.expiryDate;
      }

      // Only make API call if there are changes
      if (Object.keys(updatePayload).length > 0) {
        await updateResourceApi(editData._id, updatePayload);
        await fetchResources();
      }

      setShowEditModal(false);
      setEditData(null);
    } catch (error) {
      setError(error.message);
      alert('Update failed: ' + error.message);
    } finally {
      setUploading(false);
    }
  };

  // When opening edit modal, store original values
  const handleEdit = (resource) => {
    setEditData({
      ...resource,
      // Format the expiry date for datetime-local input
      expiryDate: resource.expiryDate ? new Date(resource.expiryDate).toISOString().slice(0, 16) : '',
      plans: resource.plans || []
    });
    setShowEditModal(true);
  };

  const handleFilterChange = (filterName) => {
    setFilters(prev => ({
      ...prev,
      [filterName]: !prev[filterName]
    }));
  };

  const handleClearSearch = () => {
    setSearchKeyword('');
    fetchResources(); // Fetch resources without keyword
  };

  const handleEditPlanToggle = (plan) => {
    setEditData(prev => ({
      ...prev,
      plans: prev.plans.includes(plan)
        ? prev.plans.filter(p => p !== plan)
        : [...prev.plans, plan]
    }));
  };

  // Add helper function to check if expired
  const isExpired = (date) => {
    if (!date) return false;
    return new Date(date) < new Date();
  };

  return (
    <div className="vh-100 px-3" style={{ background: 'white', color: 'black', overflowY: 'auto' }}>
      {loading && (
        <div className="spinner-overlay">
          <Bars
            height="80"
            width="80"
            color="#23f0c7"
            ariaLabel="bars-loading"
            visible={true}
          />
        </div>
      )}
      
      <SUPHeader />
      
      <div className="padding-0">
        <div className="bg-main-div mt-3">
          <Row className="mb-3 supervisor-dashboard-container">
            <Col className="d-flex justify-content-center align-items-center py-3">
              <div className="box d-flex justify-content-between align-items-center supd-stats">
                <SMLLabel style="title1" value="Resource Management" />
              </div>
            </Col>
          </Row>
        </div>

        <div className="bg-main-div mt-3 mb-3">
          <Row className="mb-3">
            <Col>
              <div className="supd-header2">
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div className="category-buttons">
                    <button
                      className={selectedSection === 'video' ? 'SMLToggleBtn-orange' : 'SMLToggleBtn-white'}
                      onClick={() => setSelectedSection('video')}
                    >
                      Videos
                    </button>
                    <button
                      className={selectedSection === 'template' ? 'SMLToggleBtn-orange' : 'SMLToggleBtn-white'}
                      onClick={() => setSelectedSection('template')}
                    >
                      Templates & Guides
                    </button>
                    <button
                      className={selectedSection === 'brief' ? 'SMLToggleBtn-orange' : 'SMLToggleBtn-white'}
                      onClick={() => setSelectedSection('brief')}
                    >
                      Briefs
                    </button>
                  </div>

                  <div className="search-container">
                    <div className="search-input-wrapper">
                      <input
                        type="text"
                        className="form-control search-input"
                        placeholder="Search resources..."
                        value={searchKeyword}
                        onChange={(e) => setSearchKeyword(e.target.value)}
                      />
                      {searchKeyword && (
                        <button
                          className="search-clear-button"
                          onClick={handleClearSearch}
                          type="button"
                        >
                          ×
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="d-flex align-items-center">
                    <div className="filter-toggles">
                      <label className={`filter-toggle ${filters.hidden ? 'active' : ''}`}>
                        <input
                          type="checkbox"
                          checked={filters.hidden}
                          onChange={() => handleFilterChange('hidden')}
                        />
                        Hidden
                      </label>
                      <label className={`filter-toggle ${filters.archived ? 'active' : ''}`}>
                        <input
                          type="checkbox"
                          checked={filters.archived}
                          onChange={() => handleFilterChange('archived')}
                        />
                        Archived
                      </label>
                      <label className={`filter-toggle ${filters.expired ? 'active' : ''}`}>
                        <input
                          type="checkbox"
                          checked={filters.expired}
                          onChange={() => handleFilterChange('expired')}
                        />
                        Expired
                      </label>
                    </div>
                    <button
                      className="SMLButton2 SMLButton-Orange ml-3"
                      onClick={() => setShowUploadModal(true)}
                    >
                      Upload New Resource
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          {error && (
            <div className="alert alert-danger mx-3">
              {error}
            </div>
          )}

          <div className="p-4">
            {resources && resources.length > 0 ? (
              <div className="resource-list">
                {resources.sort((a, b) => (a.order || 0) - (b.order || 0)).map((resource) => (
                  <div key={resource._id} className="resource-item">
                    <div className="resource-content">
                      <div className="thumbnail-column">
                        {resource.thumbnailKey ? (
                          <img 
                            src={`${resource.baseUrl}/${resource.thumbnailKey}`}
                            alt={resource.title}
                            className="resource-thumbnail"
                          />
                        ) : (
                          <div className="thumbnail-placeholder">
                            <i className="fas fa-image"></i>
                          </div>
                        )}
                      </div>

                      <div className="resource-title-section">
                        <h3 className="title">{resource.title}</h3>
                        <p className="description">{resource.description || '-'}</p>
                      </div>

                      <div className="resource-info-grid">
                        <div className="column-labels">
                          {selectedSection === 'brief' && (
                            <>
                              <div className="label">EXPIRY DATE</div>
                              <div className="label">STATUS</div>
                            </>
                          )}
                          <div className="label">CATEGORY</div>
                          <div className="label">AVAILABLE TO</div>
                          <div className="label">CREATED</div>
                          <div className="label">ACTIONS</div>
                        </div>

                        <div className="column-values">
                          {selectedSection === 'brief' && (
                            <>
                              <div className={`value ${isExpired(resource.expiryDate) ? 'expired' : ''}`}>
                                {resource.expiryDate ? new Date(resource.expiryDate).toLocaleDateString('en-US', {
                                  year: 'numeric',
                                  month: 'short',
                                  day: 'numeric',
                                  hour: 'numeric',
                                  minute: '2-digit',
                                  hour12: true
                                }) : '-'}
                              </div>

                              <div className="value status-stack">
                                {resource.hidden && <span className="status-tag hidden">Hidden</span>}
                                {resource.archived && <span className="status-tag archived">Archived</span>}
                                {!resource.hidden && !resource.archived && <span className="status-tag active">Active</span>}
                              </div>
                            </>
                          )}

                          <div className="value">
                            <span className="category-tag">{resource.category}</span>
                          </div>

                          <div className="value">
                            <div className="plan-tags">
                              {resource.plans.map(plan => (
                                <span key={plan} className="plan-tag">{plan}</span>
                              ))}
                            </div>
                          </div>

                          <div className="value">
                            {new Date(resource.created_at).toLocaleDateString()}
                          </div>

                          <div className="value actions">
                            <button onClick={() => window.open(resource.url, '_blank')}>View</button>
                            <button onClick={() => handleEdit(resource)}>Edit</button>
                            <button onClick={() => handleDelete(resource._id)} className="delete">Delete</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center py-4">
                No resources available
              </div>
            )}
          </div>
        </div>
      </div>

      {showUploadModal && (
        <div className="modal-backdrop">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">New Resource</h3>
            </div>
            
            <form onSubmit={handleUpload}>
              <div className="form-group">
                <label className="form-label">Title</label>
                <input
                  type="text"
                  className="form-control"
                  value={uploadData.title}
                  onChange={(e) => setUploadData(prev => ({
                    ...prev,
                    title: e.target.value
                  }))}
                  required
                />
              </div>

              <div className="form-group">
                <label className="form-label">Description</label>
                <textarea
                  className="form-control"
                  value={uploadData.description}
                  onChange={(e) => setUploadData(prev => ({
                    ...prev,
                    description: e.target.value
                  }))}
                  required
                />
              </div>

              <div className="form-group">
                <label className="form-label">Resource File</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      setUploadData(prev => ({
                        ...prev,
                        file: file
                      }));
                    }
                  }}
                  accept={selectedSection === 'video' 
                    ? '.mp4,.mov,.avi,.wmv,.flv,.mkv'
                    : '.pdf,.doc,.docx'
                  }
                  required
                />
              </div>

              {selectedSection !== 'brief' && (
                <div className="form-group">
                  <label className="form-label">Thumbnail</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file) {
                        setUploadData(prev => ({
                          ...prev,
                          thumbnail: file
                        }));
                      }
                    }}
                    accept="image/*"
                    required
                  />
                </div>
              )}

              <div className="form-group">
                <label className="form-label">Order</label>
                <input
                  type="number"
                  className="form-control order-input"
                  value={uploadData.order || 0}
                  onChange={(e) => setUploadData(prev => ({
                    ...prev,
                    order: parseInt(e.target.value) || 0
                  }))}
                />
              </div>

              <div className="plans-section">
                <label className="form-label">Available to Plans</label>
                <div className="plans-checkboxes">
                  {['Free', 'Starter', 'Essential', 'Pro', 'Partner'].map(plan => (
                    <label key={plan} className="plan-checkbox">
                      <input
                        type="checkbox"
                        checked={uploadData.plans.includes(plan.toLowerCase())}
                        onChange={() => {
                          setUploadData(prev => ({
                            ...prev,
                            plans: prev.plans.includes(plan.toLowerCase())
                              ? prev.plans.filter(p => p !== plan.toLowerCase())
                              : [...prev.plans, plan.toLowerCase()]
                          }));
                        }}
                      />
                      {plan}
                    </label>
                  ))}
                </div>
              </div>

              <div className="status-toggles">
                <label className="status-toggle">
                  <input
                    type="checkbox"
                    checked={uploadData.hidden}
                    onChange={(e) => setUploadData(prev => ({
                      ...prev,
                      hidden: e.target.checked
                    }))}
                  />
                  Hidden
                </label>

                <label className="status-toggle">
                  <input
                    type="checkbox"
                    checked={uploadData.archived}
                    onChange={(e) => setUploadData(prev => ({
                      ...prev,
                      archived: e.target.checked
                    }))}
                  />
                  Archived
                </label>
              </div>

              {selectedSection === 'brief' && (
                <div className="form-group">
                  <label className="form-label">Expiry Date</label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={uploadData.expiryDate}
                    onChange={(e) => setUploadData(prev => ({
                      ...prev,
                      expiryDate: e.target.value
                    }))}
                    required
                  />
                </div>
              )}

              <div className="modal-footer">
                <button type="button" className="btn btn-link" onClick={() => setShowUploadModal(false)}>
                  Cancel
                </button>
                <button type="submit" className="btn btn-dark" disabled={uploading}>
                  {uploading ? 'Uploading...' : 'Upload'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showEditModal && editData && (
        <div className="modal-backdrop">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Edit Resource</h3>
            </div>
            
            <form onSubmit={handleUpdate}>
              <div className="form-group">
                <label className="form-label">Title</label>
                <input
                  type="text"
                  className="form-control"
                  value={editData.title}
                  onChange={(e) => setEditData(prev => ({
                    ...prev,
                    title: e.target.value
                  }))}
                  required
                />
              </div>

              <div className="form-group">
                <label className="form-label">Description</label>
                <textarea
                  className="form-control"
                  value={editData.description}
                  onChange={(e) => setEditData(prev => ({
                    ...prev,
                    description: e.target.value
                  }))}
                  required
                />
              </div>

              <div className="form-group">
                <label className="form-label">Resource File</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      setEditData(prev => ({
                        ...prev,
                        newFile: file
                      }));
                    }
                  }}
                  accept={selectedSection === 'video' 
                    ? '.mp4,.mov,.avi,.wmv,.flv,.mkv'
                    : '.pdf,.doc,.docx'
                  }
                />
                {editData?.newFile ? (
                  <div className="file-name">
                    Selected file: {editData.newFile.name}
                  </div>
                ) : editData?.resourceFileKey ? (
                  <div className="file-name">
                    Current file: {editData.resourceFileKey.split('/').pop()}
                  </div>
                ) : null}
              </div>

              <div className="form-group">
                <label className="form-label">Order</label>
                <input
                  type="number"
                  className="form-control order-input"
                  value={editData.order || 0}
                  onChange={(e) => setEditData(prev => ({
                    ...prev,
                    order: parseInt(e.target.value) || 0
                  }))}
                />
              </div>

              <div className="plans-section">
                <label className="form-label">Available to Plans</label>
                <div className="plans-checkboxes">
                  {['Free', 'Starter', 'Essential', 'Pro', 'Partner'].map(plan => (
                    <label key={plan} className="plan-checkbox">
                      <input
                        type="checkbox"
                        checked={editData.plans.includes(plan.toLowerCase())}
                        onChange={() => handleEditPlanToggle(plan.toLowerCase())}
                      />
                      {plan}
                    </label>
                  ))}
                </div>
              </div>

              <div className="status-toggles">
                <label className="status-toggle">
                  <input
                    type="checkbox"
                    checked={editData.hidden}
                    onChange={(e) => setEditData(prev => ({
                      ...prev,
                      hidden: e.target.checked
                    }))}
                  />
                  Hidden
                </label>

                <label className="status-toggle">
                  <input
                    type="checkbox"
                    checked={editData.archived}
                    onChange={(e) => setEditData(prev => ({
                      ...prev,
                      archived: e.target.checked
                    }))}
                  />
                  Archived
                </label>
              </div>

              {editData.category === 'brief' && (
                <div className="form-group">
                  <label className="form-label">Expiry Date</label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={editData.expiryDate || ''}
                    onChange={(e) => setEditData(prev => ({
                      ...prev,
                      expiryDate: e.target.value
                    }))}
                  />
                </div>
              )}

              <div className="modal-footer">
                <button type="button" className="btn btn-link" onClick={() => {
                  setShowEditModal(false);
                  setEditData(null);
                }}>
                  Cancel
                </button>
                <button type="submit" className="btn btn-dark" disabled={uploading}>
                  {uploading ? 'Updating...' : 'Update'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};