import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Styles from "./Header.module.css";
import UserMobileIcon from "../../../assets/Icons/user-mobile.svg";
import ChevronUp from "../../../assets/Icons/chevron-up.svg";
import ChevronDown from "../../../assets/Images/chevron-down.svg";
import { useDispatch, useSelector } from "react-redux";
import { logoutRequest } from "../../../redux/actions/authActions";
import { RESET_ARTIST } from "../../../redux/actions/smlActions";
import { googleLogout } from "@react-oauth/google";
import {
  RESET_ASSIGNED_SUPERVISOR,
  RESET_CONFIRM_MAIL,
  RESET_GET_ALL_TRACK,
  RESET_GET_SINGLE_TRACK,
} from "../../../redux/actions/actionTypes";
import { logoutFromWebSocket } from "../../../api/websocket/websocketApi";

export default function ProfileDropdown() {
  const [showLogout, setShowLogout] = useState(false);
  const [username, setUsername] = useState("-");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  useEffect(() => {
    const checkUsername = () => {
      const storedUsername = localStorage.getItem("username");
      if (storedUsername) {
        setUsername(storedUsername);
      }
    };

    checkUsername();
    let attempts = 0;
    const interval = setInterval(() => {
      if (username === "-" && attempts < 20) {
        checkUsername();
        attempts++;
      } else {
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);

  const toggleLogout = () => {
    setShowLogout(!showLogout);
  };

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    dispatch(logoutRequest());
    dispatch({ type: RESET_GET_SINGLE_TRACK });
    dispatch({ type: RESET_GET_ALL_TRACK });
    dispatch({ type: RESET_ASSIGNED_SUPERVISOR });
    dispatch({ type: RESET_ARTIST });
    dispatch({ type: RESET_CONFIRM_MAIL });
    googleLogout();
    logoutFromWebSocket(); // Call the logout function from websocketApi.js
    // websocket.close()
    navigate("/");
  };

  return (
    <div className={Styles.profileDropdown}>
      <button onClick={toggleLogout} className={Styles.profileButton}>
        <img src={UserMobileIcon} alt="User" />
        {username}
        <img src={showLogout ? ChevronDown : ChevronUp} alt="Chevron" />
      </button>
      {showLogout && <Logout onLogout={logout} />}
    </div>
  );
}

function Logout({ onLogout }) {
  return (
    <div className={Styles.logoutCard}>
      <button onClick={onLogout}>Logout</button>
    </div>
  );
}
